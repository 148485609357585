function TicketStatus() {
  return [
    { ticketStatusID: 100, ticketStatusName: "Draft" },
    { ticketStatusID: 101, ticketStatusName: "New" },
    { ticketStatusID: 102, ticketStatusName: "Open" },
    { ticketStatusID: 103, ticketStatusName: "Resolved" },
    { ticketStatusID: 104, ticketStatusName: "Closed" },
    { ticketStatusID: 105, ticketStatusName: "Re-Opened" },
    { ticketStatusID: 1001, ticketStatusName: "Escalated" },
    { ticketStatusID: 1004, ticketStatusName: "Reassigned" },
  ];
}

export default TicketStatus;
