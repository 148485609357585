import React, { Component } from "react";
import Priority from "./../Settings/Store/StorePriority";
class PriorityMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
componentDidMount(){
  
}
  render() {
    return (
      <React.Fragment>
        <Priority />
      </React.Fragment>
    );
  }
}

export default PriorityMaster;
