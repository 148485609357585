import React, { Component } from "react";
import RedDeleteIcon from "./../../assets/Images/red-delete-icon.png";
import DelBigIcon from "./../../assets/Images/del-big.png";
import FileUpload from "./../../assets/Images/file.png";
import DelBlack from "./../../assets/Images/del-black.png";
import UploadCancel from "./../../assets/Images/upload-cancel.png";
import DownExcel from "./../../assets/Images/csv.png";
import { ProgressBar } from "react-bootstrap";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Spin, Radio, Collapse } from "antd";
import ReactTable from "react-table";
import BlackInfoIcon from "./../../assets/Images/Info-black.png";
import Modal from "react-responsive-modal";
import { authHeader } from "../../helpers/authHeader";
import axios from "axios";
import config from "../../helpers/config";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import { CSVLink } from "react-csv";
import { Tabs, Tab } from "react-bootstrap-tabs/dist";
import matchSorter from "match-sorter";
import Sorting from "./../../assets/Images/sorting.png";
import { formatSizeUnits } from "../../helpers/CommanFuncation";
import Dropzone from "react-dropzone";
import * as translationHI from "../../translations/hindi";
import * as translationMA from "../../translations/marathi";
import CollapseDownIconDown from "./../../assets/Images/table-arr.png";
import CollapseDownIconUP from "./../../assets/Images/table-arr-up.png";
import { async } from "rxjs/internal/scheduler/async";
const { Panel } = Collapse;
class MainUserMaster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileName: "",
      isOpen: false,
      getID: 0,
      userData: [],
      selectUserName: "",
      selectFirstName: "",
      selectLastName: "",
      selectMobile: "",
      selectEmail: "",
      brandData: [],
      CategoryData: [],
      SubCategoryData: [],
      IssueTypeData: [],
      DesignationData: [],
      CRMRoleData: [],
      ReporteeDesignData: [],
      AgentData: [],
      selectedBrand: [],
      selectedCategory: [],
      selectedSubCategory: [],
      selectedIssueType: [],
      GetUserData: [],
      editBrand: [],
      editCategory: [],
      editSubCategory: [],
      editIssuetype: [],
      EditBrandData: [],
      selectedDesignation: 0,
      selectedCopyEscalation: false,
      selectedAssignEscalation: false,
      selectedSupervisorAgent: "",
      selectedCRMRoles: 0,
      selectedReporteeDesign: 0,
      selectedReportTO: 0,
      selectedAgent: 0,
      selectedStatus: "true",
      multibrandIDs: "",
      multicategoryIDs: "",
      multisubcategoryIDs: "",
      editreporteeDesign: 0,
      userEditData: {},
      editmodel: false,
      selectedAgentRadio: false,
      selectedSupervisorRadio: true,
      editAgentRadio: true,
      editSupervisorRadio: false,
      buttonToggle: false,
      buttonProfileToggle: false,
      forEditID: 0,
      test: "",
      usernameCompulsion: "",
      firstnameCompulsion: "",
      mobilenumberCompulsion: "",
      emailCompulsion: "",
      userdesignCompulsion: "",
      reporteeDesignCompulsion: "",
      reportToCompulsion: "",
      brandCompulsion: "",
      categoryCompulsion: "",
      subcategoryCompulsion: "",
      isuuetypeCompulsion: "",
      crmroleCompulsion: "",
      copyescCompulsion: "",
      assignescCompulsion: "",
      RadioCompulsion: "",
      agentCompulsion: "",
      editusernameCompulsion: "",
      editfirstnameCompulsion: "",
      editmobilenumberCompulsion: "",
      editemailCompulsion: "",
      edituserdesignCompulsion: "",
      editreporteeDesignCompulsion: "",
      editreportToCompulsion: "",
      editbrandCompulsion: "",
      editcategoryCompulsion: "",
      editsubcategoryCompulsion: "",
      editisuuetypeCompulsion: "",
      editcrmroleCompulsion: "",
      editcopyescCompulsion: "",
      editassignescCompulsion: "",
      editRadioCompulsion: "",
      editagentCompulsion: "",
      emailValidation: "",
      mobileValidation: "",
      personalReadOnly: false,
      profileReadOnly: false,
      StatusModel: false,
      sortAllData: "",
      sortDesignation: [],
      sortUsername: [],
      sortEmail: [],
      sortMobile: [],
      selTab: "Personal Details",
      userColor: "",
      mobileColor: "",
      emailColor: "",
      designationColor: "",
      sortHeader: "",
      emailFlag: true,
      editEmailFlag: true,
      phoneFlag: true,
      EditPhoneFlag: true,
      sortFilterDesignation: [],
      sortFilterUsername: [],
      sortFilterMobile: [],
      sortFilterEmail: [],
      tempuserData: [],
      filterTxtValue: "",
      sFilterCheckbox: "",
      isFileUploadFail: false,
      progressValue: 0,
      fileSize: "",
      showProgress: false,
      bulkuploadCompulsion: "",
      fileN: [],
      sdesignationFilterCheckbox: "",
      suserNameFilterCheckbox: "",
      smobileNumberFilterCheckbox: "",
      semailIDFilterCheckbox: "",
      isortA: false,
      bulkuploadLoading: false,
      translateLanguage: {},
      mappedCategoryBtnDisabled: true,
      createUserType: 0,
      storeCodeData: [],
      departmentData: [],
      functionData: [],
      userDesignationData: [],
      reportDesignation: [],
      reportToData: [],
      claimCategoryData: [],
      claimSubCategoryData: [],
      claimIssueTypeData: [],
      createUserActiveKey: "PersonalDetails",
      storeDetailsInput: {},
      storeDetailsErrors: {},
      newUserID: 0,
      storeDetialReadOnly: false,
      personalDetailsInput: {},
      personalDetailsErrors: {},
      personalDetailReadOnly: false,
      profileDetailsInput: {},
      profileDetailsErrors: {},
      profileDetailsReadOnly: false,
      mapCateogryInput: {
        CopyEscalation: false,
        AssignEscalation: false,
        Status: "true",
      },
      mapCateogryErrors: {},
      mapCateogryDetailsReadOnly: false,
    };
    this.handleGetUserList = this.handleGetUserList.bind(this);
    this.handleAddPersonalDetails = this.handleAddPersonalDetails.bind(this);
    this.handleGetBrandList = this.handleGetBrandList.bind(this);
    this.handleGetCategoryList = this.handleGetCategoryList.bind(this);
    this.handleGetSubCategoryList = this.handleGetSubCategoryList.bind(this);
    this.handleGetIssueTypeList = this.handleGetIssueTypeList.bind(this);
    this.handleGetDesignationList = this.handleGetDesignationList.bind(this);
    this.handleGetCRMRoleList = this.handleGetCRMRoleList.bind(this);
    this.handleGetReportTOList = this.handleGetReportTOList.bind(this);
    this.handleAddProfileDetails = this.handleAddProfileDetails.bind(this);
    this.handleGetAgentList = this.handleGetAgentList.bind(this);
    this.handleAddMapCategory = this.handleAddMapCategory.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.handleGetUserListByID = this.handleGetUserListByID.bind(this);
    this.togglePopover = this.togglePopover.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.handleSendMail = this.handleSendMail.bind(this);
    this.handleValidationEmailIdMob = this.handleValidationEmailIdMob.bind(
      this
    );
    this.StatusOpenModel = this.StatusOpenModel.bind(this);
    this.StatusCloseModel = this.StatusCloseModel.bind(this);
    this.hanldeAddBulkUpload = this.hanldeAddBulkUpload.bind(this);
  }
  componentDidMount() {
    this.handleGetUserList();
    this.handleGetBrandList();
    this.handleGetDesignationList();
    this.handleGetCRMRoleList();
    this.handleGetUserDesignationData();

    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.setState({
        translateLanguage: translationHI,
      });
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.setState({
        translateLanguage: translationMA,
      });
    } else {
      this.setState({
        translateLanguage: {},
      });
    }
  }
  sortStatusZtoA() {
    var itemsArray = [];
    itemsArray = this.state.userData;

    if (this.state.sortColumn === "userName") {
      itemsArray.sort((a, b) => {
        if (a.userName < b.userName) return 1;
        if (a.userName > b.userName) return -1;
        return 0;
      });
    }
    if (this.state.sortColumn === "designation") {
      itemsArray.sort((a, b) => {
        if (a.designation < b.designation) return 1;
        if (a.designation > b.designation) return -1;
        return 0;
      });
    }
    if (this.state.sortColumn === "mobileNumber") {
      itemsArray.sort((a, b) => {
        if (a.mobileNumber < b.mobileNumber) return 1;
        if (a.mobileNumber > b.mobileNumber) return -1;
        return 0;
      });
    }
    if (this.state.sortColumn === "emailID") {
      itemsArray.sort((a, b) => {
        if (a.emailID < b.emailID) return 1;
        if (a.emailID > b.emailID) return -1;
        return 0;
      });
    }

    this.setState({
      isortA: true,
      userData: itemsArray,
    });
    setTimeout(() => {
      this.StatusCloseModel();
    }, 10);
  }

  sortStatusAtoZ() {
    var itemsArray = [];
    itemsArray = this.state.userData;

    if (this.state.sortColumn === "designation") {
      itemsArray.sort((a, b) => {
        if (a.designation < b.designation) return -1;
        if (a.designation > b.designation) return 1;
        return 0;
      });
    }
    if (this.state.sortColumn === "userName") {
      itemsArray.sort((a, b) => {
        if (a.userName < b.userName) return -1;
        if (a.userName > b.userName) return 1;
        return 0;
      });
    }
    if (this.state.sortColumn === "mobileNumber") {
      itemsArray.sort((a, b) => {
        if (a.mobileNumber < b.mobileNumber) return -1;
        if (a.mobileNumber > b.mobileNumber) return 1;
        return 0;
      });
    }
    if (this.state.sortColumn === "emailID") {
      itemsArray.sort((a, b) => {
        if (a.emailID < b.emailID) return -1;
        if (a.emailID > b.emailID) return 1;
        return 0;
      });
    }

    this.setState({
      isortA: true,
      userData: itemsArray,
    });
    setTimeout(() => {
      this.StatusCloseModel();
    }, 10);
  }

  StatusOpenModel(data, header) {
    if (
      this.state.sortFilterDesignation.length === 0 ||
      this.state.sortFilterUsername.length === 0 ||
      this.state.sortFilterMobile.length === 0 ||
      this.state.sortFilterEmail.length === 0
    ) {
      return false;
    }
    if (data === "designation") {
      if (
        this.state.suserNameFilterCheckbox !== "" ||
        this.state.smobileNumberFilterCheckbox !== "" ||
        this.state.semailIDFilterCheckbox !== ""
      ) {
        this.setState({
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      } else {
        this.setState({
          suserNameFilterCheckbox: "",
          smobileNumberFilterCheckbox: "",
          semailIDFilterCheckbox: "",
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      }
    }
    if (data === "userName") {
      if (
        this.state.sdesignationFilterCheckbox !== "" ||
        this.state.smobileNumberFilterCheckbox !== "" ||
        this.state.semailIDFilterCheckbox !== ""
      ) {
        this.setState({
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      } else {
        this.setState({
          sdesignationFilterCheckbox: "",
          smobileNumberFilterCheckbox: "",
          semailIDFilterCheckbox: "",
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      }
    }
    if (data === "mobileNumber") {
      if (
        this.state.sdesignationFilterCheckbox !== "" ||
        this.state.suserNameFilterCheckbox !== "" ||
        this.state.semailIDFilterCheckbox !== ""
      ) {
        this.setState({
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      } else {
        this.setState({
          sdesignationFilterCheckbox: "",
          suserNameFilterCheckbox: "",
          semailIDFilterCheckbox: "",
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      }
    }
    if (data === "emailID") {
      if (
        this.state.sdesignationFilterCheckbox !== "" ||
        this.state.suserNameFilterCheckbox !== "" ||
        this.state.smobileNumberFilterCheckbox !== ""
      ) {
        this.setState({
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      } else {
        this.setState({
          sdesignationFilterCheckbox: "",
          suserNameFilterCheckbox: "",
          smobileNumberFilterCheckbox: "",
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      }
    }
  }
  StatusCloseModel() {
    if (this.state.tempuserData.length > 0) {
      this.setState({
        StatusModel: false,
        userData: this.state.tempuserData,
        filterTxtValue: "",
        sortFilterDesignation: this.state.sortDesignation,
        sortFilterUsername: this.state.sortUsername,
        sortFilterMobile: this.state.sortMobile,
        sortFilterEmail: this.state.sortEmail,
      });
      if (this.state.sortColumn === "designation") {
        if (this.state.sdesignationFilterCheckbox === "") {
        } else {
          this.setState({
            suserNameFilterCheckbox: "",
            smobileNumberFilterCheckbox: "",
            semailIDFilterCheckbox: "",
          });
        }
      }
      if (this.state.sortColumn === "userName") {
        if (this.state.suserNameFilterCheckbox === "") {
        } else {
          this.setState({
            sdesignationFilterCheckbox: "",
            smobileNumberFilterCheckbox: "",
            semailIDFilterCheckbox: "",
          });
        }
      }
      if (this.state.sortColumn === "mobileNumber") {
        if (this.state.smobileNumberFilterCheckbox === "") {
        } else {
          this.setState({
            sdesignationFilterCheckbox: "",
            suserNameFilterCheckbox: "",
            semailIDFilterCheckbox: "",
          });
        }
      }
      if (this.state.sortColumn === "emailID") {
        if (this.state.semailIDFilterCheckbox === "") {
        } else {
          this.setState({
            sdesignationFilterCheckbox: "",
            suserNameFilterCheckbox: "",
            smobileNumberFilterCheckbox: "",
          });
        }
      }
    } else {
      if (this.state.isortA) {
        this.setState({
          StatusModel: false,
          userData: this.state.userData,
          filterTxtValue: "",
          sortFilterDesignation: this.state.sortDesignation,
          sortFilterUsername: this.state.sortUsername,
          sortFilterMobile: this.state.sortMobile,
          sortFilterEmail: this.state.sortEmail,
        });
      } else {
        this.setState({
          StatusModel: false,
          userData: this.state.sortAllData,
          filterTxtValue: "",
          sortFilterDesignation: this.state.sortDesignation,
          sortFilterUsername: this.state.sortUsername,
          sortFilterMobile: this.state.sortMobile,
          sortFilterEmail: this.state.sortEmail,
        });
      }
    }
  }

  setSortCheckStatus = (column, type, e) => {
    var itemsArray = [];

    var suserNameFilterCheckbox = this.state.suserNameFilterCheckbox;
    var sdesignationFilterCheckbox = this.state.sdesignationFilterCheckbox;
    var smobileNumberFilterCheckbox = this.state.smobileNumberFilterCheckbox;
    var semailIDFilterCheckbox = this.state.semailIDFilterCheckbox;
    if (column === "userName" || column === "all") {
      if (type === "value" && type !== "All") {
        suserNameFilterCheckbox = suserNameFilterCheckbox.replace("all", "");
        suserNameFilterCheckbox = suserNameFilterCheckbox.replace("all,", "");
        if (suserNameFilterCheckbox.includes(e.currentTarget.value)) {
          suserNameFilterCheckbox = suserNameFilterCheckbox.replace(
            e.currentTarget.value + ",",
            ""
          );
        } else {
          suserNameFilterCheckbox += e.currentTarget.value + ",";
        }
      } else {
        if (suserNameFilterCheckbox.includes("all")) {
          suserNameFilterCheckbox = "";
        } else {
          if (this.state.sortColumn === "userName") {
            for (let i = 0; i < this.state.sortUsername.length; i++) {
              suserNameFilterCheckbox +=
                this.state.sortUsername[i].userName + ",";
            }
            suserNameFilterCheckbox += "all";
          }
        }
      }
    }
    if (column === "designation" || column === "all") {
      if (type === "value" && type !== "All") {
        sdesignationFilterCheckbox = sdesignationFilterCheckbox.replace(
          "all",
          ""
        );
        sdesignationFilterCheckbox = sdesignationFilterCheckbox.replace(
          "all,",
          ""
        );
        if (sdesignationFilterCheckbox.includes(e.currentTarget.value)) {
          sdesignationFilterCheckbox = sdesignationFilterCheckbox.replace(
            e.currentTarget.value + ",",
            ""
          );
        } else {
          sdesignationFilterCheckbox += e.currentTarget.value + ",";
        }
      } else {
        if (sdesignationFilterCheckbox.includes("all")) {
          sdesignationFilterCheckbox = "";
        } else {
          if (this.state.sortColumn === "designation") {
            for (let i = 0; i < this.state.sortDesignation.length; i++) {
              sdesignationFilterCheckbox +=
                this.state.sortDesignation[i].designation + ",";
            }
            sdesignationFilterCheckbox += "all";
          }
        }
      }
    }
    if (column === "mobileNumber" || column === "all") {
      if (type === "value" && type !== "All") {
        smobileNumberFilterCheckbox = smobileNumberFilterCheckbox.replace(
          "all",
          ""
        );
        smobileNumberFilterCheckbox = smobileNumberFilterCheckbox.replace(
          "all,",
          ""
        );
        if (smobileNumberFilterCheckbox.includes(e.currentTarget.value)) {
          smobileNumberFilterCheckbox = smobileNumberFilterCheckbox.replace(
            e.currentTarget.value + ",",
            ""
          );
        } else {
          smobileNumberFilterCheckbox += e.currentTarget.value + ",";
        }
      } else {
        if (smobileNumberFilterCheckbox.includes("all")) {
          smobileNumberFilterCheckbox = "";
        } else {
          if (this.state.sortColumn === "mobileNumber") {
            for (let i = 0; i < this.state.sortMobile.length; i++) {
              smobileNumberFilterCheckbox +=
                this.state.sortMobile[i].mobileNumber + ",";
            }
            smobileNumberFilterCheckbox += "all";
          }
        }
      }
    }
    if (column === "emailID" || column === "all") {
      if (type === "value" && type !== "All") {
        semailIDFilterCheckbox = semailIDFilterCheckbox.replace("all", "");
        semailIDFilterCheckbox = semailIDFilterCheckbox.replace("all,", "");
        if (semailIDFilterCheckbox.includes(e.currentTarget.value)) {
          semailIDFilterCheckbox = semailIDFilterCheckbox.replace(
            e.currentTarget.value + ",",
            ""
          );
        } else {
          semailIDFilterCheckbox += e.currentTarget.value + ",";
        }
      } else {
        if (semailIDFilterCheckbox.includes("all")) {
          semailIDFilterCheckbox = "";
        } else {
          if (this.state.sortColumn === "emailID") {
            for (let i = 0; i < this.state.sortEmail.length; i++) {
              semailIDFilterCheckbox += this.state.sortEmail[i].emailID + ",";
            }
            semailIDFilterCheckbox += "all";
          }
        }
      }
    }

    var allData = this.state.sortAllData;

    this.setState({
      sdesignationFilterCheckbox,
      suserNameFilterCheckbox,
      smobileNumberFilterCheckbox,
      semailIDFilterCheckbox,
      userColor: "",
      mobileColor: "",
      emailColor: "",
      designationColor: "",
    });
    if (column === "all") {
      itemsArray = this.state.sortAllData;
    } else if (column === "designation") {
      var sItems = sdesignationFilterCheckbox.split(",");
      if (sItems.length > 0) {
        for (let i = 0; i < sItems.length; i++) {
          if (sItems[i] !== "") {
            var tempFilterData = allData.filter(
              (a) => a.designation === sItems[i]
            );
            if (tempFilterData.length > 0) {
              for (let j = 0; j < tempFilterData.length; j++) {
                itemsArray.push(tempFilterData[j]);
              }
            }
          }
        }
      }
      this.setState({
        designationColor: "sort-column",
      });
    } else if (column === "userName") {
      var sItems = suserNameFilterCheckbox.split(",");
      if (sItems.length > 0) {
        for (let i = 0; i < sItems.length; i++) {
          if (sItems[i] !== "") {
            var tempFilterData = allData.filter(
              (a) => a.userName === sItems[i]
            );
            if (tempFilterData.length > 0) {
              for (let j = 0; j < tempFilterData.length; j++) {
                itemsArray.push(tempFilterData[j]);
              }
            }
          }
        }
      }
      this.setState({
        userColor: "sort-column",
      });
    } else if (column === "mobileNumber") {
      var sItems = smobileNumberFilterCheckbox.split(",");
      if (sItems.length > 0) {
        for (let i = 0; i < sItems.length; i++) {
          if (sItems[i] !== "") {
            var tempFilterData = allData.filter(
              (a) => a.mobileNumber === sItems[i]
            );
            if (tempFilterData.length > 0) {
              for (let j = 0; j < tempFilterData.length; j++) {
                itemsArray.push(tempFilterData[j]);
              }
            }
          }
        }
      }
      this.setState({
        mobileColor: "sort-column",
      });
    } else if (column === "emailID") {
      var sItems = semailIDFilterCheckbox.split(",");
      if (sItems.length > 0) {
        for (let i = 0; i < sItems.length; i++) {
          if (sItems[i] !== "") {
            var tempFilterData = allData.filter((a) => a.emailID === sItems[i]);
            if (tempFilterData.length > 0) {
              for (let j = 0; j < tempFilterData.length; j++) {
                itemsArray.push(tempFilterData[j]);
              }
            }
          }
        }
      }
      this.setState({
        emailColor: "sort-column",
      });
    }
    this.setState({
      tempuserData: itemsArray,
    });
  };

  opneEditModal = () => {
    this.setState({ editmodel: true });
  };
  closeEditModal() {
    this.setState({ editmodel: false, selTab: "Personal Details" });
  }

  togglePopover() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  hide(e, id) {
    document.getElementById(
      id
    ).parentElement.parentElement.parentElement.parentElement.parentElement.style.display =
      "none";
  }
  show(e, id) {
    if (document.getElementById(id))
      document.getElementById(
        id
      ).parentElement.parentElement.parentElement.parentElement.parentElement.style.display =
        "block";
  }
  setUserEditData = (e) => {
    let self = this;
    var brand = [];
    var cat = [];
    var subcat = [];
    var issue = [];
    var userEditData = e;
    userEditData.user_Id = userEditData.userId;
    userEditData.selectUserName = userEditData.userName;
    userEditData.first_Name = userEditData.firstName;
    userEditData.last_Name = userEditData.lastName;
    userEditData.mobile_Number = userEditData.mobileNumber;
    userEditData.email_ID = userEditData.emailID;
    userEditData.designation_ID = userEditData.designationID;
    userEditData.reportee_ID =
      userEditData.reporteeID === 0 ? "-1" : userEditData.reporteeID;
    userEditData.brand_IDs = userEditData.brandIDs;
    userEditData.brand_Names = userEditData.brandNames;
    userEditData.category_IDs = userEditData.categoryIDs;
    userEditData.category_Names = userEditData.categoryNames;
    userEditData.subCategory_IDs = userEditData.subCategoryIDs;
    userEditData.subCategory_Names = userEditData.subCategoryNames;
    userEditData.issueType_IDs = userEditData.issueTypeIDs;
    userEditData.issueType_Names = userEditData.issueTypeNames;
    userEditData.is_Copy_Escalation = userEditData.is_CopyEscalation;
    userEditData.is_Assign_Escalation = userEditData.is_AssignEscalation;
    userEditData.role_ID = userEditData.roleID;
    userEditData.assign_ID = userEditData.assignID;
    userEditData.assign_Escalation = userEditData.assignEscalation;
    userEditData.assign_Name = userEditData.assignName;
    userEditData.reporteeDesignation_ID =
      userEditData.reporteeDesignationID === 0
        ? "-1"
        : userEditData.reporteeDesignationID;

    if (userEditData.isActive === true) {
      userEditData.is_Active = "true";
    } else {
      userEditData.is_Active = "false";
    }
    if (userEditData.assign_Escalation === "Agent") {
      var agent = true;
      var supervi = false;
    } else if (userEditData.assign_Escalation === "Supervisor") {
      var supervi = true;
      var agent = false;
    }
    var bname = userEditData.brand_Names.split(",");
    var bid = userEditData.brand_IDs.split(",").map(Number);

    var catname = userEditData.category_Names.split(",");
    var catid = userEditData.category_IDs.split(",").map(Number);

    var subcatname = userEditData.subCategory_Names.split(",");
    var subcatid = userEditData.subCategory_IDs.split(",").map(Number);

    var issuename = userEditData.issueType_Names.split(",");
    var issueid = userEditData.issueType_IDs.split(",").map(Number);
    if (userEditData.brand_IDs !== "") {
      for (let i = 0; i < bid.length; i++) {
        brand.push({ brandID: bid[i], brandName: bname[i] });
      }
    }

    if (userEditData.category_IDs !== "") {
      for (let i = 0; i < catid.length; i++) {
        cat.push({ categoryID: catid[i], categoryName: catname[i] });
      }
    }
    if (userEditData.subCategory_IDs !== "") {
      for (let i = 0; i < subcatid.length; i++) {
        subcat.push({
          subCategoryID: subcatid[i],
          subCategoryName: subcatname[i],
        });
      }
    }
    if (userEditData.issueType_IDs !== "") {
      for (let i = 0; i < issueid.length; i++) {
        issue.push({ issueTypeID: issueid[i], issueTypeName: issuename[i] });
      }
    }

    self.setState({
      userEditData,
      editBrand: brand,
      editCategory: cat,
      editSubCategory: subcat,
      editIssuetype: issue,
      editAgentRadio: agent,
      editSupervisorRadio: supervi,
    });

    self.handleGetReporteedesignationList("edit");
    self.handleGetReportTOList("edit");
    self.handleGetCategoryList("edit");
    self.handleGetSubCategoryList("edit");
    self.handleGetIssueTypeList("edit");
    self.handleGetAgentList("edit");
    self.opneEditModal();
  };

  handleAgentValue = (datar, e) => {
    let subjectvalue = e.currentTarget.checked;
    this.setState({
      selectedSupervisorRadio: false,
      selectedAgentRadio: subjectvalue,
    });
    setTimeout(() => {
      if (this.state.selectedAgentRadio === true) {
        this.handleGetAgentList(datar);
      }
    }, 1);
  };

  handleSuperValue = (datar, e) => {
    let subjectvalue = e.currentTarget.checked;
    this.setState({
      selectedAgentRadio: false,
      selectedSupervisorRadio: subjectvalue,
    });
    setTimeout(() => {
      if (this.state.selectedSupervisorRadio === true) {
        this.handleGetAgentList(datar);
      }
    }, 1);
  };

  editAgentValue = (datar, e) => {
    let subjectvalue = e.currentTarget.checked;
    this.setState({ editSupervisorRadio: false, editAgentRadio: subjectvalue });
    setTimeout(() => {
      if (this.state.editAgentRadio === true) {
        this.handleGetAgentList(datar);
      }
    }, 1);
  };

  editSuperValue = (datar, e) => {
    let subjectvalue = e.currentTarget.checked;
    this.setState({ editAgentRadio: false, editSupervisorRadio: subjectvalue });
    setTimeout(() => {
      if (this.state.editSupervisorRadio === true) {
        this.handleGetAgentList(datar);
      }
    }, 1);
  };

  setEscn = (e) => {
    this.setState({ [e.target.name]: e.currentTarget.checked });
  };

  editsetEscn = (e) => {
    var name = e.target.name;
    var value = e.target.checked;
    var data = e.currentTarget.checked;
    var data = this.state.userEditData;
    data[name] = value;
    this.setState({ EditTemp: data });
  };

  handleOnChangeEditData = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    var data = this.state.userEditData;
    data[name] = value;

    if (name === "email_ID") {
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (e.target.value === "") {
        this.setState({
          editEmailFlag: true,
        });
      } else if (reg.test(e.target.value) === false) {
        this.setState({
          editEmailFlag: false,
        });
      } else {
        this.setState({
          editEmailFlag: true,
        });
      }
    }

    this.setState({
      EditTemp: data,
    });
  };

  hanldeMobileNoChange = (e) => {
    var name = e.target.name;
    var reg = /^[0-9\b]+$/;
    if (name === "mobile_Number") {
      if (e.target.value === "" || reg.test(e.target.value)) {
        var value = e.target.value;

        var data = this.state.userEditData;
        data[name] = value;
        this.setState({ EditTemp: data });
      } else {
        e.target.value = "";
      }
      if (e.target.value.length === 10 || e.target.value.length === 0) {
        this.setState({
          EditPhoneFlag: true,
        });
      } else {
        this.setState({
          EditPhoneFlag: false,
        });
      }
    } else {
      if (e.target.value === "" || reg.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
          mobileValidation: "",
        });
      } else {
        e.target.value = "";
      }
      if (e.target.value.length === 10 || e.target.value.length === 0) {
        this.setState({
          phoneFlag: true,
        });
      } else {
        this.setState({
          phoneFlag: false,
        });
      }
    }
  };

  handleOnChangeUserData = (e) => {
    var name = e.target.name;
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (name === "selectEmail") {
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (e.target.value === "") {
        this.setState({
          emailFlag: true,
        });
      } else if (reg.test(e.target.value) === false) {
        this.setState({
          emailFlag: false,
        });
      } else {
        this.setState({
          emailFlag: true,
        });
      }
    }
  };
  handleReporteeDesgnDropDown(data2, e) {
    this.setState({
      [e.target.name]: e.target.value,
    });

    setTimeout(() => {
      if (this.state.selectedReporteeDesign) {
        this.handleGetReportTOList(data2);
      }
    }, 1);
  }
  handleEditReporteeDesgnDropDown(data2, e) {
    var name = e.target.name;
    var value = e.target.value;

    var data = this.state.userEditData;
    data[name] = value;
    data.reportee_ID = 0;

    this.setState({
      EditTemp: data,
    });

    setTimeout(() => {
      if (parseInt(this.state.userEditData.reporteeDesignation_ID)) {
        this.handleGetReportTOList(data2);
      } else {
        let userEditData = this.state.userEditData;
        userEditData.reportee_ID = 0;
        this.setState({
          ReportToData: [],
          userEditData,
        });
      }
    }, 1);
  }

  handleEditDesination = (data1, e) => {
    e.preventDefault();
    e.stopPropagation();
    var name = e.target.name;
    var value = e.target.value;

    var data = this.state.userEditData;
    data[name] = value;

    this.setState({
      EditTemp: data,
    });
    setTimeout(() => {
      if (parseInt(this.state.userEditData.designation_ID)) {
        this.handleGetReporteedesignationList(data1);
      } else {
        let userEditData = this.state.userEditData;
        userEditData.reporteeDesignation_ID = 0;
        userEditData.reportee_ID = 0;
        this.setState({
          ReporteeDesignData: [],
          ReportToData: [],
          userEditData,
        });
      }
    }, 1);
  };

  handleBrandChange = (data, e) => {
    if (e === null) {
      e = [];
      this.setState({ selectedBrand: e, CategoryData: [] });
    } else {
      this.setState({ selectedBrand: e });
      setTimeout(() => {
        if (this.state.selectedBrand) {
          this.handleGetCategoryList(data);
        }
      }, 1);
    }
  };
  handleEditBrandChange = (data, e) => {
    if (e === null) {
      e = [];
      this.setState({ editBrand: e, CategoryData: [] });
    } else {
      if (e !== null) {
        this.setState({ editBrand: e });
      } else {
        this.setState({
          editBrand: [],
          editCategory: [],
          editSubCategory: [],
          editIssuetype: [],
        });
      }

      setTimeout(() => {
        if (this.state.editBrand) {
          this.setState({ editCategory: [] });
          this.handleGetCategoryList(data);
        }
      }, 1);
    }
  };
  handleCategoryChange = (data, e) => {
    if (e === null) {
      e = [];
      this.setState({ selectedCategory: e, SubCategoryData: [] });
    } else {
      this.setState({ selectedCategory: e });
      setTimeout(() => {
        if (this.state.selectedCategory) {
          this.handleGetSubCategoryList(data);
        }
      }, 1);
    }
  };
  handleEditCategoryChange = (data, e) => {
    if (e === null) {
      e = [];
      this.setState({ editCategory: e, SubCategoryData: [] });
    } else {
      if (e !== null) {
        this.setState({ editCategory: e });
      } else {
        this.setState({
          editCategory: [],
          editSubCategory: [],
          editIssuetype: [],
        });
      }

      setTimeout(() => {
        if (this.state.editCategory) {
          this.setState({ editSubCategory: [] });
          this.handleGetSubCategoryList(data);
        }
      }, 1);
    }
  };
  handleSubCategoryChange = (data, e) => {
    if (e === null) {
      e = [];
      this.setState({ selectedSubCategory: e, IssueTypeData: [] });
    } else {
      this.setState({ selectedSubCategory: e });
      setTimeout(() => {
        if (this.state.selectedSubCategory) {
          this.handleGetIssueTypeList(data);
        }
      }, 1);
    }
  };
  handleEditSubCategoryChange = (data, e) => {
    if (e === null) {
      e = [];
      this.setState({ editSubCategory: e, IssueTypeData: [] });
    } else {
      if (this.state.editCategory !== null) {
        if (e !== null) {
          this.setState({ editSubCategory: e });
        } else {
          this.setState({ editSubCategory: [], editIssuetype: [] });
        }

        setTimeout(() => {
          if (this.state.editSubCategory) {
            this.setState({ editIssuetype: [] });
            this.handleGetIssueTypeList(data);
          }
        }, 1);
      } else {
        this.setState({ editSubCategory: [] });
      }
    }
  };
  handleIssueTypeChange = (e) => {
    if (e === null) {
      e = [];
    }
    this.setState({ selectedIssueType: e });
  };
  handleEditIssueTypeChange = (e) => {
    if (e === null) {
      e = [];
    }
    if (e !== null) {
      this.setState({ editIssuetype: e });
    } else {
      this.setState({ editIssuetype: [] });
    }
  };
  handleGetCRMRoleList() {
    let self = this;
    axios({
      method: "post",
      // url: config.apiUrl + "/CRMRole/GetCRMRoleDropdown",
      url: config.apiUrl + "/StoreCRMRole/GetStoreCRMRoles",
      headers: authHeader(),
    })
      .then(function(res) {
        debugger;
        let crmroledata = res.data.responseData;
        self.setState({
          CRMRoleData: crmroledata,
        });
      })
      .catch((data) => {
        console.log(data);
      });
  }

  editProfileMethod() {
    this.setState({
      profileReadOnly: false,
      buttonProfileToggle: true,
    });
  }
  handleGetDesignationList() {
    let self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/StoreHierarchy/GetStoreDesignationList",
      headers: authHeader(),
    })
      .then(function(res) {
        let designationdata = res.data.responseData;

        self.setState({
          DesignationData: designationdata,
        });
      })
      .catch((data) => {
        console.log(data);
      });
  }

  handleGetReporteedesignationList = () => {
    let self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/StoreUser/BindStoreReporteeDesignation",
      headers: authHeader(),
      params: {
        DesignationID: this.state.profileDetailsInput.UserDesignation,
      },
    })
      .then(function(res) {
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({
            reportDesignation: data,
          });
        } else {
          self.setState({
            reportDesignation: [],
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  };
  handleGetReportTOList(data2) {
    let self = this;
    let id;
    if (data2 === "add") {
      id = this.state.selectedReporteeDesign;
    } else if (data2 === "edit") {
      id = this.state.userEditData.reporteeDesignation_ID;
    }
    axios({
      method: "post",
      url: config.apiUrl + "/Designation/GetReportTo",
      headers: authHeader(),
      params: {
        DesignationID: id,
      },
    })
      .then(function(res) {
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({
            ReportToData: data,
          });
        } else {
          self.setState({
            ReportToData: [],
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }
  handleGetBrandList() {
    let self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/Brand/GetBrandList",
      headers: authHeader(),
    })
      .then(function(res) {
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({ brandData: data });
        } else {
          self.setState({ brandData: [] });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }
  handleGetCategoryList() {
    let self = this;
    var finalBrandId = "";
    if (this.state.mapCateogryInput.Brand.length > 0) {
      finalBrandId = this.state.mapCateogryInput.Brand.map(
        (x) => x.brandID
      ).join(",");
    } else {
      return false;
    }

    axios({
      method: "post",
      url: config.apiUrl + "/Category/GetCategoryListByMultiBrandID",
      headers: authHeader(),
      params: {
        BrandIDs: finalBrandId,
      },
    })
      .then(function(res) {
        var status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({
            CategoryData: data,
          });
        } else {
          self.setState({
            CategoryData: [],
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }
  handleGetSubCategoryList() {
    let self = this;
    var finalCategoryId = "";

    if (this.state.mapCateogryInput.Categories.length > 0) {
      finalCategoryId = this.state.mapCateogryInput.Categories.map(
        (x) => x.categoryID
      ).join(",");
    } else {
      return false;
    }

    axios({
      method: "post",
      url: config.apiUrl + "/SubCategory/GetSubCategoryByMultiCategoryID",
      headers: authHeader(),
      params: {
        CategoryIDs: finalCategoryId,
      },
    })
      .then(function(res) {
        var status = res.data.message;
        var data = res.data.responseData;
        if (status === "Success") {
          self.setState({
            SubCategoryData: data,
          });
        } else {
          self.setState({
            SubCategoryData: [],
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }
  handleGetIssueTypeList(data) {
    let self = this;
    var finalSubCategoryId = "";

    if (this.state.mapCateogryInput.SubCategories.length > 0) {
      finalSubCategoryId = this.state.mapCateogryInput.SubCategories.map(
        (x) => x.subCategoryID
      ).join(",");
    } else {
      return false;
    }
    axios({
      method: "post",
      url: config.apiUrl + "/IssueType/GetIssueTypeListByMultiSubCategoryID",
      headers: authHeader(),
      params: {
        SubCategoryIDs: finalSubCategoryId,
      },
    })
      .then(function(res) {
        var status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({
            IssueTypeData: data,
          });
        } else {
          self.setState({
            IssueTypeData: [],
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }
  handleGetAgentList(datar) {
    let self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/Ticketing/getagentlist",
      headers: authHeader(),
      params: {
        TicketID: 0, // Don't change this value (Set on API side)
      },
    })
      .then(function(res) {
        var array = [];
        var agentdata = res.data.responseData;
        var addvalue1 = self.state.selectedAgentRadio;
        var addvalue2 = self.state.selectedSupervisorRadio;
        var editvalue1 = self.state.editAgentRadio;
        var editvalue2 = self.state.editSupervisorRadio;
        if (datar === "add") {
          if (addvalue1 === true) {
            array = agentdata.filter((a) => a.designation === "Agent");
          } else if (addvalue2 === true) {
            array = agentdata.filter((a) => a.designation === "Supervisor");
          }
        } else if (datar === "edit") {
          if (editvalue1 === true) {
            array = agentdata.filter((a) => a.designation === "Agent");
          } else if (editvalue2 === true) {
            array = agentdata.filter((a) => a.designation === "Supervisor");
          }
        }

        self.setState({ AgentData: array });
      })
      .catch((data) => {
        console.log(data);
      });
  }
  handleGetUserList() {
    let self = this;
    axios({
      method: "get",
      url: config.apiUrl + "/User/GetUserListData",
      headers: authHeader(),
    })
      .then(function(res) {
        var userdata = res.data.responseData;
        var status = res.data.message;
        if (status === "Success") {
          self.setState({
            userData: userdata,
          });
        } else {
          self.setState({
            userData: [],
          });
        }

        if (userdata !== null) {
          self.state.sortAllData = userdata;
          var unique = [];
          var distinct = [];
          for (let i = 0; i < userdata.length; i++) {
            if (
              !unique[userdata[i].designation] &&
              userdata[i].designation !== ""
            ) {
              distinct.push(userdata[i].designation);
              unique[userdata[i].designation] = 1;
            }
          }
          for (let i = 0; i < distinct.length; i++) {
            self.state.sortDesignation.push({
              designation: distinct[i],
            });
            self.state.sortFilterDesignation.push({
              designation: distinct[i],
            });
          }

          var unique = [];
          var distinct = [];
          for (let i = 0; i < userdata.length; i++) {
            if (!unique[userdata[i].userName] && userdata[i].userName !== "") {
              distinct.push(userdata[i].userName);
              unique[userdata[i].userName] = 1;
            }
          }
          for (let i = 0; i < distinct.length; i++) {
            self.state.sortUsername.push({
              userName: distinct[i],
            });
            self.state.sortFilterUsername.push({
              userName: distinct[i],
            });
          }

          var unique = [];
          var distinct = [];
          for (let i = 0; i < userdata.length; i++) {
            if (
              !unique[userdata[i].mobileNumber] &&
              userdata[i].mobileNumber !== ""
            ) {
              distinct.push(userdata[i].mobileNumber);
              unique[userdata[i].mobileNumber] = 1;
            }
          }
          for (let i = 0; i < distinct.length; i++) {
            self.state.sortMobile.push({
              mobileNumber: distinct[i],
            });
            self.state.sortFilterMobile.push({
              mobileNumber: distinct[i],
            });
          }

          var unique = [];
          var distinct = [];
          for (let i = 0; i < userdata.length; i++) {
            if (!unique[userdata[i].emailID] && userdata[i].emailID !== "") {
              distinct.push(userdata[i].emailID);
              unique[userdata[i].emailID] = 1;
            }
          }
          for (let i = 0; i < distinct.length; i++) {
            self.state.sortEmail.push({
              emailID: distinct[i],
            });
            self.state.sortFilterEmail.push({
              emailID: distinct[i],
            });
          }
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  handleGetUserListByID(id) {
    let self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/User/GetUserDetailsById",
      headers: authHeader(),
      params: {
        UserID: id,
      },
    })
      .then(function(res) {
        var status = res.data.message;
        var userdata = res.data.responseData;
        if (status === "Success") {
          self.setState({
            GetUserData: userdata,
          });
          self.setUserEditData(userdata, id);
        } else {
          self.setState({
            GetUserData: [],
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  handleValidationEmailIdMob() {
    const TranslationContext = this.state.translateLanguage.default;

    if (
      this.state.selectUserName.length > 0 &&
      this.state.selectFirstName.length > 0 &&
      this.state.selectMobile.length > 0 &&
      this.state.selectEmail.length > 0 &&
      this.state.emailFlag === true &&
      this.state.phoneFlag === true
    ) {
      this.state.emailValidation = "";
      this.state.mobileValidation = "";
      let self = this;
      axios({
        method: "post",
        url: config.apiUrl + "/User/validateUserExist",
        headers: authHeader(),
        params: {
          UserEmailID: this.state.selectEmail,
          UserMobile: this.state.selectMobile,
        },
      })
        .then(function(res) {
          var status = res.data.message;
          var userdata = res.data.responseData;
          if (status === "Success") {
            if (userdata === "Email Id already exist!") {
              self.setState({
                emailValidation: "Email Id already exist!",
              });
            } else if (userdata === "Phone number already exist!") {
              self.setState({
                mobileValidation: "Phone number already exist!",
              });
            } else if (
              userdata === "Email Id and Phone number both are already exist!"
            ) {
              self.setState({
                emailValidation: "Email Id already exist!",
                mobileValidation: "Phone number already exist!",
              });
            } else if (userdata === "Not Exist") {
              self.handleAddPersonalDetails();
            }
          }
        })
        .catch((data) => {
          console.log(data);
        });
    } else {
      this.setState({
        usernameCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseenterusername
            : "Please enter user name.",
        firstnameCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseenterfirstname
            : "Please enter first name.",
        mobilenumberCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.pleaseentermobilenumber
            : "Please enter mobile number.",
        emailCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.label.pleaseenteremailid
            : "Please enter emailID.",
      });
    }
  }

  handleAddPersonalDetails(isUpdate) {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/StoreUser/AddStoreUserPersonalDetail",
      headers: authHeader(),
      data: {
        UserID: this.state.newUserID,
        UserName: this.state.personalDetailsInput.UserName,
        MobileNo: this.state.personalDetailsInput.MobileNo,
        EmailID: this.state.personalDetailsInput.EmailID,
        FirstName: this.state.personalDetailsInput.FirstName,
        LastName: this.state.personalDetailsInput.LastName,
        UserTypeID: this.state.createUserType,
      },
    })
      .then(function(res) {
        let status = res.data.message;
        if (status === "Success") {
          if (isUpdate) {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.recordupdatedsuccessfully
                : "Record Updated Successfully."
            );
          } else {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.recordsavedsuccessfully
                : "Record Saved Successfully."
            );
          }
          self.setState({
            newUserID: res.data.responseData,
            personalDetailReadOnly: true,
            createUserActiveKey: "ProfileDetails",
          });
        } else {
          NotificationManager.error(res.data.message);
        }
      })
      .catch((response) => {
        console.log(response);
      });
  }

  handleEditPersonalDetails() {
    const TranslationContext = this.state.translateLanguage.default;
    if (
      this.state.selectUserName.length > 0 &&
      this.state.selectFirstName.length > 0 &&
      this.state.selectMobile.length > 0 &&
      this.state.selectEmail.length > 0
    ) {
      let self = this;
      var id = this.state.getID;
      var json = {
        UserName: this.state.selectUserName,
        FirstName: this.state.selectFirstName,
        LastName: this.state.selectLastName,
        MobileNo: this.state.selectMobile,
        EmailID: this.state.selectEmail,
        UserID: id,
      };
      axios({
        method: "post",
        url: config.apiUrl + "/User/EditUserPersonalDetail",
        headers: authHeader(),
        data: json,
      })
        .then(function(res) {
          let Msg = res.data.message;
          if (Msg === "Success") {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.recordupdatedsuccessfully
                : "Record updated successfully"
            );
          } else {
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.recordnotupdated
                : "Record Not Updated"
            );
          }
          self.setState({
            getID: id,
            personalReadOnly: true,
          });
          self.handleGetUserList();
        })
        .catch((data) => {
          console.log(data);
        });
    } else {
      this.setState({
        usernameCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseenterusername
            : "Please enter user name.",
        firstnameCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseenterfirstname
            : "Please enter first name.",
        mobilenumberCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.pleaseentermobilenumber
            : "Please enter mobile number.",
        emailCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.label.pleaseenteremailid
            : "Please enter emailID.",
      });
    }
  }

  handleAddProfileDetails() {
    const TranslationContext = this.state.translateLanguage.default;
    if (
      this.state.selectedDesignation > 0 &&
      (this.state.selectedReporteeDesign > 0 ||
        this.state.selectedReporteeDesign == -1) &&
      (this.state.selectedReportTO > 0 || this.state.selectedReportTO == -1)
    ) {
      let self = this;
      let id = this.state.getID;
      axios({
        method: "post",
        url: config.apiUrl + "/User/AddUserProfileDetail",
        headers: authHeader(),
        params: {
          UserID: id,
          DesignationID: this.state.selectedDesignation,
          ReportTo:
            this.state.selectedReportTO === -1
              ? 0
              : this.state.selectedReportTO,
        },
      })
        .then(function(res) {
          let Msg = res.data.message;
          if (self.state.buttonProfileToggle === true) {
            if (Msg === "Success") {
              NotificationManager.success(
                TranslationContext !== undefined
                  ? TranslationContext.alertmessage.recordupdatedsuccessfully
                  : "Record updated successfully"
              );
              self.setState({
                mappedCategoryBtnDisabled: false,
              });
            } else {
              NotificationManager.error(
                TranslationContext !== undefined
                  ? TranslationContext.alertmessage.pleaseaddpersonaldetails
                  : "Please Add Personal Details"
              );
            }
          } else {
            if (Msg === "Success") {
              NotificationManager.success(
                TranslationContext !== undefined
                  ? TranslationContext.alertmessage.recordsavedsuccessfully
                  : "Record saved successfully"
              );
              self.setState({
                getID: id,
                profileReadOnly: true,
                mappedCategoryBtnDisabled: false,
              });
              self.handleGetUserList();
            } else {
              NotificationManager.error(
                TranslationContext !== undefined
                  ? TranslationContext.alertmessage.pleaseaddpersonaldetails
                  : "Please Add Personal Details"
              );
            }
          }
        })
        .catch((data) => {
          console.log(data);
        });
    } else {
      this.setState({
        userdesignCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectdesignation
            : "Please select designation.",
        reporteeDesignCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectreporteedesignation
            : "Please select reportee designation.",
        reportToCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectreportee
            : "Please select reportee.",
      });
    }
  }

  handleDeleteUser(id) {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/User/DeleteUser",
      headers: authHeader(),
      params: {
        userID: id,
      },
    })
      .then(function(res) {
        let Msg = res.data.message;
        if (Msg === "Record In use") {
          NotificationManager.error(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.recordinuse
              : "Record in use"
          );
        } else if (Msg === "Record deleted Successfully") {
          NotificationManager.success(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.recorddeletedsuccessfully
              : "Record Deleted Successfully"
          );
          self.handleGetUserList();
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }
  handleSendMail(id) {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;

    axios({
      method: "post",
      url: config.apiUrl + "/User/SendMailforchangepassword",
      headers: authHeader(),
      params: {
        userID: id,
      },
    })
      .then(function(res) {
        let reportto = res.data.responseData;
        if (reportto === "Mail sent successfully") {
          NotificationManager.success(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.pleasecheckemail
              : "Please Check Email"
          );
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }
  handleUpdateUser() {
    const TranslationContext = this.state.translateLanguage.default;
    var radiob = true;
    var agentb = true;
    if (this.state.userEditData.is_Assign_Escalation === true) {
      if (
        this.state.editSupervisorRadio !== true &&
        this.state.editAgentRadio !== true
      ) {
        radiob = false;
      } else {
        radiob = true;
      }
    }
    if (this.state.editAgentRadio === true) {
      if (
        this.state.userEditData.assign_ID === 0 ||
        this.state.userEditData.assign_ID === "Select Agent"
      ) {
        agentb = false;
      } else {
        agentb = true;
      }
    }

    if (
      this.state.userEditData.selectUserName.length > 0 &&
      this.state.userEditData.first_Name.length > 0 &&
      this.state.userEditData.mobile_Number.length > 0 &&
      this.state.userEditData.email_ID.length > 0 &&
      this.state.userEditData.designation_ID > 0 &&
      (this.state.userEditData.reporteeDesignation_ID > 0 ||
        this.state.userEditData.reporteeDesignation_ID == -1) &&
      (this.state.userEditData.reportee_ID > 0 ||
        this.state.userEditData.reportee_ID == -1) &&
      this.state.editBrand !== null &&
      this.state.editBrand.length > 0 &&
      this.state.editCategory !== null &&
      this.state.editCategory.length > 0 &&
      this.state.editSubCategory !== null &&
      this.state.editSubCategory.length > 0 &&
      this.state.editIssuetype !== null &&
      this.state.editIssuetype.length > 0 &&
      this.state.userEditData.role_ID > 0 &&
      this.state.editEmailFlag === true &&
      this.state.EditPhoneFlag === true &&
      radiob === true &&
      agentb === true
    ) {
      let self = this;

      var finalIssueTypeId = "";
      var finalBrandId = "";
      var finalCategoryId = "";
      var finalSubCategoryId = "";
      var copyescn = 0;
      var assignescn = 0;
      var activeStatus = 0;

      if (this.state.editBrand !== null) {
        for (let i = 0; i < this.state.editBrand.length; i++) {
          finalBrandId += this.state.editBrand[i].brandID + ",";
        }
      }

      if (this.state.editCategory !== null) {
        for (let i = 0; i < this.state.editCategory.length; i++) {
          finalCategoryId += this.state.editCategory[i].categoryID + ",";
        }
      }

      if (this.state.editIssuetype !== null) {
        for (let i = 0; i < this.state.editIssuetype.length; i++) {
          finalIssueTypeId += this.state.editIssuetype[i].issueTypeID + ",";
        }
      }
      if (this.state.editSubCategory !== null) {
        for (let i = 0; i < this.state.editSubCategory.length; i++) {
          finalSubCategoryId +=
            this.state.editSubCategory[i].subCategoryID + ",";
        }
      }
      var CopyE = this.state.userEditData.is_Copy_Escalation;
      var AssignE = this.state.userEditData.is_Assign_Escalation;
      if (CopyE === true && AssignE === false) {
        copyescn = 1;
        assignescn = 0;
      } else if (CopyE === false && AssignE === true) {
        copyescn = 0;
        assignescn = 1;
      } else if (CopyE === true && AssignE === true) {
        copyescn = 1;
        assignescn = 1;
      } else if (CopyE === false && AssignE === false) {
        copyescn = 0;
        assignescn = 0;
      }
      var SuperAgent = 0;

      var superAgentValue = this.state.editAgentRadio;
      if (superAgentValue === true) {
        SuperAgent = 1;
      } else {
        SuperAgent = 0;
      }

      var status = this.state.userEditData.is_Active;
      if (status === "true") {
        activeStatus = 1;
      } else {
        activeStatus = 0;
      }

      var brand = finalBrandId.substring(0, finalBrandId.length - 1);
      var category = finalCategoryId.substring(0, finalCategoryId.length - 1);
      var subcat = finalSubCategoryId.substring(
        0,
        finalSubCategoryId.length - 1
      );
      var issue = finalIssueTypeId.substring(0, finalIssueTypeId.length - 1);
      var json = {
        UserID: this.state.userEditData.userId,
        DesignationID: this.state.userEditData.designation_ID,
        ReporteeID:
          this.state.userEditData.reportee_ID == -1
            ? 0
            : this.state.userEditData.reportee_ID,
        UserName: this.state.userEditData.selectUserName,
        EmailID: this.state.userEditData.email_ID,
        MobileNo: this.state.userEditData.mobile_Number,
        FirstName: this.state.userEditData.first_Name,
        LastName: this.state.userEditData.last_Name,
        BrandIds: brand,
        categoryIds: category,
        subCategoryIds: subcat,
        IssuetypeIds: issue,
        RoleID: this.state.userEditData.role_ID,
        IsCopyEscalation: copyescn,
        IsAssignEscalation: assignescn,
        IsAgent: SuperAgent,
        EscalateAssignToId: this.state.userEditData.assign_ID,
        IsActive: activeStatus,
      };
      axios({
        method: "post",
        url: config.apiUrl + "/User/EditUserDetails",
        headers: authHeader(),
        data: json,
      })
        .then(function(res) {
          let Msg = res.data.message;
          if (Msg === "Success") {
            self.closeEditModal();
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.recordupdatedsuccessfully
                : "Record updated successfully"
            );
            if (self.state.GetUserData.isActive === false) {
              self.handleSendMail(self.state.userEditData.userId);
            }
            self.setState({
              multibrandIDs: finalBrandId,
              multicategoryIDs: finalCategoryId,
              multisubcategoryIDs: finalSubCategoryId,
            });
          } else {
            self.closeEditModal();
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.recordnotupdated
                : "Record Not Updated"
            );
          }
          self.handleGetUserList();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({
        editusernameCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseenterusername
            : "Please enter user name.",
        editfirstnameCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseenterfirstname
            : "Please enter first name.",
        editmobilenumberCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.pleaseentermobilenumber
            : "Please enter mobile number.",
        editemailCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.label.pleaseenteremailid
            : "Please enter emailID.",
        edituserdesignCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectdesignation
            : "Please select designation.",
        editreporteeDesignCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectreporteedesignation
            : "Please select reportee designation.",
        editreportToCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectreportee
            : "Please select reportee.",
        editbrandCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectbrands
            : "Please select brands.",
        editcategoryCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectcategory
            : "Please select category.",
        editsubcategoryCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectsubcategory
            : "Please select subcategory.",
        editisuuetypeCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectissuetype
            : "Please select issuetype.",
        editcrmroleCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectcrmroles
            : "Please select  crm roles.",
        editRadioCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectanyoption
            : "Please select any option.",
        editagentCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectagent
            : "Please select agent.",
      });
    }
  }
  // Onchange tab Profile to Personal tab
  handleChangePersonalTab = () => {
    const TranslationContext = this.state.translateLanguage.default;
    if (
      this.state.userEditData.selectUserName.length > 0 &&
      this.state.userEditData.first_Name.length > 0 &&
      this.state.userEditData.mobile_Number.length > 0 &&
      this.state.userEditData.email_ID.length > 0
    ) {
      this.setState({
        selTab:
          TranslationContext !== undefined
            ? TranslationContext.label.profiledetails
            : "Profile Details",
      });
    } else {
      this.setState({
        editusernameCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseenterusername
            : "Please enter user name.",
        editfirstnameCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseenterfirstname
            : "Please enter first name.",
        editmobilenumberCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.pleaseentermobilenumber
            : "Please enter mobile number.",
        editemailCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.label.pleaseenteremailid
            : "Please enter emailID.",
      });
    }
  };
  // Onchange tab Personal to Mapped tab
  handleChangeProfileTab = () => {
    const TranslationContext = this.state.translateLanguage.default;

    if (
      this.state.userEditData.designation_ID > 0 &&
      (this.state.userEditData.reporteeDesignation_ID > 0 ||
        this.state.userEditData.reporteeDesignation_ID == -1) &&
      (this.state.userEditData.reportee_ID > 0 ||
        this.state.userEditData.reportee_ID == -1 ||
        (this.state.userEditData.reportee_ID !== 0 &&
          this.state.userEditData.reportee_ID !== "0"))
    ) {
      this.setState({
        selTab:
          TranslationContext !== undefined
            ? TranslationContext.a.mappedcategory
            : "Mapped Category",
      });
    } else {
      this.setState({
        edituserdesignCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectdesignation
            : "Please select designation.",
        editreporteeDesignCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectreporteedesignation
            : "Please select reportee designation.",
        editreportToCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectreportee
            : "Please select reportee.",
      });
    }
  };

  fileUpload = (e) => {
    var allFiles = [];
    var selectedFiles = e;
    if (selectedFiles) {
      allFiles.push(selectedFiles[0]);

      var fileSize = formatSizeUnits(selectedFiles[0].size);
      this.setState({
        fileSize,
        fileN: allFiles,
        fileName: allFiles[0].name,
        bulkuploadCompulsion: "",
      });
    }
  };
  fileDrop = (e) => {
    this.setState({ fileName: e.dataTransfer.files[0].name });
    e.preventDefault();
  };
  fileDragOver = (e) => {
    e.preventDefault();
  };
  fileDragEnter = (e) => {
    e.preventDefault();
  };

  filteTextChange(e) {
    this.setState({ filterTxtValue: e.target.value });

    if (this.state.sortColumn === "designation") {
      var sortFilterDesignation = matchSorter(
        this.state.sortDesignation,
        e.target.value,
        { keys: ["designation"] }
      );
      if (sortFilterDesignation.length > 0) {
        this.setState({ sortFilterDesignation });
      } else {
        this.setState({ sortFilterDesignation: this.state.sortDesignation });
      }
    }
    if (this.state.sortColumn === "userName") {
      var sortFilterUsername = matchSorter(
        this.state.sortUsername,
        e.target.value,
        { keys: ["userName"] }
      );
      if (sortFilterUsername.length > 0) {
        this.setState({ sortFilterUsername });
      } else {
        this.setState({
          sortFilterUsername: this.state.sortUsername,
        });
      }
    }
    if (this.state.sortColumn === "mobileNumber") {
      var sortFilterMobile = matchSorter(
        this.state.sortMobile,
        e.target.value,
        { keys: ["mobileNumber"] }
      );
      if (sortFilterMobile.length > 0) {
        this.setState({ sortFilterMobile });
      } else {
        this.setState({
          sortFilterMobile: this.state.sortMobile,
        });
      }
    }
    if (this.state.sortColumn === "emailID") {
      var sortFilterEmail = matchSorter(this.state.sortEmail, e.target.value, {
        keys: ["emailID"],
      });
      if (sortFilterEmail.length > 0) {
        this.setState({ sortFilterEmail });
      } else {
        this.setState({
          sortFilterEmail: this.state.sortEmail,
        });
      }
    }
  }

  handleDeleteBulkupload = (e) => {
    const TranslationContext = this.state.translateLanguage.default;

    this.setState({
      fileN: [],
      fileName: "",
      isOpen: false,
    });
    NotificationManager.success(
      TranslationContext !== undefined
        ? TranslationContext.alertmessage.filedeletedsuccessfully
        : "File deleted successfully."
    );
  };
  hanldeAddBulkUpload() {
    const TranslationContext = this.state.translateLanguage.default;

    if (this.state.fileN.length > 0 && this.state.fileN !== []) {
      if (this.state.fileN[0].path.split(".")[1] === "csv") {
        let self = this;
        this.setState({
          bulkuploadLoading: true,
        });
        const formData = new FormData();

        formData.append("file", this.state.fileN[0]);
        axios({
          method: "post",
          url: config.apiUrl + "/User/BulkUploadUser",
          headers: authHeader(),
          data: formData,
        })
          .then(function(res) {
            let status = res.data.message;
            let data = res.data.responseData;
            if (status === "Success") {
              NotificationManager.success(
                TranslationContext !== undefined
                  ? TranslationContext.alertmessage.fileuploadedsuccessfully
                  : "File uploaded successfully."
              );
              self.setState({
                fileName: "",
                fileSize: "",
                fileN: [],
                bulkuploadLoading: false,
              });
              self.handleGetUserList();
            } else {
              self.setState({
                showProgress: false,
                bulkuploadLoading: false,
              });
              NotificationManager.error(
                TranslationContext !== undefined
                  ? TranslationContext.alertmessage.filenotuploaded
                  : "File not uploaded."
              );
            }
          })
          .catch((data) => {
            if (data.message) {
              this.setState({
                showProgress: false,
                isFileUploadFail: true,
                bulkuploadLoading: false,
              });
            }
            console.log(data);
          });
      } else {
        NotificationManager.error("Only CSV files allowed.");
      }
    } else {
      this.setState({
        bulkuploadCompulsion:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectfile
            : "Please select file.",
      });
    }
  }
  updateUploadProgress(value) {
    this.setState({ progressValue: value });
  }
  handleCreateUserType = (e) => {
    if (e.target.value === 1 || e.target.value === 2) {
      this.setState({
        createUserActiveKey: "StoreDetails",
        createUserType: e.target.value,
      });
    } else {
      this.setState({
        createUserActiveKey: "PersonalDetails",
        createUserType: e.target.value,
      });
    }
  };
  handleCreateUserTypeCollapceChange = (e) => {
    this.setState({
      createUserActiveKey: e,
    });
  };
  handleStoreDetailsInputChange = async (event) => {
    let storeDetailsInput = this.state.storeDetailsInput;
    let storeDetailsErrors = this.state.storeDetailsErrors;
    storeDetailsInput[event.target.name] = event.target.value;
    storeDetailsErrors[event.target.name] = "";
    if (event.target.name === "brandID" && event.target.value) {
      this.handleGetstoreCodeData();
    }
    if (storeDetailsInput["brandID"] && storeDetailsInput["storeCode"]) {
      this.handleGetDepartmentData();
    }
    await this.setState({
      storeDetailsInput,
      storeDetailsErrors,
    });
  };
  handleStoreDetialsSubmit = () => {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    if (this.handleStoreDetialsValidate()) {
      axios({
        method: "post",
        url: config.apiUrl + "/StoreUser/AddUserBrandStore",
        headers: authHeader(),
        params: {
          brandID: this.state.storeDetailsInput.brandID,
          storeID: this.state.storeDetailsInput.storeCode,
          UserTypeID: this.state.createUserType,
        },
      })
        .then(function(res) {
          let status = res.data.message;
          let data = res.data.responseData;
          if (status === "Success") {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.recordsavedsuccessfully
                : "Record Saved Successfully."
            );
            self.setState({
              newUserID: data,
              storeDetialReadOnly: true,
              storeDetailsErrors: {},
              createUserActiveKey: "PersonalDetails",
            });
          } else {
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.recordnotsaved
                : "Record Not Save."
            );
          }
        })
        .catch((data) => {
          console.log(data);
        });
    }
  };
  handleStoreDetialsValidate = () => {
    let input = this.state.storeDetailsInput;
    let storeDetailsErrors = {};
    let isValid = true;

    if (!input["brandID"]) {
      isValid = false;
      storeDetailsErrors["brandID"] = "Please select brand name.";
    }

    if (!input["storeCode"]) {
      isValid = false;
      storeDetailsErrors["storeCode"] = "Please select store code.";
    }
    this.setState({
      storeDetailsErrors,
    });
    return isValid;
  };
  ////get Store Code for dropdown
  handleGetstoreCodeData() {
    let self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/StoreUser/StoreListByBrand",
      headers: authHeader(),
      params: {
        BrandID: this.state.storeDetailsInput.brandID,
      },
    })
      .then((res) => {
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({ storeCodeData: data });
        } else {
          self.setState({ storeCodeData: [] });
        }
      })
      .catch((response) => {
        console.log(response);
      });
  }
  //// handle Update Store details
  handleUpdateStoreDetails() {
    const TranslationContext = this.state.translateLanguage.default;
    if (this.handleStoreDetialsValidate()) {
      let self = this;
      axios({
        method: "post",
        url: config.apiUrl + "/StoreUser/UpdateUserBrandStore",
        headers: authHeader(),
        params: {
          brandID: this.state.storeDetailsInput.brandID,
          storeID: this.state.storeDetailsInput.storeCode,
          UserID: this.state.newUserID,
        },
      })
        .then(function(res) {
          let status = res.data.message;
          // let data = res.data.responseData;
          if (status === "Success") {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.recordupdatedsuccessfully
                : "Record Updated Successfully."
            );
            self.setState({
              storeDetialReadOnly: true,
              buttonStoreToggle: false,
              createUserActiveKey: "PersonalDetails",
            });
          } else {
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.recordnotupdated
                : "Record Not Updated."
            );
          }
        })
        .catch((response) => {
          console.log(response);
        });
    }
  }
  handlePersonalDetailChange = async (event) => {
    let personalDetailsInput = this.state.personalDetailsInput;
    let personalDetailsErrors = this.state.personalDetailsErrors;
    if (event.target.name === "MobileNo") {
      if (!isNaN(event.target.value)) {
        personalDetailsInput[event.target.name] = event.target.value;
        personalDetailsErrors[event.target.name] = "";
      } else {
        if (event.target.value) {
          personalDetailsInput[event.target.name] = "";
          personalDetailsErrors[event.target.name] =
            "Please enter only number.";
        } else {
          personalDetailsInput[event.target.name] = "";
          personalDetailsErrors[event.target.name] = "";
        }
      }
    } else {
      personalDetailsInput[event.target.name] = event.target.value;
      personalDetailsErrors[event.target.name] = "";
    }
    await this.setState({
      personalDetailsInput,
      personalDetailsErrors,
    });
  };
  handlePersonalDetialsValidate = () => {
    let input = this.state.personalDetailsInput;
    let personalDetailsErrors = {};
    let isValid = true;
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!input["UserName"]) {
      isValid = false;
      personalDetailsErrors["UserName"] = "Please enter user name.";
    }
    if (!input["FirstName"]) {
      isValid = false;
      personalDetailsErrors["FirstName"] = "Please enter first name.";
    }
    if (!input["LastName"]) {
      isValid = false;
      personalDetailsErrors["LastName"] = "Please enter last name.";
    }
    if (!input["MobileNo"]) {
      isValid = false;
      personalDetailsErrors["MobileNo"] = "Please enter mobile no.";
    }
    if (!input["EmailID"]) {
      isValid = false;
      personalDetailsErrors["EmailID"] = "Please enter email address.";
    }
    if (input["EmailID"]) {
      if (!pattern.test(input["EmailID"])) {
        isValid = false;
        personalDetailsErrors["EmailID"] = "Please enter valid email address.";
      } else {
        isValid = true;
        personalDetailsErrors["EmailID"] = "";
      }
    }

    this.setState({
      personalDetailsErrors,
    });
    return isValid;
  };
  handlePersonalDetailsSubmit = () => {
    const TranslationContext = this.state.translateLanguage.default;
    if (this.state.createUserType === 0) {
      if (this.handlePersonalDetialsValidate()) {
        this.handleAddPersonalDetails(false);
      }
    } else {
      if (this.state.newUserID) {
        if (this.handlePersonalDetialsValidate()) {
          this.handleAddPersonalDetails(false);
        }
      } else {
        NotificationManager.error(
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.pleaseenterstoredetails
            : "Please Enter Store Details."
        );
      }
    }
  };
  ////get User Designation data for dropdown
  handleGetUserDesignationData() {
    let self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/StoreHierarchy/GetStoreDesignationList",
      headers: authHeader(),
    })
      .then((res) => {
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({ userDesignationData: data });
        } else {
          self.setState({ userDesignationData: [] });
        }
      })
      .catch((response) => {
        console.log(response);
      });
  }
  handleProfileDetailChange = async (event) => {
    let profileDetailsInput = this.state.profileDetailsInput;
    let profileDetailsErrors = this.state.profileDetailsErrors;
    if (event.target) {
      profileDetailsInput[event.target.name] = event.target.value;
      profileDetailsErrors[event.target.name] = "";

      if (event.target.name === "Department") {
        if (event.target.value) {
          this.handleGetFunctionData();
        }
      }

      if (event.target.name === "UserDesignation") {
        profileDetailsInput["ReporteeDesignation"] = "";
        profileDetailsInput["ReportTo"] = "";
        this.setState({
          reportToData: [],
          reportDesignation: [],
        });
        if (event.target.value) {
          this.handleGetReporteedesignationList();
        }
      }
      if (event.target.name === "ReporteeDesignation") {
        profileDetailsInput["ReportTo"] = "";
        this.setState({
          reportToData: [],
        });
        if (event.target.value) {
          this.handleGetReportToData();
        }
      }
    } else {
      profileDetailsInput["Function"] = event;
      profileDetailsErrors["Function"] = "";
    }
    if (event === null) {
      profileDetailsInput["Function"] = event;
      profileDetailsErrors["Function"] = "";
    }
    await this.setState({
      profileDetailsInput,
      profileDetailsErrors,
    });
  };
  handleProfileDetialsValidate = () => {
    let input = this.state.profileDetailsInput;
    let profileDetailsErrors = {};
    let isValid = true;

    if (!input["UserDesignation"]) {
      isValid = false;
      profileDetailsErrors["UserDesignation"] =
        "Please select user designation.";
    }
    if (!input["ReporteeDesignation"]) {
      isValid = false;
      profileDetailsErrors["ReporteeDesignation"] =
        "Please select reportee designation.";
    }
    if (!input["ReportTo"]) {
      isValid = false;
      profileDetailsErrors["ReportTo"] = "Please select report to.";
    }
    if (this.state.createUserType !== 0) {
      if (!input["Department"]) {
        isValid = false;
        profileDetailsErrors["Department"] = "Please select department.";
      }
      if (!input["Function"]) {
        isValid = false;
        profileDetailsErrors["Function"] = "Please select function.";
      }
    }
    this.setState({
      profileDetailsErrors,
    });
    return isValid;
  };

  handleGetReportToData() {
    let self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/StoreUser/BindStoreReportToUser",
      headers: authHeader(),
      params: {
        DesignationID: this.state.profileDetailsInput.ReporteeDesignation,
        IsStoreUser: true,
      },
    })
      .then(function(res) {
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({ reportToData: data });
        } else {
          self.setState({ reportToData: [] });
        }
      })
      .catch((response) => {
        console.log(response);
      });
  }
  handleProfileDetailsSubmit = (isUpdate) => {
    const TranslationContext = this.state.translateLanguage.default;
    if (this.state.newUserID > 0) {
      if (this.handleProfileDetialsValidate()) {
        var paramObj = {};
        paramObj.UserTypeID = this.state.createUserType;
        paramObj.userID = this.state.newUserID;
        if (this.state.createUserType !== 0) {
          paramObj.BrandID = this.state.storeDetailsInput.brandID || "";
          paramObj.storeID = this.state.storeDetailsInput.storeCode || "";
          paramObj.departmentId = this.state.profileDetailsInput.Department;
          paramObj.functionIDs = this.state.profileDetailsInput.Function.join(
            ","
          );
        }
        paramObj.designationID =
          this.state.profileDetailsInput.UserDesignation === "-1"
            ? 0
            : this.state.profileDetailsInput.UserDesignation;
        paramObj.tdesignationID =
          this.state.profileDetailsInput.ReporteeDesignation === "-1"
            ? 0
            : this.state.profileDetailsInput.ReporteeDesignation;
        paramObj.reporteeID =
          this.state.profileDetailsInput.ReportTo === "-1"
            ? 0
            : this.state.profileDetailsInput.ReportTo;

        let self = this;
        axios({
          method: "post",
          url: config.apiUrl + "/StoreUser/AddStoreUserProfileDetail",
          headers: authHeader(),
          params: paramObj,
        })
          .then(function(res) {
            let status = res.data.message;
            // let data = res.data.responseData;
            if (status === "Success") {
              if (isUpdate) {
                NotificationManager.success(
                  TranslationContext !== undefined
                    ? TranslationContext.alertmessage.recordupdatedsuccessfully
                    : "Record updated successfully"
                );
              } else {
                NotificationManager.success(
                  TranslationContext !== undefined
                    ? TranslationContext.alertmessage.recordsavedsuccessfully
                    : "Record Saved Successfully."
                );
              }

              self.setState({
                profileDetailsReadOnly: true,
                createUserActiveKey: "MappedCategory",
              });
            } else {
              NotificationManager.error(
                TranslationContext !== undefined
                  ? TranslationContext.alertmessage.recordnotsaved
                  : "Record Not Saved."
              );
            }
          })
          .catch((response) => {
            console.log(response);
          });
      }
    } else {
      if (this.state.createUserType === 0) {
        NotificationManager.error(
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.pleaseenterstoredetails
            : "Please Enter Personal Details."
        );
      } else {
        NotificationManager.error(
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.pleaseenterstoredetails
            : "Please Enter Store Details."
        );
      }
    }
  };
  handleGetFunctionData() {
    let self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/StoreDepartment/getFunctionNameByDepartmentId",
      headers: authHeader(),
      params: {
        DepartmentId: this.state.profileDetailsInput.Department,
      },
    })
      .then(function(res) {
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({ functionData: data });
        } else {
          self.setState({ functionData: [] });
        }
      })
      .catch((response) => {
        console.log(response);
      });
  }
  /// handle Get Department Data by Brand and store id  for dropdown list
  handleGetDepartmentData() {
    let self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/StoreUser/BindDepartmentByBrandAndStore",
      headers: authHeader(),
      params: {
        BrandID: this.state.storeDetailsInput.brandID,
        storeID: this.state.storeDetailsInput.storeCode,
      },
    })
      .then((res) => {
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({ departmentData: data });
        } else {
          self.setState({ departmentData: [] });
        }
      })
      .catch((response) => {
        console.log(response);
      });
  }
  handleUpdatePersonalDetialsSubmit = () => {
    if (this.state.newUserID > 0) {
      if (this.handlePersonalDetialsValidate()) {
        this.handleAddPersonalDetails(true);
      }
    }
  };

  handleUpdateProfileDetialsSubmit = () => {
    if (this.state.newUserID > 0) {
      if (this.handleProfileDetialsValidate()) {
        this.handleProfileDetailsSubmit(true);
      }
    }
  };

  handleMapCategoryValidate = () => {
    let input = this.state.mapCateogryInput;
    let mapCateogryErrors = {};
    let isValid = true;
    debugger;
    if (!input["Brand"]) {
      isValid = false;
      mapCateogryErrors["Brand"] = "Please select brand.";
    }
    if (!input["Categories"]) {
      isValid = false;
      mapCateogryErrors["Categories"] = "Please select categories.";
    }
    if (!input["SubCategories"]) {
      isValid = false;
      mapCateogryErrors["SubCategories"] = "Please select sub categories.";
    }
    if (!input["IssueType"]) {
      isValid = false;
      mapCateogryErrors["IssueType"] = "Please select issue type.";
    }
    if (input["AssignEscalation"]) {
      if (!input["Agent"]) {
        isValid = false;
        mapCateogryErrors["Agent"] = "Please select assign to agent.";
      }
      if (!input["Agent"] && !input["Supervisor"]) {
        isValid = false;
        mapCateogryErrors["SupervisorOrAgent"] =
          "Please select supervisor or agent.";
      }
    }
    if (!input["CRMRole"]) {
      isValid = false;
      mapCateogryErrors["CRMRole"] = "Please select crm role.";
    }

    this.setState({
      mapCateogryErrors,
    });
    return isValid;
  };
  handleMapCategoryChange = async (name, event) => {
    let mapCateogryInput = this.state.mapCateogryInput;
    let mapCateogryErrors = this.state.mapCateogryErrors;
    if (event.target) {
      if (name === "CopyEscalation") {
        mapCateogryInput[event.target.name] = event.target.checked;
        mapCateogryErrors[event.target.name] = "";
      } else if (name === "AssignEscalation") {
        mapCateogryInput[event.target.name] = event.target.checked;
        mapCateogryErrors[event.target.name] = "";
        if (!event.target.checked) {
          mapCateogryInput["Supervisor"] = false;
          mapCateogryInput["Agent"] = false;
          mapCateogryInput["AgentID"] = false;
        }
      } else if (name === "Supervisor") {
        mapCateogryInput[name] = event.target.checked;
        mapCateogryInput["Agent"] = false;
        mapCateogryErrors[name] = "";
      } else if (name === "Agent") {
        mapCateogryInput[name] = event.target.checked;
        mapCateogryInput["Supervisor"] = false;
        mapCateogryErrors[name] = "";
      } else {
        mapCateogryInput[event.target.name] = event.target.value;
        mapCateogryErrors[event.target.name] = "";
      }
    } else {
      mapCateogryInput[name] = event;
      mapCateogryErrors[name] = "";
      if (name === "Brand") {
        mapCateogryInput["Categories"] = [];
        mapCateogryInput["SubCategories"] = [];
        mapCateogryInput["IssueType"] = [];

        this.setState({
          mapCateogryInput,
          CategoryData: [],
          SubCategoryData: [],
          IssueTypeData: [],
        });
        this.handleGetCategoryList();
      }
      if (name === "Categories") {
        mapCateogryInput["SubCategories"] = [];
        mapCateogryInput["IssueType"] = [];

        this.setState({
          mapCateogryInput,
          SubCategoryData: [],
          IssueTypeData: [],
        });
        this.handleGetSubCategoryList();
      }
      if (name === "SubCategories") {
        mapCateogryInput["IssueType"] = [];

        this.setState({
          mapCateogryInput,
          IssueTypeData: [],
        });
        this.handleGetIssueTypeList();
      }
    }
    await this.setState({
      mapCateogryInput,
      mapCateogryErrors,
    });
  };
  handleAddMapCategory() {
    const TranslationContext = this.state.translateLanguage.default;
    var isValid = false;
    if (this.state.createUserType === 0 || this.state.createUserType === 2) {
      if (this.handleMapCategoryValidate()) {
        isValid = true;
      } else {
        isValid = false;
      }
    } else {
    }

    if (isValid) {
      let self = this;

      var brandIds = this.state.mapCateogryInput.Brand.map(
        (x) => x.brandID
      ).join(",");
      var categoryIds = this.state.mapCateogryInput.Categories.map(
        (x) => x.categoryID
      ).join(",");
      var subCategoryIds = this.state.mapCateogryInput.SubCategories.map(
        (x) => x.subCategoryID
      ).join(",");
      var issuetypeIds = this.state.mapCateogryInput.IssueType.map(
        (x) => x.issueTypeID
      ).join(",");
      var activeStatus = 0;
      var status = this.state.mapCateogryInput.Status;
      if (status === "true") {
        activeStatus = 1;
      } else {
        activeStatus = 0;
      }
      var inputData = {};
      inputData.UserID = this.state.newUserID;
      inputData.BrandIDs = brandIds;
      inputData.CategoryIds = categoryIds;
      inputData.SubCategoryIds = subCategoryIds;
      inputData.IssuetypeIds = issuetypeIds;
      inputData.CRMRoleID = this.state.mapCateogryInput.CRMRole;

      if (this.state.createUserType !== 1) {
        inputData.IsCopyEscalation = this.state.mapCateogryInput.CopyEscalation
          ? 1
          : 0;
        inputData.IsAssignEscalation = this.state.mapCateogryInput
          .AssignEscalation
          ? 1
          : 0;
        inputData.IsAgent = this.state.mapCateogryInput.Agent ? 0 : 1;

        inputData.EscalateAssignToId = 0;
      } else {
        inputData.isClaimApprover = 0;
      }
      inputData.IsActive = activeStatus;
      axios({
        method: "post",
        url: config.apiUrl + "/StoreUser/AddStoreUserMappingCategory",
        headers: authHeader(),
        data: inputData,
      })
        .then(function(res) {
          let Msg = res.data.message;
          if (Msg === "Success") {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.usercreatedsuccessfully
                : "User Created successfully"
            );
            // self.handleSendMail(self.state.newUserID);
          } else {
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.usernotcreated
                : "User Not Created"
            );
          }
          self.handleGetUserList();
        })
        .catch((data) => {
          console.log(data);
        });
    }
  }

  render() {
    const TranslationContext = this.state.translateLanguage.default;
    const { userData } = this.state;
    return (
      <React.Fragment>
        <div className="position-relative d-inline-block">
          <Modal
            onClose={this.StatusCloseModel}
            open={this.state.StatusModel}
            modalId="Status-popup"
            overlayId="logout-ovrly"
          >
            <div className="status-drop-down" style={{ width: "280px" }}>
              <div className="sort-sctn">
                <label style={{ color: "#0066cc", fontWeight: "bold" }}>
                  {this.state.sortHeader}
                </label>
                <div className="d-flex">
                  <a
                    href="#!"
                    onClick={this.sortStatusAtoZ.bind(this)}
                    className="sorting-icon"
                  >
                    <img src={Sorting} alt="sorting-icon" />
                  </a>
                  <p>
                    {TranslationContext !== undefined
                      ? TranslationContext.p.sortatoz
                      : "SORT BY A TO Z"}
                  </p>
                </div>
                <div className="d-flex">
                  <a
                    href="#!"
                    onClick={this.sortStatusZtoA.bind(this)}
                    className="sorting-icon"
                  >
                    <img src={Sorting} alt="sorting-icon" />
                  </a>
                  <p>
                    {TranslationContext !== undefined
                      ? TranslationContext.p.sortztoa
                      : "SORT BY Z TO A"}
                  </p>
                </div>
              </div>
              <a
                href=""
                style={{ margin: "0 25px", textDecoration: "underline" }}
                onClick={this.setSortCheckStatus.bind(this, "all")}
              >
                {TranslationContext !== undefined
                  ? TranslationContext.a.clearsearch
                  : "clear search"}
              </a>
              <div className="filter-type">
                <p>
                  {TranslationContext !== undefined
                    ? TranslationContext.p.filterbytype
                    : "FILTER BY TYPE"}
                </p>
                <input
                  type="text"
                  style={{ display: "block" }}
                  value={this.state.filterTxtValue}
                  onChange={this.filteTextChange.bind(this)}
                />
                <div className="FTypeScroll">
                  <div className="filter-checkbox">
                    <input
                      type="checkbox"
                      name="filter-type"
                      id={"fil-open"}
                      value="all"
                      checked={
                        this.state.sdesignationFilterCheckbox.includes("all") ||
                        this.state.suserNameFilterCheckbox.includes("all") ||
                        this.state.smobileNumberFilterCheckbox.includes(
                          "all"
                        ) ||
                        this.state.semailIDFilterCheckbox.includes("all")
                      }
                      onChange={this.setSortCheckStatus.bind(this, "all")}
                    />
                    <label htmlFor={"fil-open"}>
                      <span className="table-btn table-blue-btn">ALL</span>
                    </label>
                  </div>
                  {this.state.sortColumn === "designation"
                    ? this.state.sortFilterDesignation !== null &&
                      this.state.sortFilterDesignation.map((item, i) => (
                        <div className="filter-checkbox">
                          <input
                            type="checkbox"
                            name="filter-type"
                            id={"fil-open" + item.designation}
                            value={item.designation}
                            checked={this.state.sdesignationFilterCheckbox.includes(
                              item.designation
                            )}
                            onChange={this.setSortCheckStatus.bind(
                              this,
                              "designation",
                              "value"
                            )}
                          />
                          <label htmlFor={"fil-open" + item.designation}>
                            <span className="table-btn table-blue-btn">
                              {item.designation}
                            </span>
                          </label>
                        </div>
                      ))
                    : null}

                  {this.state.sortColumn === "userName"
                    ? this.state.sortFilterUsername !== null &&
                      this.state.sortFilterUsername.map((item, i) => (
                        <div className="filter-checkbox">
                          <input
                            type="checkbox"
                            name="filter-type"
                            id={"fil-open" + item.userName}
                            value={item.userName}
                            checked={this.state.suserNameFilterCheckbox.includes(
                              item.userName
                            )}
                            onChange={this.setSortCheckStatus.bind(
                              this,
                              "userName",
                              "value"
                            )}
                          />
                          <label htmlFor={"fil-open" + item.userName}>
                            <span className="table-btn table-blue-btn">
                              {item.userName}
                            </span>
                          </label>
                        </div>
                      ))
                    : null}

                  {this.state.sortColumn === "mobileNumber"
                    ? this.state.sortFilterMobile !== null &&
                      this.state.sortFilterMobile.map((item, i) => (
                        <div className="filter-checkbox">
                          <input
                            type="checkbox"
                            name="filter-type"
                            id={"fil-open" + item.mobileNumber}
                            value={item.mobileNumber}
                            checked={this.state.smobileNumberFilterCheckbox.includes(
                              item.mobileNumber
                            )}
                            onChange={this.setSortCheckStatus.bind(
                              this,
                              "mobileNumber",
                              "value"
                            )}
                          />
                          <label htmlFor={"fil-open" + item.mobileNumber}>
                            <span className="table-btn table-blue-btn">
                              {item.mobileNumber}
                            </span>
                          </label>
                        </div>
                      ))
                    : null}

                  {this.state.sortColumn === "emailID"
                    ? this.state.sortFilterEmail !== null &&
                      this.state.sortFilterEmail.map((item, i) => (
                        <div className="filter-checkbox">
                          <input
                            type="checkbox"
                            name="filter-type"
                            id={"fil-open" + item.emailID}
                            value={item.emailID}
                            checked={this.state.semailIDFilterCheckbox.includes(
                              item.emailID
                            )}
                            onChange={this.setSortCheckStatus.bind(
                              this,
                              "emailID",
                              "value"
                            )}
                          />
                          <label htmlFor={"fil-open" + item.emailID}>
                            <span className="table-btn table-blue-btn">
                              {item.emailID}
                            </span>
                          </label>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
          </Modal>
        </div>
        {/* ----------------------------------edit Modal------------------------------------ */}
        {/* ----------------------------------end------------------------------------ */}
        <div className="container-fluid">
          <div className="store-settings-cntr settingtable">
            <div className="row">
              <div className="col-md-8">
                <div className="table-cntr table-height TicketUserReact settings-align">
                  <ReactTable
                    data={userData}
                    minRows={2}
                    columns={[
                      {
                        Header: (
                          <span
                            className={this.state.userColor}
                            onClick={this.StatusOpenModel.bind(
                              this,
                              "userName",
                              TranslationContext !== undefined
                                ? TranslationContext.label.username
                                : "User Name"
                            )}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.label.username
                              : "User Name"}
                            <FontAwesomeIcon icon={faCaretDown} />
                          </span>
                        ),
                        sortable: false,
                        accessor: "userName",
                        Cell: (row) => <span>{row.original.userName}</span>,
                      },
                      {
                        Header: (
                          <span
                            className={this.state.mobileColor}
                            onClick={this.StatusOpenModel.bind(
                              this,
                              "mobileNumber",
                              TranslationContext !== undefined
                                ? TranslationContext.label.mobileno
                                : "Mobile Number"
                            )}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.label.mobileno
                              : "Mob. No."}
                            <FontAwesomeIcon icon={faCaretDown} />
                          </span>
                        ),
                        sortable: false,
                        accessor: "mobileNumber",
                        Cell: (row) => <span>{row.original.mobileNumber}</span>,
                      },
                      {
                        Header: (
                          <span
                            className={this.state.emailColor}
                            onClick={this.StatusOpenModel.bind(
                              this,
                              "emailID",
                              TranslationContext !== undefined
                                ? TranslationContext.label.emailid
                                : "Email ID"
                            )}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.label.emailid
                              : "Email ID"}
                            <FontAwesomeIcon icon={faCaretDown} />
                          </span>
                        ),
                        sortable: false,
                        accessor: "email ID",
                        Cell: (row) => <span>{row.original.emailID}</span>,
                      },
                      {
                        Header: (
                          <span
                            className={this.state.designationColor}
                            onClick={this.StatusOpenModel.bind(
                              this,
                              "designation",
                              TranslationContext !== undefined
                                ? TranslationContext.label.designation
                                : "Designation"
                            )}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.label.designation
                              : "Designation"}
                            <FontAwesomeIcon icon={faCaretDown} />
                          </span>
                        ),
                        sortable: false,
                        accessor: "designation",
                        Cell: (row) => {
                          var ids = row.original["userId"];
                          return (
                            <div>
                              <span className="one-liner">
                                {row.original.designation}
                                <Popover
                                  content={
                                    <div className="user-desig-pop">
                                      <div className=" row d-flex">
                                        <div className="col-md-6">
                                          <p className="title">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label
                                                  .reporteedesignation
                                              : "Reportee Designation"}
                                            :
                                          </p>
                                          <b>
                                            {row.original.reporteeDesignation}
                                          </b>
                                        </div>
                                        <div className="col-md-6">
                                          <p className="sub-title mx-2">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label
                                                  .issuetype
                                              : "Issue Type"}
                                            :
                                          </p>
                                          <b>{row.original.issueTypeNames}</b>
                                        </div>
                                      </div>

                                      <div className="row d-flex">
                                        <div className="col-md-6">
                                          <p className="title">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label
                                                  .reportto
                                              : "Report To"}
                                            :
                                          </p>
                                          <b>{row.original.reportTo}</b>
                                        </div>
                                        <div className="col-md-6">
                                          <p className="sub-title mx-2">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label.crmrole
                                              : "CRM Role"}
                                            :
                                          </p>
                                          <b>{row.original.crmRoleName}</b>
                                        </div>
                                      </div>

                                      <div className="row d-flex">
                                        <div className="col-md-6">
                                          <p className="title">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label.brand
                                              : "Brand"}
                                            :
                                          </p>
                                          <b>{row.original.brandNames}</b>
                                        </div>
                                        <div className="col-md-6">
                                          {row.original.isCopyEscalation ===
                                          "Yes" ? (
                                            <>
                                              <p className="sub-title mx-2">
                                                {TranslationContext !==
                                                undefined
                                                  ? TranslationContext.label
                                                      .copyescalation
                                                  : "Copy Escalation"}
                                                :
                                              </p>
                                              <b>
                                                {TranslationContext !==
                                                undefined
                                                  ? TranslationContext.label.yes
                                                  : "Yes"}
                                              </b>
                                            </>
                                          ) : (
                                            <>
                                              <p className="sub-title mx-2">
                                                {TranslationContext !==
                                                undefined
                                                  ? TranslationContext.label
                                                      .copyescalation
                                                  : "Copy Escalation"}
                                                :
                                              </p>
                                              <b>
                                                {TranslationContext !==
                                                undefined
                                                  ? TranslationContext.label.no
                                                  : "No"}
                                              </b>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <div className="row d-flex">
                                        <div className="col-md-6">
                                          <p className="title">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label
                                                  .categories
                                              : "Categories"}
                                            :
                                          </p>
                                          <b>{row.original.categoryNames}</b>
                                        </div>
                                        <div className="col-md-6">
                                          <p className="sub-title mx-2">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label
                                                  .assignescalation
                                              : "Assign Escalation"}
                                            :
                                          </p>
                                          <b>{row.original.assignEscalation}</b>
                                        </div>
                                      </div>
                                      <div className="row d-flex">
                                        <div className="col-md-6">
                                          <p className="title">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label
                                                  .subcategories
                                              : "Sub Categories"}
                                            :
                                          </p>
                                          <b>{row.original.subCategoryNames}</b>
                                        </div>
                                        <div className="col-md-6">
                                          <p className="sub-title mx-2">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.p.agentname
                                              : "Agent Name"}
                                            :
                                          </p>
                                          <b>{row.original.assignName}</b>
                                        </div>
                                      </div>
                                      <div className="row d-flex">
                                        <div className="col-md-6">
                                          <p className="title">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label
                                                  .createdby
                                              : "Created By"}
                                            :
                                          </p>
                                          <b>{row.original.createdBy}</b>
                                        </div>
                                        <div className="col-md-6">
                                          <p className="sub-title mx-2">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.p.updatedby
                                              : "Updated By"}
                                            :
                                          </p>
                                          <b>{row.original.updatedBy}</b>
                                        </div>
                                      </div>
                                      <div className="row d-flex">
                                        <div className="col-md-6">
                                          <p className="title">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.p.createddate
                                              : "Created Date"}
                                            :
                                          </p>
                                          <b>{row.original.createdDate}</b>
                                        </div>
                                        <div className="col-md-6">
                                          <p className="sub-title mx-2">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.p.updateddate
                                              : "Updated Date"}
                                            :
                                          </p>
                                          <b>{row.original.updatedDate}</b>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  placement="bottom"
                                >
                                  <img
                                    className="info-icon-cp"
                                    src={BlackInfoIcon}
                                    alt="info-icon"
                                    id={ids}
                                  />
                                </Popover>
                              </span>
                            </div>
                          );
                        },
                      },
                      {
                        Header: (
                          <span>
                            {TranslationContext !== undefined
                              ? TranslationContext.label.actions
                              : "Actions"}
                          </span>
                        ),
                        accessor: "userId",
                        sortable: false,
                        Cell: (row) => {
                          var ids = row.original["userId"];
                          return (
                            <>
                              <span className="settings-align-actions">
                                <Popover
                                  content={
                                    <div
                                      className="samdel d-flex general-popover popover-body"
                                      id={"samdel" + ids}
                                    >
                                      <div className="del-big-icon">
                                        <img src={DelBigIcon} alt="del-icon" />
                                      </div>
                                      <div>
                                        <p className="font-weight-bold blak-clr">
                                          {TranslationContext !== undefined
                                            ? TranslationContext.p.deletefile
                                            : "Delete file?"}
                                        </p>
                                        <p className="mt-1 fs-12">
                                          {TranslationContext !== undefined
                                            ? TranslationContext.p
                                                .areyousureyouwanttodeletethisfile
                                            : "Are you sure you want to delete this file"}
                                          ?
                                        </p>
                                        <div className="del-can">
                                          <a
                                            className="canblue"
                                            onClick={() =>
                                              this.hide(this, "samdel" + ids)
                                            }
                                          >
                                            {TranslationContext !== undefined
                                              ? TranslationContext.button.cancel
                                              : "CANCEL"}
                                          </a>
                                          <button
                                            className="butn"
                                            onClick={this.handleDeleteUser.bind(
                                              this,
                                              row.original.userId
                                            )}
                                          >
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label.delete
                                              : "Delete"}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  placement="bottom"
                                  trigger="click"
                                >
                                  <img
                                    src={RedDeleteIcon}
                                    alt="del-icon"
                                    className="del-btn"
                                    id={ids}
                                  />
                                </Popover>

                                <button
                                  className="react-tabel-button editre"
                                  onClick={this.handleGetUserListByID.bind(
                                    this,
                                    row.original.userId
                                  )}
                                >
                                  {TranslationContext !== undefined
                                    ? TranslationContext.button.edit
                                    : "EDIT"}
                                </button>
                              </span>
                            </>
                          );
                        },
                      },
                    ]}
                    resizable={false}
                    defaultPageSize={10}
                    showPagination={true}
                  />
                </div>
              </div>
              <div className="col-md-4 cus-drp">
                <div className="right-sect-div right-sect-collapse">
                  <h3>
                    {TranslationContext !== undefined
                      ? TranslationContext.h3.createusers
                      : "Create Users"}
                  </h3>
                  <div className="createUserType">
                    <Radio.Group
                      defaultValue={this.state.createUserType}
                      buttonStyle="solid"
                      onChange={this.handleCreateUserType.bind(this)}
                    >
                      <Radio.Button value={0}>Ticket</Radio.Button>
                      <Radio.Button value={1}>Store</Radio.Button>
                      <Radio.Button value={2}>Both</Radio.Button>
                    </Radio.Group>
                  </div>
                  <div className="createUserDetails">
                    <Collapse
                      accordion
                      className="site-collapse-custom-collapse"
                      expandIconPosition="right"
                      expandIcon={({ isActive }) =>
                        isActive ? (
                          <img src={CollapseDownIconUP} alt="UP" />
                        ) : (
                          <img src={CollapseDownIconDown} alt="down" />
                        )
                      }
                      activeKey={this.state.createUserActiveKey}
                      onChange={this.handleCreateUserTypeCollapceChange.bind(
                        this
                      )}
                    >
                      {this.state.createUserType !== 0 ? (
                        <Panel
                          header={
                            TranslationContext !== undefined
                              ? TranslationContext.a.storedetails
                              : " Store Details"
                          }
                          key="StoreDetails"
                        >
                          <div>
                            <label>
                              {TranslationContext !== undefined
                                ? TranslationContext.label.barnd
                                : "Brand"}
                            </label>
                            <select
                              className={"store-create-select"}
                              name="brandID"
                              value={this.state.storeDetailsInput.brandName}
                              onChange={this.handleStoreDetailsInputChange.bind(
                                this
                              )}
                              disabled={this.state.storeDetialReadOnly}
                            >
                              <option value="">
                                {TranslationContext !== undefined
                                  ? TranslationContext.option.select
                                  : "Select"}
                              </option>
                              {this.state.brandData !== null &&
                                this.state.brandData.map((item, i) => (
                                  <option
                                    key={i}
                                    value={item.brandID}
                                    className="select-category-placeholder"
                                  >
                                    {item.brandName}
                                  </option>
                                ))}
                            </select>
                            {this.state.storeDetailsErrors ? (
                              <div className="text-danger">
                                {this.state.storeDetailsErrors["brandID"] || ""}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <label>
                              {TranslationContext !== undefined
                                ? TranslationContext.label.storecode
                                : "Store Code"}
                            </label>
                            <select
                              className={"store-create-select"}
                              name="storeCode"
                              value={this.state.storeDetailsInput.storeCode}
                              onChange={this.handleStoreDetailsInputChange.bind(
                                this
                              )}
                              disabled={this.state.storeDetialReadOnly}
                            >
                              <option>
                                {TranslationContext !== undefined
                                  ? TranslationContext.option.select
                                  : "Select"}
                              </option>
                              {this.state.storeCodeData !== null &&
                                this.state.storeCodeData.map((item, s) => (
                                  <option
                                    key={s}
                                    value={item.storeID}
                                    className="select-category-placeholder"
                                  >
                                    {item.storeCode}
                                  </option>
                                ))}
                            </select>
                            {this.state.storeDetailsErrors ? (
                              <div className="text-danger">
                                {this.state.storeDetailsErrors["storeCode"] ||
                                  ""}
                              </div>
                            ) : null}
                          </div>

                          {this.state.storeDetialReadOnly === true ? (
                            <div>
                              <button
                                className="butn"
                                onClick={(e) => {
                                  this.setState({
                                    storeDetialReadOnly: false,
                                    buttonStoreToggle: true,
                                  });
                                }}
                              >
                                {TranslationContext !== undefined
                                  ? TranslationContext.button.edit
                                  : "Edit"}
                              </button>
                            </div>
                          ) : this.state.buttonStoreToggle === true ? (
                            <div>
                              <button
                                className="butn"
                                onClick={this.handleUpdateStoreDetails.bind(
                                  this
                                )}
                              >
                                {TranslationContext !== undefined
                                  ? TranslationContext.button.updateandnext
                                  : "Update & Next"}
                              </button>
                            </div>
                          ) : (
                            <div>
                              <button
                                className="butn"
                                onClick={this.handleStoreDetialsSubmit.bind(
                                  this
                                )}
                              >
                                {TranslationContext !== undefined
                                  ? TranslationContext.button.saveandnext
                                  : "SAVE & Next"}
                              </button>
                            </div>
                          )}
                        </Panel>
                      ) : null}
                      <Panel
                        header={
                          TranslationContext !== undefined
                            ? TranslationContext.label.personaldetails
                            : "Personal Details"
                        }
                        key="PersonalDetails"
                      >
                        <div>
                          <label>
                            {TranslationContext !== undefined
                              ? TranslationContext.label.username
                              : "User Name"}
                          </label>
                          <input
                            type="text"
                            maxLength={25}
                            placeholder={
                              TranslationContext !== undefined
                                ? TranslationContext.label.username
                                : "User Name"
                            }
                            name="UserName"
                            value={this.state.personalDetailsInput.UserName}
                            autoComplete="off"
                            onChange={this.handlePersonalDetailChange.bind(
                              this
                            )}
                            disabled={this.state.personalDetailReadOnly}
                          />
                          {this.state.personalDetailsErrors ? (
                            <div className="text-danger">
                              {this.state.personalDetailsErrors["UserName"] ||
                                ""}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <label>
                            {TranslationContext !== undefined
                              ? TranslationContext.label.firstname
                              : "First Name"}
                          </label>
                          <input
                            type="text"
                            maxLength={25}
                            placeholder={
                              TranslationContext !== undefined
                                ? TranslationContext.label.firstname
                                : "First Name"
                            }
                            disabled={this.state.personalDetailReadOnly}
                            name="FirstName"
                            value={this.state.personalDetailsInput.FirstName}
                            autoComplete="off"
                            onChange={this.handlePersonalDetailChange.bind(
                              this
                            )}
                          />
                          {this.state.personalDetailsErrors ? (
                            <div className="text-danger">
                              {this.state.personalDetailsErrors["FirstName"] ||
                                ""}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <label>
                            {TranslationContext !== undefined
                              ? TranslationContext.label.lastname
                              : "Last Name"}
                          </label>
                          <input
                            type="text"
                            maxLength={25}
                            placeholder={
                              TranslationContext !== undefined
                                ? TranslationContext.label.lastname
                                : "Last Name"
                            }
                            disabled={this.state.personalDetailReadOnly}
                            name="LastName"
                            value={this.state.personalDetailsInput.LastName}
                            autoComplete="off"
                            onChange={this.handlePersonalDetailChange.bind(
                              this
                            )}
                          />
                          {this.state.personalDetailsErrors ? (
                            <div className="text-danger">
                              {this.state.personalDetailsErrors["LastName"] ||
                                ""}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <label>
                            {TranslationContext !== undefined
                              ? TranslationContext.label.mobilenumber
                              : "Mobile Number"}
                          </label>
                          <input
                            type="text"
                            maxLength={10}
                            placeholder={
                              TranslationContext !== undefined
                                ? TranslationContext.label.mobilenumber
                                : "Mobile Number"
                            }
                            disabled={this.state.personalDetailReadOnly}
                            name="MobileNo"
                            value={this.state.personalDetailsInput.MobileNo}
                            autoComplete="off"
                            onChange={this.handlePersonalDetailChange.bind(
                              this
                            )}
                          />
                          {this.state.personalDetailsErrors ? (
                            <div className="text-danger">
                              {this.state.personalDetailsErrors["MobileNo"] ||
                                ""}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <label>
                            {TranslationContext !== undefined
                              ? TranslationContext.label.emailid
                              : "Email ID"}
                          </label>
                          <input
                            type="text"
                            maxLength={100}
                            placeholder={
                              TranslationContext !== undefined
                                ? TranslationContext.label.emailid
                                : "Email ID"
                            }
                            disabled={this.state.personalDetailReadOnly}
                            name="EmailID"
                            value={this.state.personalDetailsInput.EmailID}
                            autoComplete="off"
                            onChange={this.handlePersonalDetailChange.bind(
                              this
                            )}
                          />
                          {this.state.personalDetailsErrors ? (
                            <div className="text-danger">
                              {this.state.personalDetailsErrors["EmailID"] ||
                                ""}
                            </div>
                          ) : null}
                        </div>

                        {this.state.personalDetailReadOnly === true ? (
                          <div>
                            <button
                              className="butn"
                              onClick={() => {
                                this.setState({
                                  personalDetailReadOnly: false,
                                  buttonToggle: true,
                                });
                              }}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.label.edit
                                : "Edit"}
                            </button>
                          </div>
                        ) : this.state.buttonToggle === true ? (
                          <div>
                            <button
                              className="butn"
                              onClick={this.handleUpdatePersonalDetialsSubmit.bind(
                                this
                              )}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.button.updateandnext
                                : "Update & Next"}
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button
                              className="butn"
                              onClick={this.handlePersonalDetailsSubmit.bind(
                                this
                              )}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.button.saveandnext
                                : "SAVE & NEXT"}
                            </button>
                          </div>
                        )}
                      </Panel>
                      <Panel
                        header={
                          TranslationContext !== undefined
                            ? TranslationContext.label.profiledetails
                            : "Profile Details"
                        }
                        key="ProfileDetails"
                      >
                        {this.state.createUserType !== 0 ? (
                          <React.Fragment>
                            <div>
                              <label>
                                {TranslationContext !== undefined
                                  ? TranslationContext.label.department
                                  : "Department"}
                              </label>
                              <select
                                className={
                                  this.state.profileReadOnly
                                    ? "disabled-input store-create-select"
                                    : "store-create-select"
                                }
                                disabled={this.state.profileReadOnly}
                                name="Department"
                                value={
                                  this.state.profileDetailsInput.Department
                                }
                                onChange={this.handleProfileDetailChange}
                              >
                                <option>
                                  {TranslationContext !== undefined
                                    ? TranslationContext.option.select
                                    : "Select"}
                                </option>
                                {this.state.departmentData !== null &&
                                  this.state.departmentData.map((item, d) => (
                                    <option
                                      key={d}
                                      value={item.departmentID}
                                      className="select-category-placeholder"
                                    >
                                      {item.departmentName}
                                    </option>
                                  ))}
                              </select>
                              {this.state.profileDetailsErrors ? (
                                <div className="text-danger">
                                  {this.state.profileDetailsErrors[
                                    "Department"
                                  ] || ""}
                                </div>
                              ) : null}
                            </div>
                            <div>
                              <label>
                                {TranslationContext !== undefined
                                  ? TranslationContext.label.function
                                  : "Function"}
                              </label>
                              <Select
                                getOptionLabel={(option) =>
                                  option.funcationName
                                }
                                getOptionValue={(option) => option.functionID}
                                options={this.state.functionData}
                                placeholder={
                                  TranslationContext !== undefined
                                    ? TranslationContext.placeholder.select
                                    : "Select"
                                }
                                styles={{ marginBottom: "10px" }}
                                closeMenuOnSelect={false}
                                name="Function"
                                onChange={this.handleProfileDetailChange}
                                value={this.state.profileDetailsInput.Function}
                                isMulti
                                isDisabled={this.state.profileReadOnly}
                              />
                              {this.state.profileDetailsErrors ? (
                                <div className="text-danger">
                                  {this.state.profileDetailsErrors[
                                    "Function"
                                  ] || ""}
                                </div>
                              ) : null}
                            </div>
                          </React.Fragment>
                        ) : null}
                        <div>
                          <label>
                            {TranslationContext !== undefined
                              ? TranslationContext.label.userdesignation
                              : "User Designation"}
                          </label>
                          <select
                            disabled={this.state.profileDetailsReadOnly}
                            name="UserDesignation"
                            value={
                              this.state.profileDetailsInput.UserDesignation
                            }
                            onChange={this.handleProfileDetailChange}
                          >
                            <option value="">
                              {TranslationContext !== undefined
                                ? TranslationContext.option.select
                                : "Select"}
                            </option>
                            {this.state.userDesignationData !== null &&
                              this.state.userDesignationData.map((item, d) => (
                                <option
                                  key={d}
                                  value={item.designationID}
                                  className="select-category-placeholder"
                                >
                                  {item.designationName}
                                </option>
                              ))}
                          </select>
                          {this.state.profileDetailsErrors ? (
                            <div className="text-danger">
                              {this.state.profileDetailsErrors[
                                "UserDesignation"
                              ] || ""}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <label>
                            {TranslationContext !== undefined
                              ? TranslationContext.label.reporteedesignation
                              : "Reportee Designation"}
                          </label>
                          <select
                            disabled={this.state.profileDetailsReadOnly}
                            name="ReporteeDesignation"
                            value={
                              this.state.profileDetailsInput.ReporteeDesignation
                            }
                            onChange={this.handleProfileDetailChange}
                          >
                            <option value="">
                              {TranslationContext !== undefined
                                ? TranslationContext.option.select
                                : "Select"}
                            </option>
                            {this.state.reportDesignation !== null &&
                              this.state.reportDesignation.map((item, d) => (
                                <option
                                  key={d}
                                  value={item.designationID}
                                  className="select-category-placeholder"
                                >
                                  {item.designationName}
                                </option>
                              ))}

                            {this.state.reportDesignation.length === 0 && (
                              <option value="-1">
                                {TranslationContext !== undefined
                                  ? TranslationContext.option.root
                                  : "Root"}
                              </option>
                            )}
                          </select>
                          {this.state.profileDetailsErrors ? (
                            <div className="text-danger">
                              {this.state.profileDetailsErrors[
                                "ReporteeDesignation"
                              ] || ""}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <label>
                            {TranslationContext !== undefined
                              ? TranslationContext.label.reportto
                              : "Report To"}
                          </label>
                          <select
                            disabled={this.state.profileDetailsReadOnly}
                            name="ReportTo"
                            value={this.state.profileDetailsInput.ReportTo}
                            onChange={this.handleProfileDetailChange}
                          >
                            <option value="">
                              {TranslationContext !== undefined
                                ? TranslationContext.option.select
                                : "Select"}
                            </option>
                            {this.state.reportToData !== null &&
                              this.state.reportToData.map((item, d) => (
                                <option
                                  key={d}
                                  value={item.user_ID}
                                  className="select-category-placeholder"
                                >
                                  {item.agentName}
                                </option>
                              ))}
                            {this.state.reportToData.length === 0 && (
                              <option value="-1">
                                {TranslationContext !== undefined
                                  ? TranslationContext.option.root
                                  : "Root"}
                              </option>
                            )}
                          </select>
                          {this.state.profileDetailsErrors ? (
                            <div className="text-danger">
                              {this.state.profileDetailsErrors["ReportTo"] ||
                                ""}
                            </div>
                          ) : null}
                        </div>
                        {this.state.profileDetailsReadOnly === true ? (
                          <div>
                            <button
                              className="butn"
                              onClick={() => {
                                this.setState({
                                  profileDetailsReadOnly: false,
                                  btnProfileToggle: true,
                                });
                              }}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.button.edit
                                : "Edit"}
                            </button>
                          </div>
                        ) : this.state.btnProfileToggle === true ? (
                          <div>
                            <button
                              onClick={this.handleUpdateProfileDetialsSubmit.bind(
                                this
                              )}
                              className="butn"
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.button.update
                                : "Update"}
                              &amp;
                              {TranslationContext !== undefined
                                ? TranslationContext.button.next
                                : "Next"}
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button
                              className="butn"
                              onClick={this.handleProfileDetailsSubmit.bind(
                                this,
                                false
                              )}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.button.saveandnext
                                : "SAVE & Next"}
                            </button>
                          </div>
                        )}
                      </Panel>

                      {this.state.createUserType !== 1 ? (
                        <Panel
                          header={
                            TranslationContext !== undefined
                              ? TranslationContext.a.mappedcategory
                              : "Mapped Category"
                          }
                          key="MappedCategory"
                        >
                          <div>
                            <label>
                              {TranslationContext !== undefined
                                ? TranslationContext.label.brand
                                : "Brand"}
                            </label>
                            <Select
                              getOptionLabel={(option) => option.brandName}
                              getOptionValue={(option) => option.brandID}
                              options={this.state.brandData}
                              placeholder={
                                TranslationContext !== undefined
                                  ? TranslationContext.button.select
                                  : "Select"
                              }
                              closeMenuOnSelect={false}
                              name="Brand"
                              onChange={this.handleMapCategoryChange.bind(
                                this,
                                "Brand"
                              )}
                              value={this.state.mapCateogryInput.Brand}
                              isMulti
                            />
                            {this.state.mapCateogryErrors ? (
                              <div className="text-danger">
                                {this.state.mapCateogryErrors["Brand"] || ""}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <label>
                              {TranslationContext !== undefined
                                ? TranslationContext.label.categories
                                : "Categories"}
                            </label>

                            <Select
                              getOptionLabel={(option) => option.categoryName}
                              getOptionValue={(option) => option.categoryID}
                              options={this.state.CategoryData}
                              placeholder={
                                TranslationContext !== undefined
                                  ? TranslationContext.button.select
                                  : "Select"
                              }
                              name="Categories"
                              closeMenuOnSelect={false}
                              onChange={this.handleMapCategoryChange.bind(
                                this,
                                "Categories"
                              )}
                              value={this.state.mapCateogryInput.Categories}
                              isMulti
                            />
                            {this.state.mapCateogryErrors ? (
                              <div className="text-danger">
                                {this.state.mapCateogryErrors["Categories"] ||
                                  ""}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <label>
                              {TranslationContext !== undefined
                                ? TranslationContext.label.subcategories
                                : "Sub Categories"}
                            </label>

                            <Select
                              getOptionLabel={(option) =>
                                option.subCategoryName
                              }
                              getOptionValue={(option) => option.subCategoryID}
                              options={this.state.SubCategoryData}
                              placeholder={
                                TranslationContext !== undefined
                                  ? TranslationContext.button.select
                                  : "Select"
                              }
                              name="SubCategories"
                              closeMenuOnSelect={false}
                              onChange={this.handleMapCategoryChange.bind(
                                this,
                                "SubCategories"
                              )}
                              value={this.state.mapCateogryInput.SubCategories}
                              isMulti
                            />
                            {this.state.mapCateogryErrors ? (
                              <div className="text-danger">
                                {this.state.mapCateogryErrors[
                                  "SubCategories"
                                ] || ""}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <label>
                              {TranslationContext !== undefined
                                ? TranslationContext.label.issuetype
                                : "Issue Type"}
                            </label>

                            <Select
                              getOptionLabel={(option) => option.issueTypeName}
                              getOptionValue={(option) => option.issueTypeID}
                              options={this.state.IssueTypeData}
                              placeholder={
                                TranslationContext !== undefined
                                  ? TranslationContext.button.select
                                  : "Select"
                              }
                              name="IssueType"
                              closeMenuOnSelect={false}
                              onChange={this.handleMapCategoryChange.bind(
                                this,
                                "IssueType"
                              )}
                              value={this.state.mapCateogryInput.IssueType}
                              isMulti
                            />
                            {this.state.mapCateogryErrors ? (
                              <div className="text-danger">
                                {this.state.mapCateogryErrors["IssueType"] ||
                                  ""}
                              </div>
                            ) : null}
                          </div>

                          <div className="mapped-cate-extra">
                            {this.state.createUserType !== 3 ? (
                              <div>
                                <label>
                                  {TranslationContext !== undefined
                                    ? TranslationContext.label.crmrole
                                    : "CRM Role"}
                                </label>
                                <select
                                  className="add-select-category"
                                  name="CRMRole"
                                  onChange={this.handleMapCategoryChange.bind(
                                    this,
                                    "CRMRole"
                                  )}
                                  value={this.state.mapCateogryInput.CRMRole}
                                >
                                  <option value="">
                                    {TranslationContext !== undefined
                                      ? TranslationContext.option.select
                                      : "Select"}
                                  </option>
                                  {this.state.CRMRoleData !== null &&
                                    this.state.CRMRoleData.map((item, d) => {
                                      return (
                                        <option
                                          key={d}
                                          value={item.crmRoleID}
                                          className="select-category-placeholder"
                                        >
                                          {item.roleName}
                                        </option>
                                      );
                                    })}
                                </select>
                                {this.state.mapCateogryErrors ? (
                                  <div className="text-danger">
                                    {this.state.mapCateogryErrors["CRMRole"] ||
                                      ""}
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                            <div className="escalation-options">
                              <div className="filter-checkbox">
                                <input
                                  type="checkbox"
                                  id="copy-esc"
                                  name="CopyEscalation"
                                  value={
                                    this.state.mapCateogryInput
                                      .CopyEscalation || false
                                  }
                                  onChange={this.handleMapCategoryChange.bind(
                                    this,
                                    "CopyEscalation"
                                  )}
                                />

                                <label htmlFor="copy-esc">
                                  {TranslationContext !== undefined
                                    ? TranslationContext.label.copyescalation
                                    : "Copy Escalation"}
                                </label>
                              </div>
                              {this.state.mapCateogryErrors ? (
                                <div className="text-danger">
                                  {this.state.mapCateogryErrors[
                                    "CopyEscalation"
                                  ] || ""}
                                </div>
                              ) : null}
                              <div className="filter-checkbox">
                                <input
                                  type="checkbox"
                                  id="assign-esc"
                                  name="AssignEscalation"
                                  value={
                                    this.state.mapCateogryInput
                                      .AssignEscalation || false
                                  }
                                  onChange={this.handleMapCategoryChange.bind(
                                    this,
                                    "AssignEscalation"
                                  )}
                                />

                                <label htmlFor="assign-esc">
                                  {TranslationContext !== undefined
                                    ? TranslationContext.label.assignescalation
                                    : "Assign Escalation"}
                                </label>
                              </div>
                              {this.state.mapCateogryErrors ? (
                                <div className="text-danger">
                                  {this.state.mapCateogryErrors[
                                    "AssignEscalation"
                                  ] || ""}
                                </div>
                              ) : null}
                              {this.state.mapCateogryInput[
                                "AssignEscalation"
                              ] === true ? (
                                <>
                                  <div className="sup-agent-cntr">
                                    <div className="status-options">
                                      <input
                                        type="radio"
                                        name="Supervisor"
                                        id="Supervisor"
                                        value={
                                          this.state.mapCateogryInput.Supervisor
                                        }
                                        onChange={this.handleMapCategoryChange.bind(
                                          this,
                                          "Supervisor"
                                        )}
                                      />

                                      <label
                                        htmlFor="Supervisor"
                                        className="logout-label"
                                      >
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.supervisor
                                          : "Supervisor"}
                                      </label>
                                    </div>
                                    <div className="status-options">
                                      <input
                                        type="radio"
                                        name="Supervisor"
                                        id="Agent"
                                        value={
                                          this.state.mapCateogryInput.Agent
                                        }
                                        onChange={this.handleMapCategoryChange.bind(
                                          this,
                                          "Agent"
                                        )}
                                      />
                                      <label
                                        htmlFor="Agent"
                                        className="logout-label"
                                      >
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.agent
                                          : "Agent"}
                                      </label>
                                    </div>
                                  </div>
                                  {this.state.mapCateogryErrors ? (
                                    <div className="text-danger">
                                      {this.state.mapCateogryErrors[
                                        "SupervisorOrAgent"
                                      ] || ""}
                                    </div>
                                  ) : null}
                                </>
                              ) : null}
                            </div>

                            {this.state.mapCateogryInput["Agent"] === true &&
                            this.state.mapCateogryInput["AssignEscalation"] ===
                              true ? (
                              <div className="div-cntr">
                                <label>
                                  {TranslationContext !== undefined
                                    ? TranslationContext.label.selectagent
                                    : "Select Agent"}
                                </label>

                                <select
                                  className="add-select-category"
                                  name="AgentID"
                                  value={this.state.mapCateogryInput.AgentID}
                                  onChange={this.handleMapCategoryChange.bind(
                                    this,
                                    "AgentID"
                                  )}
                                >
                                  <option value="">
                                    {TranslationContext !== undefined
                                      ? TranslationContext.label.selectagent
                                      : "Select Agent"}
                                  </option>
                                  {this.state.AgentData !== null &&
                                    this.state.AgentData.map((item, i) => (
                                      <option key={i} value={item.user_ID}>
                                        {item.agentName}
                                      </option>
                                    ))}
                                </select>
                                {this.state.mapCateogryErrors ? (
                                  <div className="text-danger">
                                    {this.state.mapCateogryErrors["AgentID"] ||
                                      ""}
                                  </div>
                                ) : null}
                              </div>
                            ) : null}

                            <div>
                              <label>
                                {TranslationContext !== undefined
                                  ? TranslationContext.label.status
                                  : "Status"}
                              </label>
                              <select
                                name="Status"
                                value={this.state.mapCateogryInput.Status}
                                onChange={this.handleMapCategoryChange.bind(
                                  this,
                                  "Status"
                                )}
                              >
                                <option value={"true"}>
                                  {TranslationContext !== undefined
                                    ? TranslationContext.span.active
                                    : "Active"}
                                </option>
                                <option value={"false"}>
                                  {TranslationContext !== undefined
                                    ? TranslationContext.span.inactive
                                    : "Inactive"}
                                </option>
                              </select>
                              {this.state.mapCateogryErrors ? (
                                <div className="text-danger">
                                  {this.state.mapCateogryErrors["Status"] || ""}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div>
                            <button
                              className="butn"
                              onClick={this.handleAddMapCategory.bind(this)}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.label.add
                                : "ADD"}
                            </button>
                          </div>
                        </Panel>
                      ) : null}
                      {this.state.createUserType !== 0 ? (
                        <Panel
                          header={
                            TranslationContext !== undefined
                              ? TranslationContext.a.mappedclaimcategory
                              : "Mapped Claim Category"
                          }
                          key="MappedClaimCategory"
                        >
                          <div>
                            <label>
                              {TranslationContext !== undefined
                                ? TranslationContext.label.brand
                                : "Brand"}
                            </label>
                            <Select
                              getOptionLabel={(option) => option.brandName}
                              getOptionValue={(option) => option.brandID}
                              options={this.state.brandData}
                              placeholder={
                                TranslationContext !== undefined
                                  ? TranslationContext.placeholder.select
                                  : "Select"
                              }
                              closeMenuOnSelect={false}
                              name="selectedClaimBrand"
                              // onChange={this.handleMultiBrandonChange.bind(this)}
                              value={this.state.selectedClaimBrand}
                              isMulti
                            />
                            {/* {this.state.selectedClaimBrand.length === 0 && (
                            <p style={{ color: "red", marginBottom: "0px" }}>
                              {this.state.mappedBrandCompulsory}
                            </p>
                          )} */}
                          </div>
                          <div>
                            <label>
                              {TranslationContext !== undefined
                                ? TranslationContext.label.categories
                                : "Categories"}
                            </label>
                            <Select
                              getOptionLabel={(option) => option.categoryName}
                              getOptionValue={(option) => option.categoryID}
                              options={this.state.claimCategoryData}
                              placeholder={
                                TranslationContext !== undefined
                                  ? TranslationContext.placeholder.select
                                  : "Select"
                              }
                              closeMenuOnSelect={false}
                              name="selectedClaimCategory"
                              // onChange={this.handleMultiCategoryonChange.bind(
                              //   this
                              // )}
                              value={this.state.selectedClaimCategory}
                              isMulti
                            />
                            {/* {this.state.selectedClaimCategory.length === 0 && (
                            <p style={{ color: "red", marginBottom: "0px" }}>
                              {this.state.mappedCategoryCompulsory}
                            </p>
                          )} */}
                          </div>
                          <div>
                            <label>
                              {TranslationContext !== undefined
                                ? TranslationContext.label.subcategories
                                : "Sub Categories"}
                            </label>
                            <Select
                              getOptionLabel={(option) =>
                                option.subCategoryName
                              }
                              getOptionValue={(option) => option.subCategoryID}
                              options={this.state.claimSubCategoryData}
                              placeholder={
                                TranslationContext !== undefined
                                  ? TranslationContext.placeholder.select
                                  : "Select"
                              }
                              closeMenuOnSelect={false}
                              name="selectedClaimSubCategory"
                              // onChange={this.handleMultiSubCategoryonChange.bind(
                              //   this
                              // )}
                              value={this.state.selectedClaimSubCategory}
                              isMulti
                            />
                            {/* {this.state.selectedClaimSubCategory.length === 0 && (
                            <p style={{ color: "red", marginBottom: "0px" }}>
                              {this.state.mappedSubCategoryCompulsory}
                            </p>
                          )} */}
                          </div>
                          <div>
                            <label>
                              {TranslationContext !== undefined
                                ? TranslationContext.label.issuetype
                                : "Issue Type"}
                            </label>
                            <Select
                              getOptionLabel={(option) => option.issueTypeName}
                              getOptionValue={(option) => option.issueTypeID}
                              options={this.state.claimIssueTypeData}
                              placeholder={
                                TranslationContext !== undefined
                                  ? TranslationContext.placeholder.select
                                  : "Select"
                              }
                              closeMenuOnSelect={false}
                              name="selectedClaimIssueType"
                              // onChange={this.handleMultiIssueTypeonChange.bind(
                              //   this
                              // )}
                              value={this.state.selectedClaimIssueType}
                              isMulti
                            />
                            {/* {this.state.selectedClaimIssueType.length === 0 && (
                            <p style={{ color: "red", marginBottom: "0px" }}>
                              {this.state.mappedIssueTypeCompulsory}
                            </p>
                          )} */}
                          </div>
                          <div>
                            <label>
                              {TranslationContext !== undefined
                                ? TranslationContext.label.claimapprover
                                : "Claim Approver"}
                            </label>
                            <select
                              name="selectClaimApprover"
                              value={this.state.selectClaimApprover}
                              // onChange={this.handleDropDownOnChange}
                              className="store-create-select"
                            >
                              <option>
                                {TranslationContext !== undefined
                                  ? TranslationContext.option.select
                                  : "Select"}
                              </option>
                              <option value={"yes"}>
                                {TranslationContext !== undefined
                                  ? TranslationContext.option.yes
                                  : "Yes"}
                              </option>
                              <option value={"no"}>
                                {TranslationContext !== undefined
                                  ? TranslationContext.option.no
                                  : "No"}
                              </option>
                            </select>
                            {/* {this.state.selectClaimApprover.length === 0 && (
                            <p style={{ color: "red", marginBottom: "0px" }}>
                              {this.state.ClaimApproverCompulsory}
                            </p>
                          )} */}
                          </div>
                          <div className="mapped-cate-extra">
                            <div>
                              <label>
                                {TranslationContext !== undefined
                                  ? TranslationContext.label.crmrole
                                  : "CRM Role"}
                              </label>
                              <select
                                className="store-create-select"
                                name="selectCrmRole"
                                // value={this.state.selectCrmRole}
                                // onChange={this.handleDropDownOnChange}
                              >
                                <option>
                                  {TranslationContext !== undefined
                                    ? TranslationContext.option.select
                                    : "Select"}
                                </option>
                                {/* {this.state.CRMRoleData !== null &&
                                  this.state.CRMRoleData.map((item, d) => (
                                    <option
                                      key={d}
                                      value={item.crmRoleID}
                                      className="select-category-placeholder"
                                    >
                                      {item.roleName}
                                    </option>
                                  ))} */}
                              </select>
                              {/* {this.state.selectCrmRole === 0 && (
                              <p style={{ color: "red", marginBottom: "0px" }}>
                                {this.state.CrmRoleCompulsory}
                              </p>
                            )} */}
                            </div>
                            <div>
                              <label>
                                {TranslationContext !== undefined
                                  ? TranslationContext.label.status
                                  : "Status"}
                              </label>
                              <select
                                name="selectStatus"
                                value={this.state.selectedStatus}
                                onChange={this.handleOnChangeUserData}
                                className="store-create-select"
                              >
                                <option value="true">
                                  {TranslationContext !== undefined
                                    ? TranslationContext.span.active
                                    : "Active"}
                                </option>
                                <option value="false">
                                  {TranslationContext !== undefined
                                    ? TranslationContext.span.inactive
                                    : "Inactive"}
                                </option>
                              </select>
                              {/* {this.state.selectStatus === 0 && (
                              <p style={{ color: "red", marginBottom: "0px" }}>
                                {this.state.statusCompulsory}
                              </p>
                            )} */}
                            </div>
                          </div>
                          <div>
                            <button
                              className="butn"
                              type="button"
                              // onClick={this.handleFinalSaveUserData.bind(this)}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.button.add
                                : "ADD"}
                            </button>
                          </div>
                        </Panel>
                      ) : null}
                    </Collapse>
                  </div>
                </div>
                <div className="right-sect-div">
                  <div className="d-flex justify-content-between pb-2">
                    <h3 className="pb-0">
                      {TranslationContext !== undefined
                        ? TranslationContext.h3.bulkupload
                        : "Bulk Upload"}
                    </h3>
                    <div>
                      <div className="down-excel mb-2">
                        <p>
                          {TranslationContext !== undefined
                            ? TranslationContext.p.template
                            : "Template"}
                        </p>
                        <CSVLink
                          filename={"User.csv"}
                          data={config.userTemplate}
                        >
                          <img src={DownExcel} alt="download icon" />
                        </CSVLink>
                      </div>
                    </div>
                  </div>
                  <Spin
                    tip={
                      TranslationContext !== undefined
                        ? TranslationContext.tip.pleasewait
                        : "Please wait..."
                    }
                    spinning={this.state.bulkuploadLoading}
                  >
                    <div className="mainfileUpload">
                      <Dropzone onDrop={this.fileUpload.bind(this)}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input
                              {...getInputProps()}
                              className="file-upload d-none"
                            />
                            <div className="file-icon">
                              <img src={FileUpload} alt="file-upload" />
                            </div>
                            <span className={"fileupload-span"}>
                              {TranslationContext !== undefined
                                ? TranslationContext.span.addfile
                                : "Add File"}
                            </span>
                            {TranslationContext !== undefined
                              ? TranslationContext.label.ordropfilehere
                              : "or Drop File here"}
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    {this.state.fileN.length === 0 && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {this.state.bulkuploadCompulsion}
                      </p>
                    )}
                    {this.state.fileName && (
                      <div className="file-info">
                        <div className="file-cntr">
                          <div className="file-dtls">
                            <p className="file-name">{this.state.fileName}</p>
                            <div className="del-file" id="del-file-1">
                              <img
                                src={DelBlack}
                                alt="delete-black"
                                onClick={this.togglePopover}
                              />
                            </div>
                            <UncontrolledPopover
                              trigger="legacy"
                              placement="auto"
                              target="del-file-1"
                              className="general-popover delete-popover"
                              isOpen={this.state.isOpen}
                              toggle={this.togglePopover}
                            >
                              <PopoverBody className="d-flex">
                                <div className="del-big-icon">
                                  <img src={DelBigIcon} alt="del-icon" />
                                </div>
                                <div>
                                  <p className="font-weight-bold blak-clr">
                                    {TranslationContext !== undefined
                                      ? TranslationContext.p.deletefile
                                      : "Delete file?"}
                                  </p>
                                  <p className="mt-1 fs-12">
                                    {TranslationContext !== undefined
                                      ? TranslationContext.p
                                          .areyousureyouwanttodeletethisfile
                                      : "Are you sure you want to delete this file"}
                                    ?
                                  </p>
                                  <div className="del-can">
                                    <a
                                      className="canblue"
                                      onClick={this.togglePopover}
                                    >
                                      {TranslationContext !== undefined
                                        ? TranslationContext.a.cancel
                                        : "CANCEL"}
                                    </a>
                                    <button
                                      className="butn"
                                      onClick={this.handleDeleteBulkupload}
                                    >
                                      {TranslationContext !== undefined
                                        ? TranslationContext.label.delete
                                        : "Delete"}
                                    </button>
                                  </div>
                                </div>
                              </PopoverBody>
                            </UncontrolledPopover>
                          </div>
                          <div>
                            <span className="file-size">
                              {this.state.fileSize}
                            </span>
                          </div>
                        </div>
                        {this.state.fileN.length > 0 &&
                        this.state.isFileUploadFail ? (
                          <div className="file-cntr">
                            <div className="file-dtls">
                              <p className="file-name">{this.state.fileName}</p>
                              <a
                                className="file-retry"
                                onClick={this.hanldeAddBulkUpload.bind(this)}
                              >
                                {TranslationContext !== undefined
                                  ? TranslationContext.a.retry
                                  : "Retry"}
                              </a>
                            </div>
                            <div>
                              <span className="file-failed">
                                {TranslationContext !== undefined
                                  ? TranslationContext.span.failed
                                  : "Failed"}
                              </span>
                            </div>
                          </div>
                        ) : null}
                        {this.state.showProgress ? (
                          <div className="file-cntr">
                            <div className="file-dtls">
                              <p className="file-name pr-0">
                                {this.state.fileName}
                              </p>
                            </div>
                            <div>
                              <div className="d-flex align-items-center mt-2">
                                <ProgressBar
                                  className="file-progress"
                                  now={this.state.progressValue}
                                />
                                <div className="cancel-upload">
                                  <img src={UploadCancel} alt="upload cancel" />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}
                    <button
                      className="butn"
                      onClick={this.hanldeAddBulkUpload.bind(this)}
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.label.add
                        : "ADD"}
                    </button>
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MainUserMaster;
