import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap-tabs";
import TicketReports from "./Ticketing/Reports";
import StoreReports from "./Store/StoreReports";
import * as translationHI from "./../../translations/hindi";
import * as translationMA from "./../../translations/marathi";
import Demo from "./../../store/Hashtag";
import { Link } from "react-router-dom";

class FileUploadLogMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      translateLanguage: {},
    };
  }
  componentDidMount() {
    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.setState({
        translateLanguage: translationHI,
      });
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.setState({
        translateLanguage: translationMA,
      });
    } else {
      this.setState({
        translateLanguage: {},
      });
    }
  }
  render() {
    const TranslationContext = this.state.translateLanguage.default;
    return (
      <React.Fragment>
        <div className="container-fluid setting-title setting-breadcrumb ">
          <Link to="settings" className="header-path">
            {TranslationContext !== undefined
              ? TranslationContext.link.setting
              : "Settings"}
          </Link>
          <span>&gt;</span>
          <Link to={Demo.BLANK_LINK} className="active header-path">
            Reports Master
          </Link>
        </div>
        <Tabs selected={"Ticket Reports"} className="mr-20">
          <Tab label={"Ticket Reports"} key={1}>
            <TicketReports />
          </Tab>
          <Tab label={"Store Reports"} key={2}>
            <StoreReports />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}

export default FileUploadLogMaster;
