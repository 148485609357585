import React, { Component, Fragment } from "react";
// import axios from "axios";
// import config from "../../helpers/config";
// import { NotificationManager } from "react-notifications";
// import { authHeader } from "../../helpers/authHeader";
import { Table, Switch } from "antd";
import DatePicker from "react-datepicker";
import * as translationHI from "../../translations/hindi";
import * as translationMA from "../../translations/marathi";

class TicketSystemAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointName: "",
      appointEmailId: "",
      appointMobileNo: "",
      appointmentDate: "",
      appointmentTime: "",
      appointmentfee: "",
      remarks: "",
      appointmentTypeData: [
        { appointmentTypeId: 1, appointmentTypeName: "Pickup" },
        { appointmentTypeId: 2, appointmentTypeName: "Video Call" },
        { appointmentTypeId: 3, appointmentTypeName: "Visit" },
      ],
      selectStateValue: 0,
      selectCityValue: 0,
      selectBranchValue: 0,
      selectServiceValue: 0,
      selectAssignToValue: 0,
      selectAppointmentType: 0,
      appointNameValidation: "",
      appointEmailIdValidation: "",
      appointMobileNoValidation: "",
      appointStateValidation: "",
      appointCityValidation: "",
      appointBranchValidation: "",
      appointServiceValidation: "",
      appointAssignToValidation: "",
      appointTypeValidation: "",
      appointmentDateValidation: "",
      appointmentTimeValidation: "",
      appointmentFeeValidation: "",
      remarksValidation: "",
      translateLanguage: {},
    };
  }

  componentDidMount() {
    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.state.translateLanguage = translationHI;
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.state.translateLanguage = translationMA;
    } else {
      this.state.translateLanguage = {};
    }
  }
  /// handle Input on change
  handleInputOnchange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  /// Onchange for Mobile no
  hanldeMobileNoChange = (e) => {
    var reg = /^[0-9\b]+$/;

    if (e.target.value === "" || reg.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      e.target.value = "";
    }
  };

  /// handle decimal onchange
  hanldeDecimalOnChange = (e) => {
    var values = e.target.value;
    var names = e.target.name;

    if (isNaN(values)) {
      return false;
    }

    var splitText = values.split(".");
    var index = values.indexOf(".");

    if (index !== -1) {
      if (splitText) {
        if (splitText[1].length <= 2) {
          if (index !== -1 && splitText.length === 2) {
            if (names === "appointmentfee") {
              this.setState({ appointmentfee: values });
            }
          }
        } else {
          return false;
        }
      } else {
        if (names === "appointmentfee") {
          this.setState({ appointmentfee: values });
        }
      }
    } else {
      if (names === "appointmentfee") {
        this.setState({ appointmentfee: values });
      }
    }
  };
  /// handle select date
  handleAppointmentDate = (date) => {
    this.setState({
      appointmentDate: date,
    });
  };
  /// handle add appoinment
  handleAddAppointmentData() {
    if (
      this.state.appointName !== "" &&
      this.state.appointEmailId !== "" &&
      this.state.appointMobileNo !== "" &&
      this.state.selectStateValue !== 0 &&
      this.state.selectCityValue !== 0 &&
      this.state.selectBranchValue !== 0 &&
      this.state.selectServiceValue !== 0 &&
      this.state.selectAssignToValue !== 0 &&
      this.state.selectAppointmentType !== 0 &&
      this.state.appointmentDate !== "" &&
      this.state.appointmentTime !== "" &&
      this.state.remarks !== "" &&
      this.state.appointmentfee !== ""
    ) {
      /// API function
    } else {
      this.setState({
        appointNameValidation: "Please enter name.",
        appointEmailIdValidation: "Please enter email id.",
        appointMobileNoValidation: "Please enter mobile no",
        appointStateValidation: "Please select state.",
        appointCityValidation: "Please select city.",
        appointBranchValidation: "Please select branch.",
        appointServiceValidation: "Please select service.",
        appointAssignToValidation: "Please select assign to.",
        appointTypeValidation: "Please select Type.",
        appointmentDateValidation: "Please select date.",
        appointmentTimeValidation: "Please select time.",
        remarksValidation: "Please enter remarks.",
        appointmentFeeValidation: "Please enter appointment fee.",
      });
    }
  }
  handleAppointmentTypeChange = (e) => {
    this.setState({ selectAppointmentType: e.target.value });
  };
  render() {
    const TranslationContext = this.state.translateLanguage.default;

    return (
      <Fragment>
        <div className="ticketSycard">
          <div className="ticketSycard1">
            <div id="accordion">
              <div className="card-header collapsetask">
                <h5 className="mb-8 drop">
                  <label
                    className="btn btn-link drop1"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    {TranslationContext !== undefined
                      ? TranslationContext.nav.appointment
                      : "Appointment"}
                  </label>
                </h5>
              </div>

              <div
                id="collapseOne"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div>
                  <div className="row m-b-10 m-l-10 m-r-10 m-t-10">
                    <div className="col-md-6">
                      <label className="addmanuallytext">
                        {TranslationContext !== undefined
                          ? TranslationContext.ticketingDashboard.addappointment
                          : "Add Appointment"}
                      </label>
                    </div>
                  </div>
                  <div className="row m-b-10 m-l-10 m-r-10">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="addmanuallytext1"
                        placeholder={
                          TranslationContext !== undefined
                            ? TranslationContext.span.name
                            : "Name"
                        }
                        autoComplete="off"
                        name="appointName"
                        value={this.state.appointName}
                        onChange={this.handleInputOnchange}
                      />
                      {this.state.appointName === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.appointNameValidation}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="addmanuallytext1"
                        placeholder={
                          TranslationContext !== undefined
                            ? TranslationContext.p.emailid
                            : "Email ID"
                        }
                        autoComplete="off"
                        name="appointEmailId"
                        value={this.state.appointEmailId}
                        onChange={this.handleInputOnchange}
                      />
                      {this.state.appointEmailId === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.appointEmailIdValidation}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row m-b-10 m-l-10 m-r-10">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="addmanuallytext1"
                        placeholder={
                          TranslationContext !== undefined
                            ? TranslationContext.p.mobileno
                            : "Mobile No"
                        }
                        autoComplete="off"
                        maxLength={10}
                        name="appointMobileNo"
                        value={this.state.appointMobileNo}
                        onChange={this.hanldeMobileNoChange}
                      />
                      {this.state.appointMobileNo === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.appointMobileNoValidation}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <select
                        className="category-select-system dropdown-label"
                        value={this.state.selectStateValue}
                        name="selectStateValue"
                        onChange={this.handleInputOnchange}
                      >
                        <option value={0}>
                          {TranslationContext !== undefined
                            ? TranslationContext.label.state
                            : "State"}
                        </option>
                      </select>
                      {this.state.selectStateValue === 0 && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.appointStateValidation}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row m-b-10 m-l-10 m-r-10">
                    <div className="col-md-6">
                      <select
                        className="category-select-system dropdown-label"
                        value={this.state.selectCityValue}
                        name="selectCityValue"
                        onChange={this.handleInputOnchange}
                      >
                        <option
                          className="select-sub-category-placeholder"
                          value={0}
                        >
                          {TranslationContext !== undefined
                            ? TranslationContext.span.city
                            : "City"}
                        </option>
                      </select>
                      {this.state.selectCityValue === 0 && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.appointCityValidation}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6 dapic">
                      <select
                        className="category-select-system dropdown-label"
                        value={this.state.selectBranchValue}
                        name="selectBranchValue"
                        onChange={this.handleInputOnchange}
                      >
                        <option
                          className="select-sub-category-placeholder"
                          value={0}
                        >
                          Branch / Location / Site / Store
                        </option>
                      </select>
                      {this.state.selectBranchValue === 0 && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.appointBranchValidation}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row m-b-10 m-l-10 m-r-10">
                    <div className="col-md-6">
                      <select
                        className="category-select-system dropdown-label"
                        value={this.state.selectServiceValue}
                        name="selectServiceValue"
                        onChange={this.handleInputOnchange}
                      >
                        <option
                          className="select-sub-category-placeholder"
                          value={0}
                        >
                          Speciality / Service
                        </option>
                      </select>
                      {this.state.selectServiceValue === 0 && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.appointServiceValidation}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <select
                        className="category-select-system dropdown-label"
                        value={this.state.selectAssignToValue}
                        name="selectAssignToValue"
                        onChange={this.handleInputOnchange}
                      >
                        <option
                          className="select-sub-category-placeholder"
                          value={0}
                        >
                          Assigned To
                        </option>
                      </select>
                      {this.state.selectAssignToValue === 0 && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.appointAssignToValidation}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row m-b-10 m-l-10 m-r-10">
                    <div className="col-md-6">
                      <div className="priority-butns-cntr">
                        <select
                          className="category-select-system dropdown-label"
                          value={this.state.selectAppointmentType}
                          name="selectAppointmentType"
                          onChange={this.handleAppointmentTypeChange}
                        >
                          <option
                            className="select-sub-category-placeholder"
                            value={0}
                          >
                            Select Appointment Type
                          </option>
                          {this.state.appointmentTypeData !== null &&
                            this.state.appointmentTypeData.map((item, i) => (
                              <option
                                className="select-sub-category-placeholder"
                                value={item.appointmentTypeId}
                              >
                                {item.appointmentTypeName}
                              </option>
                            ))}
                        </select>
                      </div>
                      {this.state.selectAppointmentType === 0 && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.appointTypeValidation}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <DatePicker
                        placeholderText={
                          TranslationContext !== undefined
                            ? TranslationContext.label.date
                            : "Date"
                        }
                        showMonthDropdown
                        showYearDropdown
                        className="addmanuallytext1"
                        selected={this.state.appointmentDate}
                        value={this.state.appointmentDate}
                        onChange={this.handleAppointmentDate.bind(this)}
                      />
                      {this.state.appointmentDate === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.appointmentDateValidation}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row m-b-10 m-l-10 m-r-10">
                    <div className="col-md-6">
                      <DatePicker
                        placeholderText="Time"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="addmanuallytext1"
                        selected={this.state.appointmentTime}
                        value={this.state.appointmentTime}
                        onChange={(time) =>
                          this.setState({
                            appointmentTime: time,
                          })
                        }
                      />
                      {this.state.appointmentTime === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.appointmentTimeValidation}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                       
                      <Switch
                        checkedChildren="Paid"
                        unCheckedChildren="Free"
                        defaultChecked
                        style={{ marginTop: "8px", marginBottom: "15px" }}
                      />
                      <input
                        type="text"
                        className="addmanuallytext1"
                        placeholder={
                          TranslationContext !== undefined
                            ? TranslationContext.ticketingDashboard
                                .appointmentfee
                            : "Appointment Fee"
                        }
                        autoComplete="off"
                        name="appointmentfee"
                        value={this.state.appointmentfee}
                        onChange={this.hanldeDecimalOnChange}
                        style={{width:"180px",marginLeft:"5px"}}
                      />
                      {this.state.appointmentfee === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.appointmentFeeValidation}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row m-b-10 m-l-10 m-r-10">
                    <div className="col-md-12">
                      <textarea
                        className="addNote-textarea-system"
                        placeholder={
                          TranslationContext !== undefined
                            ? TranslationContext.ticketingDashboard.remarks
                            : "Remarks"
                        }
                        name="remarks"
                        value={this.state.remarks}
                        onChange={this.handleInputOnchange}
                      ></textarea>
                      {this.state.remarks === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.remarksValidation}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row m-b-10 m-l-10 m-r-10">
                    <div className="col-md-12 tckAppoinbtncn">
                      <button
                        type="button"
                        className="tckAppointmentBtn m-t-15"
                        onClick={this.handleAddAppointmentData.bind(this)}
                      >
                        Book Appointment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="card-header collapsetask" id="headingTwo">
                  <h5 className="mb-0 drop">
                    <label
                      className="btn btn-link collapsed drop1"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.ticketingDashboard
                            .appointmentlistforcustomer
                        : "Appointment List For Customer"}
                    </label>
                  </h5>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div className="card-body systemtaskreact">
                    <Table
                      columns={[
                        {
                          title:
                            TranslationContext !== undefined
                              ? TranslationContext.span.id
                              : "Name",
                          dataIndex: "name",
                        },
                        {
                          title:
                            TranslationContext !== undefined
                              ? TranslationContext.span.tasktitle
                              : "Email Id",
                          dataIndex: "emailid",
                        },
                        {
                          title:
                            TranslationContext !== undefined
                              ? TranslationContext.span.assignto
                              : "Mobile No",
                          dataIndex: "mobileNo",
                        },
                        {
                          title:
                            TranslationContext !== undefined
                              ? TranslationContext.span.assignto
                              : "State",
                          dataIndex: "state",
                        },
                        {
                          title:
                            TranslationContext !== undefined
                              ? TranslationContext.span.assignto
                              : "City",
                          dataIndex: "city",
                        },
                        {
                          title:
                            TranslationContext !== undefined
                              ? TranslationContext.span.assignto
                              : "Branch",
                          dataIndex: "branch",
                        },
                        {
                          title:
                            TranslationContext !== undefined
                              ? TranslationContext.span.assignto
                              : "Service",
                          dataIndex: "service",
                        },
                        {
                          title:
                            TranslationContext !== undefined
                              ? TranslationContext.span.assignto
                              : "Assign To",
                          dataIndex: "assignto",
                        },
                        {
                          title:
                            TranslationContext !== undefined
                              ? TranslationContext.span.assignto
                              : "Type",
                          dataIndex: "type",
                        },
                        {
                          title:
                            TranslationContext !== undefined
                              ? TranslationContext.span.assignto
                              : "Date",
                          dataIndex: "date",
                        },
                        {
                          title:
                            TranslationContext !== undefined
                              ? TranslationContext.span.assignto
                              : "Time",
                          dataIndex: "time",
                        },
                      ]}
                      dataSource={this.state.appointmentTblData}
                      pagination={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default TicketSystemAppointment;
