import React, { Component } from "react";
import Module from "./Store/StoreModule";
class ModuleMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    return (
      <React.Fragment>
        <Module />
      </React.Fragment>
    );
  }
}

export default ModuleMaster;
