import React, { Component } from "react";
import MainUserMaster from "./MainUserMaster";
import * as translationHI from "./../../translations/hindi";
import * as translationMA from "./../../translations/marathi";
import { Link } from "react-router-dom";

class UserMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      translateLanguage: {},
    };
  }
  componentDidMount() {
    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.setState({
        translateLanguage: translationHI,
      });
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.setState({
        translateLanguage: translationMA,
      });
    } else {
      this.setState({
        translateLanguage: {},
      });
    }
  }
  render() {
    const TranslationContext = this.state.translateLanguage.default;
    return (
      <React.Fragment>
        <div className="container-fluid setting-title setting-breadcrumb ">
          <Link to="settings" className="header-path">
            {TranslationContext !== undefined
              ? TranslationContext.link.setting
              : "Settings"}
          </Link>
          <span>&gt;</span>
          <Link className="active header-path">User Master</Link>
        </div>
        <MainUserMaster />
      </React.Fragment>
    );
  }
}

export default UserMaster;
