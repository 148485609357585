import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap-tabs";
import TicketAlert from "./../../Settings/Alerts";
import StoreAlert from "./../../Settings/Store/StoreAlerts";
import * as translationHI from "./../../../translations/hindi";
import * as translationMA from "./../../../translations/marathi";
import { Link } from "react-router-dom";

class MasterAlerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      translateLanguage: {},
    };
  }
  componentDidMount() {
    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.state.translateLanguage = translationHI;
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.state.translateLanguage = translationMA;
    } else {
      this.state.translateLanguage = {};
    }
  }
  render() {
    const TranslationContext = this.state.translateLanguage.default;
    return (
      <React.Fragment>
        <div className="container-fluid setting-title setting-breadcrumb ">
          <Link to="settings" className="header-path">
            {TranslationContext !== undefined
              ? TranslationContext.link.setting
              : "Settings"}
          </Link>
          <span>&gt;</span>
          <Link className="active header-path">
          {TranslationContext !== undefined
              ? TranslationContext.link.alerts
              : "Alerts"}
          </Link>
        </div>
        <Tabs selected={"Ticket Alert"} className="mr-20">
          <Tab label={"Ticket Alert"} key={1}>
            <TicketAlert />
          </Tab>
          <Tab label={"Store Alert"} key={2}>
            <StoreAlert />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}

export default MasterAlerts;
