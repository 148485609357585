import React, { Component } from "react";
import Demo from "./../../store/Hashtag";
import DelBigIcon from "./../../assets/Images/del-big.png";
import { Popover } from "antd";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import RedDeleteIcon from "./../../assets/Images/red-delete-icon.png";
import Modal from "react-responsive-modal";

import * as translationHI from "./../../translations/hindi";
import * as translationMA from "./../../translations/marathi";
import ActiveStatus from "../activeStatus";

class ServiceTypeMaster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      EscalationGridData: [
        {
          id: 1,
          serviceName: "Sevice 1",
          status: "Active",
        },
        {
          id: 2,
          serviceName: "Sevice 2",
          status: "Inactive",
        },
      ],
      loading: false,
      serviceTypeName: "",
      statusCompulsory: "",
      serviceNameCompulsory: "",
      activeData: ActiveStatus(),
      selectStatus: 0,
      editModal: false,
      translateLanguage: {},
    };
  }

  componentDidMount() {
    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.setState({
        translateLanguage: translationHI,
      });
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.setState({
        translateLanguage: translationMA,
      });
    } else {
      this.setState({
        translateLanguage: {},
      });
    }
  }
  /// handle Edit modal open
  handleEditModalOpen() {
    this.setState({
      editModal: true,
    });
  }
  /// handle Edit modal close
  handleEditModalClose() {
    this.setState({
      editModal: false,
    });
  }
  /// handle create data
  handleCreateHierarchy() {
    if (this.state.serviceTypeName !== "" && this.state.selectStatus !== 0) {
      /// api calling
    } else {
      this.setState({
        serviceNameCompulsory: "Please Enter Service Name.",
        statusCompulsory: "Please Select Status.",
      });
    }
  }
  ////handle status change drop-down
  handleStatusChange = (e) => {
    let value = e.target.value;
    this.setState({ selectStatus: value });
  };

  render() {
    const TranslationContext = this.state.translateLanguage.default;

    return (
      <React.Fragment>
        <div className="position-relative d-inline-block"></div>
        <div className="container-fluid setting-title setting-breadcrumb">
          <Link to="settings" className="header-path">
            {TranslationContext !== undefined
              ? TranslationContext.link.setting
              : "Settings"}
          </Link>
          <span>&gt;</span>
          <Link to={Demo.BLANK_LINK} className="active header-path">
            {TranslationContext !== undefined
              ? TranslationContext.strong.servicetypemaster
              : "Service Type Master"}
          </Link>
        </div>
        <div className="container-fluid">
          <div className="store-settings-cntr settingtable">
            <div className="row">
              <div className="col-md-8">
                {this.state.loading === true ? (
                  <div className="loader-icon"></div>
                ) : (
                  <div className="table-cntr table-height TicketBrandReact settings-align">
                    <ReactTable
                      data={this.state.EscalationGridData}
                      minRows={2}
                      columns={[
                        {
                          Header: "Service Name",
                          sortable: false,
                          accessor: "serviceName",
                        },
                        {
                          Header: "Status",
                          sortable: false,
                          accessor: "status",
                        },
                        {
                          Header: (
                            <span>
                              {TranslationContext !== undefined
                                ? TranslationContext.span.action
                                : "Actions"}
                            </span>
                          ),
                          accessor: "actiondept",
                          sortable: false,
                          Cell: (row) => {
                            return (
                              <>
                                <span className="settings-align-actions">
                                  <Popover
                                    content={
                                      <div className="samdel d-flex general-popover popover-body">
                                        <div className="del-big-icon">
                                          <img
                                            src={DelBigIcon}
                                            alt="del-icon"
                                          />
                                        </div>
                                        <div>
                                          <p className="font-weight-bold blak-clr">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.p.deletefile
                                              : "Delete file?"}
                                          </p>
                                          <p className="mt-1 fs-12">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.p
                                                  .areyousuredeletefile
                                              : "Are you sure you want to delete this file?"}
                                          </p>
                                          <div className="del-can">
                                            <a href="#!" className="canblue">
                                              {TranslationContext !== undefined
                                                ? TranslationContext.a.cancel
                                                : "CANCEL"}
                                            </a>
                                            <button
                                              className="butn"
                                              type="button"
                                            >
                                              {TranslationContext !== undefined
                                                ? TranslationContext.button
                                                    .delete
                                                : "Delete"}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    }
                                    placement="bottom"
                                    trigger="click"
                                  >
                                    <img
                                      src={RedDeleteIcon}
                                      alt="del-icon"
                                      className="del-btn"
                                    />
                                  </Popover>
                                  <button
                                    className="react-tabel-button"
                                    onClick={this.handleEditModalOpen.bind(
                                      this
                                    )}
                                  >
                                    <label className="Table-action-edit-button-text">
                                      EDIT
                                    </label>
                                  </button>
                                </span>
                              </>
                            );
                          },
                        },
                      ]}
                      resizable={false}
                      defaultPageSize={10}
                      showPagination={true}
                    />
                  </div>
                )}
              </div>

              <div className="col-md-4">
                <div className="createHierarchyMask">
                  <div className="createSpace">
                    <label className="create-department">
                      {TranslationContext !== undefined
                        ? TranslationContext.ticketingDashboard
                            .createservicetyepmaster
                        : "CREATE SERVICE TYPE MASTER"}
                    </label>
                    <div className="div-padding-1">
                      <label className="designation-name">
                        {TranslationContext !== undefined
                          ? TranslationContext.ticketingDashboard
                              .servicetypename
                          : "Service Type Name"}
                      </label>
                      <input
                        type="text"
                        className="txt-1"
                        placeholder={
                          TranslationContext !== undefined
                            ? TranslationContext.ticketingDashboard
                                .enterservicetypename
                            : "Enter Service Type Name"
                        }
                        value={this.state.serviceTypeName}
                        autoComplete="off"
                        onChange={(e) =>
                          this.setState({ serviceTypeName: e.target.value })
                        }
                      />
                      {this.state.serviceTypeName === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.serviceNameCompulsory}
                        </p>
                      )}
                    </div>

                    <div className="dropDrownSpace">
                      <label className="designation-name">
                        {TranslationContext !== undefined
                          ? TranslationContext.label.status
                          : "Status"}
                      </label>
                      <select
                        value={this.state.selectStatus}
                        onChange={this.handleStatusChange}
                      >
                        <option value={0}>
                          {TranslationContext !== undefined
                            ? TranslationContext.option.select
                            : "Select"}
                        </option>
                        {this.state.activeData !== null &&
                          this.state.activeData.map((item, j) => (
                            <option key={j} value={item.ActiveID}>
                              {item.ActiveName}
                            </option>
                          ))}
                      </select>
                      {parseInt(this.state.selectStatus) === 0 && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.statusCompulsory}
                        </p>
                      )}
                    </div>
                    <div className="btnSpace">
                      <button
                        className="CreateADDBtn"
                        type="button"
                        onClick={this.handleCreateHierarchy.bind(this)}
                      >
                        {TranslationContext !== undefined
                          ? TranslationContext.button.add
                          : "ADD"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                onClose={this.handleEditModalClose.bind(this)}
                open={this.state.editModal}
                modalId="esacalationEditmdl"
                overlayId="logout-ovrly"
              >
                <div className="createHierarchyMask p-20">
                  <label className="create-department">
                    {TranslationContext !== undefined
                      ? TranslationContext.ticketingDashboard
                          .editeservicetypemaster
                      : "EDIT SERVICE TYPE MASTER"}
                  </label>
                  <div className="div-padding-1">
                    <label className="designation-name">
                      {TranslationContext !== undefined
                        ? TranslationContext.ticketingDashboard.servicetypename
                        : "Service Type Name"}
                    </label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={
                        TranslationContext !== undefined
                          ? TranslationContext.ticketingDashboard
                              .enterservicetypename
                          : "Enter Service Type Name"
                      }
                      autoComplete="off"
                    />
                  </div>

                  <div className="dropDrownSpace">
                    <label className="designation-name">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.status
                        : "Status"}
                    </label>
                    <select
                      value={this.state.selectStatus}
                      onChange={this.handleStatusChange}
                    >
                      <option value={0}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "Select"}
                      </option>
                      {this.state.activeData !== null &&
                        this.state.activeData.map((item, j) => (
                          <option key={j} value={item.ActiveID}>
                            {item.ActiveName}
                          </option>
                        ))}
                    </select>
                    {parseInt(this.state.selectStatus) === 0 && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {this.state.statusCompulsory}
                      </p>
                    )}
                  </div>
                  <div className="btnSpace">
                    <div>
                      <a
                        className="pop-over-cancle"
                        onClick={this.handleEditModalClose.bind(this)}
                      >
                        {TranslationContext !== undefined
                          ? TranslationContext.a.cancel
                          : "CANCEL"}
                      </a>
                      <button className="pop-over-button" type="button">
                        {TranslationContext !== undefined
                          ? TranslationContext.button.save
                          : "SAVE"}
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ServiceTypeMaster;
