import React, { Component } from "react";
import { Route } from "react-router-dom";
// import Loadable from "react-loadable";
import StoreLayout from "./../StoreManager/storelayout";
import StoreDashboard from "./StoreDashboard";
import StoreTask from "./StoreTask";
import EditStoreTask from "./EditStoreTask";
import StoreTaskByTicket from "./StoreTaskByTicket";
import RaiseClaim from "./RaiseClaim";
import Claim from "./Claim";
import ClaimApproveReject from "./ClaimApproveReject";
import StoreAddTask from "./StoreAddTask";
import Settings from "./../Settings/Settings";
import HierarchyMaster from "./../Settings/Store/HierarchyMaster";
import StorePriority from "./../Settings/Store/StorePriority";
import StoreCRMRole from "./../Settings/Store/StoreCRMRole";
import StoreReports from "./../Settings/Store/StoreReports";
import ItemMaster from "./../Settings/ItemMaster";
import SlaTemplateDepartment from "./../Settings/Store/SlaTemplateDepartment";
import StoreUsers from "./../Settings/Store/StoreUsers";
import StoreModule from "./../Settings/Store/StoreModule";
import StoreAlerts from "./../Settings/Store/StoreAlerts";
import { NotificationContainer } from "react-notifications";
import StoreFileUploadLogs from "../Settings/Store/StoreFileUploadLogs";
import UserProfile from "./UserProfile";
import StoreCampaign from "../Campaign/StoreCampaign";
import storeMyTicket from "./storeMyTicket";
import storeMyTicketList from "./storeMyTicketList";
import Notification from "./MobileChatNotification";
import StoreTaskAddCampaign from "./StoreTaskAddCampaign";
import UnassignCampaign from "./UnassignCampaign";
import MasterAlerts from "../Settings/Ticketing/MasterAlerts";
import MasterHierarchy from "./../Settings/MasterHierarchy";
import EscalationHierarchy from "./../Settings/EscalationHierarchy";
import MasterSLA from "./../Settings/Ticketing/MasterSLA";
import PriorityMaster from "./../Settings/PriorityMaster";
import CRMRoleMaster from "./../Settings/CRMRoleMaster";
import FileUploadLogMaster from "./../Settings/FileUploadLogMaster";
import DepartmentMaster from "./../Settings/DepartmentMaster";
import UserMaster from "./../Settings/UserMaster";
import ModuleMaster from "./../Settings/ModuleMaster";
import StoreMaster from "./../Settings/Ticketing/StoreMaster";
import BrandMaster from "./../Settings/Ticketing/Brands";
import CategoryMaster from "./../Settings/CategoryMaster";
import ClaimCategoryMaster from "./../Settings/ClaimCategoryMaster";
import JunkWords from "./../Settings/Ticketing/JunkWords";
import Templates from "./../Settings/Ticketing/Templates";
import BlockedEmail from "./../Settings/Ticketing/BlockedEmail";
import ReportsMaster from "./../Settings/ReportsMaster";
import ServiceTypeMaster from "../Settings/ServiceTypeMaster";
import StoreAppointment from "./StoreAppointment";
export class StoreApp extends Component {
  render() {
    const { match } = this.props;
    return (
      <StoreLayout>
        <Route
          exact
          path={`${match.url}/storeDashboard`}
          component={StoreDashboard}
        />
        <Route exact path={`${match.url}/storetask`} component={StoreTask} />
        <Route exact path={`${match.url}/myTicket`} component={storeMyTicket} />
        <Route
          exact
          path={`${match.url}/myTicketList`}
          component={storeMyTicketList}
        />

        <Route
          exact
          path={`${match.url}/editStoreTask`}
          component={EditStoreTask}
        />
        <Route
          exact
          path={`${match.url}/storeTaskByTicket`}
          component={StoreTaskByTicket}
        />
        <Route exact path={`${match.url}/raiseClaim`} component={RaiseClaim} />
        <Route exact path={`${match.url}/claim`} component={Claim} />
        <Route
          exact
          path={`${match.url}/claimApproveReject`}
          component={ClaimApproveReject}
        />
        <Route
          exact
          path={`${match.url}/storeAddTask`}
          component={StoreAddTask}
        />
        <Route
          exact
          path={`${match.url}/hierarchyMaster`}
          component={HierarchyMaster}
        />
        <Route
          exact
          path={`${match.url}/storePriority`}
          component={StorePriority}
        />
        <Route
          exact
          path={`${match.url}/storeCRMRole`}
          component={StoreCRMRole}
        />
        <Route exact path={`${match.url}/settings`} component={Settings} />
        <Route
          exact
          path={`${match.url}/storeReports`}
          component={StoreReports}
        />
        <Route exact path={`${match.url}/itemMaster`} component={ItemMaster} />
        <Route
          exact
          path={`${match.url}/slaTemplateDepartment`}
          component={SlaTemplateDepartment}
        />
        <Route exact path={`${match.url}/storeUsers`} component={StoreUsers} />
        <Route
          exact
          path={`${match.url}/storeModule`}
          component={StoreModule}
        />
        <Route
          exact
          path={`${match.url}/departmentMaster`}
          component={DepartmentMaster}
        />
        <Route
          exact
          path={`${match.url}/ClaimCategoryMaster`}
          component={ClaimCategoryMaster}
        />
        <Route
          exact
          path={`${match.url}/storeAlerts`}
          component={StoreAlerts}
        />
        <Route
          exact
          path={`${match.url}/storeFileUploadLogs`}
          component={StoreFileUploadLogs}
        />
        <Route
          exact
          path={`${match.url}/userProfile`}
          component={UserProfile}
        />
        <Route exact path={`${match.url}/campaign`} component={StoreCampaign} />
        <Route
          exact
          path={`${match.url}/notification`}
          component={Notification}
        />
        <Route
          exact
          path={`${match.url}/taskAddCampaign`}
          component={StoreTaskAddCampaign}
        />
        <Route
          exact
          path={`${match.url}/unassignCampaign`}
          component={UnassignCampaign}
        />
        <Route
          exact
          path={`${match.url}/MasterAlerts`}
          component={MasterAlerts}
        />
        <Route
          exact
          path={`${match.url}/MasterHierarchy`}
          component={MasterHierarchy}
        />
        <Route
          exact
          path={`${match.url}/EscalationHierarchy`}
          component={EscalationHierarchy}
        />
        <Route exact path={`${match.url}/MasterSLA`} component={MasterSLA} />
        <Route
          exact
          path={`${match.url}/PriorityMaster`}
          component={PriorityMaster}
        />
        <Route
          exact
          path={`${match.url}/CRMRoleMaster`}
          component={CRMRoleMaster}
        />
        <Route
          exact
          path={`${match.url}/FileUploadLogMaster`}
          component={FileUploadLogMaster}
        />
        <Route
          exact
          path={`${match.url}/ModuleMaster`}
          component={ModuleMaster}
        />
        <Route exact path={`${match.url}/UserMaster`} component={UserMaster} />
        <Route
          exact
          path={`${match.url}/StoreMaster`}
          component={StoreMaster}
        />
        <Route exact path={`${match.url}/brands`} component={BrandMaster} />
        <Route
          exact
          path={`${match.url}/CategoryMaster`}
          component={CategoryMaster}
        />
        <Route exact path={`${match.url}/JunkWords`} component={JunkWords} />
        <Route exact path={`${match.url}/templates`} component={Templates} />
        <Route
          exact
          path={`${match.url}/BlockedEmail`}
          component={BlockedEmail}
        />
        <Route
          exact
          path={`${match.url}/ReportsMaster`}
          component={ReportsMaster}
        />
        <Route
          exact
          path={`${match.url}/ServiceTypeMaster`}
          component={ServiceTypeMaster}
        />
        <Route
          exact
          path={`${match.url}/StoreAppointment`}
          component={StoreAppointment}
        />

        <NotificationContainer />
      </StoreLayout>
    );
  }
}
export default StoreApp;
