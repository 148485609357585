import React, { Component } from "react";
import CRMRole from "./Store/StoreCRMRole";
class CRMRoleMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
componentDidMount(){
  
}
  render() {
    return (
      <React.Fragment>
        <CRMRole />
      </React.Fragment>
    );
  }
}

export default CRMRoleMaster;
