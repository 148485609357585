import React, { Component } from "react";
import { Route } from "react-router-dom";
// import Loadable from "react-loadable";
import Dashboard from "./Dashboard";
import Layout from "./../Component/layout";
import MyTicket from "./MyTicket";
import TicketSystem from "./TicketSystem";
import Chatbot from "./Chatbot";
import MyTicketList from "./MyTicketList";
import Settings from "./Settings/Settings";
import KnowledgeBase from "./KnowledgeBase";
import FileUploadLogs from "./Settings/FileUploadLogs";
import TicketHierarchy from "./Settings/Ticketing/TicketHierarchy";
import Brands from "./Settings/Ticketing/Brands";
import Users from "./Settings/Ticketing/Users";
import CreatePriority from "./CreatePriority";
import Alerts from "./Settings/Alerts";
import Templates from "./Settings/Ticketing/Templates";
import AddSearchMyTicket from "./AddSearchMyTicket";
import TicketCRMRole from "./Settings/Ticketing/TicketCRMRole";
import Reports from "./Settings/Ticketing/Reports";
import CreateSLA from "./Settings/Ticketing/CreateSLA";
import StoreMaster from "./Settings/Ticketing/StoreMaster";
import CategoryMaster from "./Settings/CategoryMaster";
import UserProfile from "./UserProfile";
import BlockedEmail from "./Settings/Ticketing/BlockedEmail";
import JunkWords from "./Settings/Ticketing/JunkWords";
import MasterSLA from "./Settings/Ticketing/MasterSLA";
import MasterHierarchy from "./Settings/MasterHierarchy";
import MasterAlerts from "./Settings/Ticketing/MasterAlerts";
import PriorityMaster from "./Settings/PriorityMaster";
import CRMRoleMaster from "./Settings/CRMRoleMaster";
import FileUploadLogMaster from "./Settings/FileUploadLogMaster";
import DepartmentMaster from "./Settings/DepartmentMaster";
import UserMaster from "./Settings/UserMaster";
import ModuleMaster from "./Settings/ModuleMaster";
import EscalationHierarchy from "./Settings/EscalationHierarchy";
import ReportsMaster from "./Settings/ReportsMaster";
import ItemMaster from "./Settings/ItemMaster";
import { NotificationContainer } from "react-notifications";
import ClaimCategoryMaster from "./Settings/ClaimCategoryMaster";
import ServiceTypeMaster from "./Settings/ServiceTypeMaster";
export class App extends Component {
  render() {
    const { match } = this.props;
    return (
      <Layout>
        <Route exact path={`${match.url}/dashboard`} component={Dashboard} />
        <Route exact path={`${match.url}/myticket`} component={MyTicket} />
        <Route
          exact
          path={`${match.url}/ticketSystem`}
          component={TicketSystem}
        />
        <Route exact path={`${match.url}/chatbot`} component={Chatbot} />
        <Route
          exact
          path={`${match.url}/myTicketList`}
          component={MyTicketList}
        />
        <Route
          exact
          path={`${match.url}/ticketHierarchy`}
          component={TicketHierarchy}
        />
        <Route exact path={`${match.url}/settings`} component={Settings} />

        <Route
          exact
          path={`${match.url}/knowledgebase`}
          component={KnowledgeBase}
        />

        <Route exact path={`${match.url}/users`} component={Users} />
        <Route
          exact
          path={`${match.url}/fileUploadLogs`}
          component={FileUploadLogs}
        />
        <Route exact path={`${match.url}/brands`} component={Brands} />
        <Route
          exact
          path={`${match.url}/priority`}
          component={CreatePriority}
        />
        <Route exact path={`${match.url}/alerts`} component={Alerts} />

        <Route exact path={`${match.url}/templates`} component={Templates} />
        <Route
          exact
          path={`${match.url}/addSearchMyTicket`}
          component={AddSearchMyTicket}
        />
        <Route
          exact
          path={`${match.url}/ticketCRMRole`}
          component={TicketCRMRole}
        />

        <Route exact path={`${match.url}/reports`} component={Reports} />

        <Route exact path={`${match.url}/sLA`} component={CreateSLA} />

        <Route
          exact
          path={`${match.url}/storeMaster`}
          component={StoreMaster}
        />

        <Route
          exact
          path={`${match.url}/categoryMaster`}
          component={CategoryMaster}
        />
        <Route
          exact
          path={`${match.url}/userProfile`}
          component={UserProfile}
        />
        <Route
          exact
          path={`${match.url}/blockedemail`}
          component={BlockedEmail}
        />
        <Route exact path={`${match.url}/junkwords`} component={JunkWords} />
        <Route exact path={`${match.url}/MasterSLA`} component={MasterSLA} />
        <Route
          exact
          path={`${match.url}/MasterAlerts`}
          component={MasterAlerts}
        />
        <Route
          exact
          path={`${match.url}/MasterHierarchy`}
          component={MasterHierarchy}
        />
        <Route
          exact
          path={`${match.url}/PriorityMaster`}
          component={PriorityMaster}
        />
        <Route
          exact
          path={`${match.url}/CRMRoleMaster`}
          component={CRMRoleMaster}
        />
        <Route
          exact
          path={`${match.url}/FileUploadLogMaster`}
          component={FileUploadLogMaster}
        />
        <Route
          exact
          path={`${match.url}/DepartmentMaster`}
          component={DepartmentMaster}
        />
        <Route exact path={`${match.url}/UserMaster`} component={UserMaster} />
        <Route
          exact
          path={`${match.url}/ModuleMaster`}
          component={ModuleMaster}
        />
        <Route
          exact
          path={`${match.url}/EscalationHierarchy`}
          component={EscalationHierarchy}
        />
        <Route
          exact
          path={`${match.url}/ReportsMaster`}
          component={ReportsMaster}
        />
        <Route
          exact
          path={`${match.url}/ItemMaster`}
          component={ItemMaster}
        />
        <Route
          exact
          path={`${match.url}/ClaimCategoryMaster`}
          component={ClaimCategoryMaster}
        />
        <Route
          exact
          path={`${match.url}/ServiceTypeMaster`}
          component={ServiceTypeMaster}
        />

        <NotificationContainer />
      </Layout>
    );
  }
}

export default App;
