import React, { Component } from "react";
import Demo from "./../../store/Hashtag";
import DelBigIcon from "./../../assets/Images/del-big.png";
import { Popover } from "antd";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import RedDeleteIcon from "./../../assets/Images/red-delete-icon.png";
import Correct from "./../../assets/Images/correct.png";
import axios from "axios";
import { authHeader } from "../../helpers/authHeader";
import config from "../../helpers/config";
import { NotificationManager } from "react-notifications";
import Modal from "react-responsive-modal";

import * as translationHI from "./../../translations/hindi";
import * as translationMA from "./../../translations/marathi";

class EscalationHierarchy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      EscalationGridData: [],
      userRoleName: "",
      mapUserRoleData: [],
      loading: false,
      userRoleNameCompulsory: "",
      mapUserRoleCompulsory: "",
      translateLanguage: {},
      editModal: false,
      UserRoleNameOvrlayShow: false,
      UserRoleNameShow: false,
      indiUserRoleName: "",
      EditUserRoleNameOvrlayShow: false,
      editUserRoleNameShow: false,
      EditIndiUserRoleName: "",
      selectPhase: 0,
      EscalationEditData: {},
      editRoleNameCompulsory: "",
      editMapUserRoleCompulsory: "",
    };
  }

  componentDidMount() {
    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.setState({
        translateLanguage: translationHI,
      });
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.setState({
        translateLanguage: translationMA,
      });
    } else {
      this.setState({
        translateLanguage: {},
      });
    }
    this.handleGetMapUserNameData("Add");
    this.handleGetEscalationHierarchyGridData();
  }
  /// handle get Escalation Hierarchy Grid Data
  handleGetEscalationHierarchyGridData() {
    let self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/EscalationHierarchy/GetEscalationHierarchyDetails",
      headers: authHeader(),
    })
      .then((res) => {
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({
            EscalationGridData: data.escalationHierarchyDetails,
          });
        } else {
          self.setState({ EscalationGridData: [] });
        }
      })
      .catch((response) => {
        console.log(response);
      });
  }
  /// handle get map user name data
  handleGetMapUserNameData(check) {
    let self = this;
    var user_Type = 0;
    if (check === "Add") {
      user_Type = this.state.selectPhase;
    } else {
      user_Type = this.state.EscalationEditData.userType;
    }
    axios({
      method: "post",
      url: config.apiUrl + "/EscalationHierarchy/GetUserForEscalationHierarchy",
      headers: authHeader(),
      params: {
        Usertype: user_Type,
      },
    })
      .then((res) => {
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({ mapUserRoleData: data.escalationHierarchyUsers });
        } else {
          self.setState({ mapUserRoleData: [] });
        }
      })
      .catch((response) => {
        console.log(response);
      });
  }
  /// handle Edit modal open
  handleEditModalOpen(EscalationEditData) {
    var EditIndiUserRoleName = EscalationEditData.userId;

    this.setState({
      editModal: true,
      EscalationEditData,
      EditIndiUserRoleName,
    });

    setTimeout(() => {
      this.handleGetMapUserNameData("edit");
    }, 10);
  }
  /// handle Edit modal close
  handleEditModalClose() {
    this.setState({
      editModal: false,
    });
  }
  /// handle create data
  handleCreateHierarchy() {
    const TranslationContext = this.state.translateLanguage.default;
    var self = this;

    if (this.state.userRoleName !== "" && this.state.indiUserRoleName !== "") {
      var user_list = this.state.indiUserRoleName
        .substring(",", this.state.indiUserRoleName.length - 1)
        .split(",");

      axios({
        method: "post",
        url:
          config.apiUrl +
          "/EscalationHierarchy/AddUpdateEscalationHierarchyUser",
        headers: authHeader(),
        data: {
          RoleName: this.state.userRoleName,
          Usertype: parseInt(this.state.selectPhase),
          UserList: user_list,
        },
      })
        .then((res) => {
          let status = res.data.message;
          if (status === "Record Inserted") {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.escalationaddedsuccessfully
                : "Escalation added successfully."
            );
            self.setState({
              userRoleName: "",
              selectPhase: 0,
              indiUserRoleName: "",
            });
            self.handleGetEscalationHierarchyGridData();
          } else {
            NotificationManager.error(status);
          }
        })
        .catch((response) => {
          console.log(response);
        });
    } else {
      this.setState({
        userRoleNameCompulsory: "Please enter user role Name.",
        mapUserRoleCompulsory: "Please select User role map.",
      });
    }
  }

  handleUserRoleNameButton = () => {
    let slaShowOriginal = this.state.UserRoleNameShow;
    let UserRoleNameShow = !slaShowOriginal;
    let slaOvrlayShowOriginal = this.state.UserRoleNameOvrlayShow;
    let UserRoleNameOvrlayShow = !slaOvrlayShowOriginal;
    this.setState({
      UserRoleNameShow,
      UserRoleNameOvrlayShow,
    });
  };
  /// select all campaign
  selectAllUserRoleName = async (event) => {
    var indiUserRoleName = "";
    var checkboxes = document.getElementsByName("allUserRoleName");
    document.getElementById("UserRoleNameValue").textContent = "All Selected";
    for (var i in checkboxes) {
      if (checkboxes[i].checked === false) {
        checkboxes[i].checked = true;
      }
    }
    if (this.state.mapUserRoleData !== null) {
      this.state.mapUserRoleData.forEach(allCampaignId);
      function allCampaignId(item) {
        indiUserRoleName += item.userId + ",";
      }
    }
    await this.setState({
      indiUserRoleName,
    });
  };
  /// hanlde select Individual Role name
  selectIndividuaRoleName = async (issueId, event) => {
    var indiUserRoleName = this.state.indiUserRoleName;
    var separator = ",";
    var values = indiUserRoleName.split(separator);
    if (event.target.checked) {
      var flag = values.includes(issueId.toString());
      if (!flag) {
        values.unshift(issueId);
        indiUserRoleName = values.join(separator);
      }
      await this.setState({
        indiUserRoleName,
      });
      document.getElementById("UserRoleNameValue").textContent =
        this.state.indiUserRoleName.split(",").length - 1 + " selected";
    } else {
      for (var i = 0; i < values.length; i++) {
        if (parseInt(values[i]) === issueId) {
          values.splice(i, 1);
          indiUserRoleName = values.join(separator);
        }
      }
      await this.setState({
        indiUserRoleName,
      });
      if (this.state.indiUserRoleName.split(",").length - 1 !== 0) {
        document.getElementById("UserRoleNameValue").textContent =
          this.state.indiUserRoleName.split(",").length - 1 + " selected";
      } else {
        document.getElementById("UserRoleNameValue").textContent = "Select";
      }
    }
  };
  /// handle no select user
  selectNoUserRoleName = async (event) => {
    var checkboxes = document.getElementsByName("allUserRoleName");
    document.getElementById("UserRoleNameValue").textContent = "Select";
    for (var i in checkboxes) {
      if (checkboxes[i].checked === true) {
        checkboxes[i].checked = false;
      }
    }
    await this.setState({
      indiUserRoleName: "",
    });
  };
  handleEditUserRoleNameButton = () => {
    let slaShowOriginal = this.state.editUserRoleNameShow;
    let editUserRoleNameShow = !slaShowOriginal;
    let slaOvrlayShowOriginal = this.state.EditUserRoleNameOvrlayShow;
    let EditUserRoleNameOvrlayShow = !slaOvrlayShowOriginal;
    this.setState({
      editUserRoleNameShow,
      EditUserRoleNameOvrlayShow,
    });
  };
  /// hanlde select Individual Role name
  selectEditIndividuaRoleName = async (issueId, event) => {
    var EditIndiUserRoleName = this.state.EditIndiUserRoleName;
    var separator = ",";
    var values = EditIndiUserRoleName.split(separator);
    if (event.target.checked) {
      var flag = values.includes(issueId.toString());
      if (!flag) {
        values.unshift(issueId);
        EditIndiUserRoleName = values.join(separator);
      }
      await this.setState({
        EditIndiUserRoleName,
      });
    } else {
      for (var i = 0; i < values.length; i++) {
        if (parseInt(values[i]) === issueId) {
          values.splice(i, 1);
          EditIndiUserRoleName = values.join(separator);
        }
      }
      await this.setState({
        EditIndiUserRoleName,
      });
    }
  };
  /// select all edit user role name
  selectAllEditUserRoleName = async (event) => {
    var EditIndiUserRoleName = "";
    var checkboxes = document.getElementsByName("EditAllUserRoleName");

    for (var i in checkboxes) {
      if (checkboxes[i].checked === false) {
        checkboxes[i].checked = true;
      }
    }
    if (this.state.mapUserRoleData !== null) {
      this.state.mapUserRoleData.forEach(allCampaignId);
      function allCampaignId(item) {
        EditIndiUserRoleName += item.userId + ",";
      }
    }
    await this.setState({
      EditIndiUserRoleName,
    });
  };
  /// handle no select user
  selectNoEditUserRoleName = async (event) => {
    var checkboxes = document.getElementsByName("EditAllUserRoleName");

    for (var i in checkboxes) {
      if (checkboxes[i].checked === true) {
        checkboxes[i].checked = false;
      }
    }
    await this.setState({
      EditIndiUserRoleName: "",
    });
  };

  handleSelectPhaseChange = (e) => {
    this.setState({
      selectPhase: e.target.value,
    });
    setTimeout(() => {
      this.handleGetMapUserNameData("Add");
    }, 10);
  };
  handleInputOnchange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    var EscalationEditData = this.state.EscalationEditData;
    EscalationEditData[name] = value;

    if (name === "userType") {
      setTimeout(() => {
        this.handleGetMapUserNameData("edit");
      }, 10);

      this.setState({
        EscalationEditData,
        EditIndiUserRoleName: "",
        mapUserRoleData: [],
      });
    } else {
      this.setState({
        EscalationEditData,
      });
    }
  };
  /// handle update data
  handleUpdateHierarchy() {
    var self = this;

    if (
      this.state.EscalationEditData.roleName !== "" &&
      this.state.EditIndiUserRoleName !== ""
    ) {
      var user_list = this.state.EditIndiUserRoleName.substring(
        ",",
        this.state.EditIndiUserRoleName.length - 1
      ).split(",");

      axios({
        method: "post",
        url:
          config.apiUrl +
          "/EscalationHierarchy/AddUpdateEscalationHierarchyUser",
        headers: authHeader(),
        data: {
          RoleID: this.state.EscalationEditData.roleID,
          RoleName: this.state.EscalationEditData.roleName,
          Usertype: parseInt(this.state.EscalationEditData.userType),
          UserList: user_list,
        },
      })
        .then((res) => {
          let status = res.data.message;
          if (status === "Record Updated") {
            NotificationManager.success(status);
            self.setState({
              EscalationEditData: {},
              editModal: false,
            });
            self.handleGetEscalationHierarchyGridData();
          } else {
            NotificationManager.error(status);
          }
        })
        .catch((response) => {
          console.log(response);
        });
    } else {
      self.setState({
        editRoleNameCompulsory: "Please Enter User Role Name.",
        editMapUserRoleCompulsory: "Please Select User Role Map.",
      });

      self.handleGetEscalationHierarchyGridData();
    }
  }

  handleDeleteEscalationHierarchy(deleteId) {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/EscalationHierarchy/DeleteEscalationHierarchy",
      headers: authHeader(),
      params: {
        RoleID: deleteId,
      },
    })
      .then(function(res) {
        let status = res.data.message;
        if (status === "Record deleted Successfully") {
          NotificationManager.success(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.recorddeletedsuccessfully
              : "Record Deleted Successfully."
          );
          self.handleGetEscalationHierarchyGridData();
        } else {
          NotificationManager.error(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.recordnotdeleted
              : "Record Not Deleted."
          );
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  render() {
    const TranslationContext = this.state.translateLanguage.default;

    return (
      <React.Fragment>
        <div className="container-fluid setting-title setting-breadcrumb">
          <Link to="settings" className="header-path">
            {TranslationContext !== undefined
              ? TranslationContext.link.setting
              : "Settings"}
          </Link>
          <span>&gt;</span>
          <Link to={Demo.BLANK_LINK} className="active header-path">
            {TranslationContext !== undefined
              ? TranslationContext.strong.escalationhierarchy
              : "Escalation Hierarchy"}
          </Link>
        </div>
        <div className="container-fluid">
          <div className="store-settings-cntr settingtable">
            <div className="row">
              <div className="col-md-8">
                {this.state.loading === true ? (
                  <div className="loader-icon"></div>
                ) : (
                  <div className="table-cntr table-height TicketBrandReact settings-align">
                    <ReactTable
                      data={this.state.EscalationGridData}
                      minRows={2}
                      columns={[
                        {
                          Header: "Role Name",
                          sortable: false,
                          accessor: "roleName",
                        },
                        {
                          Header: "Users",
                          sortable: false,
                          accessor: "userName",
                        },
                        {
                          Header: (
                            <span>
                              {TranslationContext !== undefined
                                ? TranslationContext.span.action
                                : "Actions"}
                            </span>
                          ),
                          accessor: "actiondept",
                          sortable: false,
                          Cell: (row) => {
                            return (
                              <>
                                <span className="settings-align-actions">
                                  <Popover
                                    content={
                                      <div className="samdel d-flex general-popover popover-body">
                                        <div className="del-big-icon">
                                          <img
                                            src={DelBigIcon}
                                            alt="del-icon"
                                          />
                                        </div>
                                        <div>
                                          <p className="font-weight-bold blak-clr">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.p.deletefile
                                              : "Delete file?"}
                                          </p>
                                          <p className="mt-1 fs-12">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.p
                                                  .areyousuredeletefile
                                              : "Are you sure you want to delete this file?"}
                                          </p>
                                          <div className="del-can">
                                            <a href="#!" className="canblue">
                                              {TranslationContext !== undefined
                                                ? TranslationContext.a.cancel
                                                : "CANCEL"}
                                            </a>
                                            <button
                                              className="butn"
                                              type="button"
                                              onClick={this.handleDeleteEscalationHierarchy.bind(
                                                this,
                                                row.original.roleID
                                              )}
                                            >
                                              {TranslationContext !== undefined
                                                ? TranslationContext.button
                                                    .delete
                                                : "Delete"}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    }
                                    placement="bottom"
                                    trigger="click"
                                  >
                                    <img
                                      src={RedDeleteIcon}
                                      alt="del-icon"
                                      className="del-btn"
                                    />
                                  </Popover>
                                  <button
                                    className="react-tabel-button"
                                    onClick={this.handleEditModalOpen.bind(
                                      this,
                                      row.original
                                    )}
                                  >
                                    <label className="Table-action-edit-button-text">
                                      EDIT
                                    </label>
                                  </button>
                                </span>
                              </>
                            );
                          },
                        },
                      ]}
                      resizable={false}
                      defaultPageSize={10}
                      showPagination={true}
                    />
                  </div>
                )}
              </div>
              <div
                id="overlayUserRoleName"
                className={this.state.UserRoleNameOvrlayShow ? "show" : ""}
                onClick={this.handleUserRoleNameButton}
              />
              <div className="col-md-4">
                <div className="createHierarchyMask">
                  <div className="createSpace">
                    <label className="create-department">
                      {TranslationContext !== undefined
                        ? TranslationContext.ticketingDashboard
                            .createescalationhierarchy
                        : "CREATE ESCALATION HIERARCHY"}
                    </label>
                    <div className="div-padding-1">
                      <label className="designation-name">
                        {TranslationContext !== undefined
                          ? TranslationContext.ticketingDashboard
                              .escalationuserrolename
                          : "Escalation User Role Name"}
                      </label>
                      <input
                        type="text"
                        className="txt-1"
                        placeholder={
                          TranslationContext !== undefined
                            ? TranslationContext.ticketingDashboard
                                .enteruserrolename
                            : "Enter User Role Name"
                        }
                        value={this.state.userRoleName}
                        autoComplete="off"
                        onChange={(e) =>
                          this.setState({ userRoleName: e.target.value })
                        }
                      />
                      {this.state.userRoleName === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.userRoleNameCompulsory}
                        </p>
                      )}
                    </div>

                    <div className="divSpace-3">
                      <div className="dropDrownSpace">
                        <label className="reports-to">
                          {TranslationContext !== undefined
                            ? TranslationContext.label.usertype
                            : "User Type"}
                        </label>
                        <select
                          className="store-create-select"
                          value={this.state.selectPhase}
                          onChange={this.handleSelectPhaseChange}
                        >
                          <option value={0}>ticket</option>
                          <option value={1}>store</option>
                          <option value={2}>both</option>
                        </select>
                      </div>
                    </div>
                    <div className="dropDrownSpace">
                      <label className="reports-to">
                        {TranslationContext !== undefined
                          ? TranslationContext.ticketingDashboard
                              .mapuserinthisrole
                          : "Map user in this role"}
                      </label>
                      <div className="dropdown issuetype-cusdrp">
                        <button
                          className="btn issuesladrop mb-0"
                          type="button"
                          id="UserRoleNameValue"
                          onClick={this.handleUserRoleNameButton}
                        >
                          Select
                          <span className="caret"></span>
                        </button>

                        <div
                          className={
                            this.state.UserRoleNameShow
                              ? "dropdown-menu dropdown-menu-sla show"
                              : "dropdown-menu dropdown-menu-sla"
                          }
                        >
                          <div className="cat-mainbox">
                            <div className="category-button">
                              <ul>
                                <li>
                                  <label
                                    onClick={this.selectAllUserRoleName.bind(
                                      this
                                    )}
                                  >
                                    Select All
                                  </label>
                                </li>
                                <li>
                                  <label
                                    onClick={this.selectNoUserRoleName.bind(
                                      this
                                    )}
                                  >
                                    Clear
                                  </label>
                                </li>
                              </ul>
                            </div>
                            <div className="category-box category-scroll">
                              <ul className="m-0">
                                {this.state.mapUserRoleData !== null &&
                                  this.state.mapUserRoleData.map((item, i) => (
                                    <li key={i}>
                                      <input
                                        type="checkbox"
                                        id={"Esc" + item.userId}
                                        name="allUserRoleName"
                                        onChange={this.selectIndividuaRoleName.bind(
                                          this,
                                          item.userId
                                        )}
                                        checked={
                                          this.state.indiUserRoleName !==
                                          undefined
                                            ? this.state.indiUserRoleName
                                                .split(",")
                                                .find(
                                                  (num) =>
                                                    num ===
                                                    item.userId.toString()
                                                )
                                            : false
                                        }
                                      />
                                      <label
                                        htmlFor={"Esc" + item.userId}
                                        title={item.userName}
                                      >
                                        {item.userName.length > 20
                                          ? item.userName
                                              .substr(0, 20)
                                              .concat("...")
                                          : item.userName}
                                        <div>
                                          <img src={Correct} alt="Checked" />
                                        </div>
                                      </label>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.indiUserRoleName === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.mapUserRoleCompulsory}
                        </p>
                      )}
                    </div>
                    <div className="btnSpace">
                      <button
                        className="CreateADDBtn"
                        type="button"
                        onClick={this.handleCreateHierarchy.bind(this)}
                      >
                        {TranslationContext !== undefined
                          ? TranslationContext.button.add
                          : "ADD"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                onClose={this.handleEditModalClose.bind(this)}
                open={this.state.editModal}
                modalId="esacalationEditmdl"
                overlayId="logout-ovrly"
              >
                <div className="escalationHeirarchyEdit">
                  <div
                    id="overlayEditUserRoleName"
                    className={
                      this.state.EditUserRoleNameOvrlayShow ? "show" : ""
                    }
                    onClick={this.handleEditUserRoleNameButton}
                  />
                  <label className="create-department">
                    {TranslationContext !== undefined
                      ? TranslationContext.ticketingDashboard
                          .editescalationhierarchy
                      : "EDIT ESCALATION HIERARCHY"}
                  </label>
                  <div className="div-padding-1">
                    <label className="designation-name">
                      {TranslationContext !== undefined
                        ? TranslationContext.ticketingDashboard
                            .escalationuserrolename
                        : "Escalation User Role Name"}
                    </label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={
                        TranslationContext !== undefined
                          ? TranslationContext.ticketingDashboard
                              .enteruserrolename
                          : "Enter User Role Name"
                      }
                      name="roleName"
                      value={this.state.EscalationEditData.roleName}
                      onChange={this.handleInputOnchange}
                      autoComplete="off"
                    />
                    {this.state.EscalationEditData.roleName === "" && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {this.state.editRoleNameCompulsory}
                      </p>
                    )}
                  </div>

                  <div className="divSpace-3">
                    <div className="dropDrownSpace">
                      <label className="reports-to">
                        {TranslationContext !== undefined
                          ? TranslationContext.label.usertype
                          : "User Type"}
                      </label>
                      <select
                        className="store-create-select"
                        name="userType"
                        value={this.state.EscalationEditData.userType}
                        onChange={this.handleInputOnchange}
                      >
                        <option value={0}>ticket</option>
                        <option value={1}>store</option>
                        <option value={2}>both</option>
                      </select>
                    </div>
                  </div>

                  <div className="dropDrownSpace">
                    <label className="reports-to">
                      {TranslationContext !== undefined
                        ? TranslationContext.ticketingDashboard
                            .mapuserinthisrole
                        : "Map user in this role"}
                    </label>
                    <div className="dropdown issuetype-cusdrp">
                      <button
                        className="btn issuesladrop mb-0"
                        type="button"
                        id="EditUserRoleNameValue"
                        onClick={this.handleEditUserRoleNameButton}
                      >
                        {this.state.EditIndiUserRoleName
                          ? this.state.EditIndiUserRoleName.split(",").length -
                            1 +
                            " Selected"
                          : "Select"}
                        <span className="caret"></span>
                      </button>

                      <div
                        className={
                          this.state.editUserRoleNameShow
                            ? "dropdown-menu dropdown-menu-sla show"
                            : "dropdown-menu dropdown-menu-sla"
                        }
                      >
                        <div className="cat-mainbox">
                          <div className="category-button">
                            <ul>
                              <li>
                                <label
                                  onClick={this.selectAllEditUserRoleName.bind(
                                    this
                                  )}
                                >
                                  Select All
                                </label>
                              </li>
                              <li>
                                <label
                                  onClick={this.selectNoEditUserRoleName.bind(
                                    this
                                  )}
                                >
                                  Clear
                                </label>
                              </li>
                            </ul>
                          </div>
                          <div className="category-box category-scroll">
                            <ul className="m-0">
                              {this.state.mapUserRoleData !== null &&
                                this.state.mapUserRoleData.map((item, i) => (
                                  <li key={i}>
                                    <input
                                      type="checkbox"
                                      id={"EditEsc" + item.userId}
                                      name="EditAllUserRoleName"
                                      onChange={this.selectEditIndividuaRoleName.bind(
                                        this,
                                        item.userId
                                      )}
                                      checked={
                                        this.state.EditIndiUserRoleName !==
                                        undefined
                                          ? this.state.EditIndiUserRoleName.split(
                                              ","
                                            ).find(
                                              (num) =>
                                                num === item.userId.toString()
                                            )
                                          : false
                                      }
                                    />
                                    <label
                                      htmlFor={"EditEsc" + item.userId}
                                      title={item.userName}
                                    >
                                      {item.userName.length > 20
                                        ? item.userName
                                            .substr(0, 20)
                                            .concat("...")
                                        : item.userName}
                                      <div>
                                        <img src={Correct} alt="Checked" />
                                      </div>
                                    </label>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.EditIndiUserRoleName === "" && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {this.state.editMapUserRoleCompulsory}
                      </p>
                    )}
                  </div>
                  <div className="btnSpace">
                    <div>
                      <a
                        className="pop-over-cancle"
                        onClick={this.handleEditModalClose.bind(this)}
                      >
                        {TranslationContext !== undefined
                          ? TranslationContext.a.cancel
                          : "CANCEL"}
                      </a>
                      <button
                        className="pop-over-button"
                        type="button"
                        onClick={this.handleUpdateHierarchy.bind(this)}
                      >
                        {TranslationContext !== undefined
                          ? TranslationContext.button.save
                          : "SAVE"}
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EscalationHierarchy;
