import React, { Component } from "react";
import "jquery";
import "popper.js/dist/popper";
import "bootstrap";
import storeSettings from "./../../assets/Images/store-settings.png";
import axios from "axios";
import priority from "./../../assets/Images/priority.png";
import users from "./../../assets/Images/users.png";
import category from "./../../assets/Images/category.png";
import hierarchy from "./../../assets/Images/hierarchy.png";
import brand from "./../../assets/Images/brand.png";
import sla from "./../../assets/Images/sla.png";
import crmRoles from "./../../assets/Images/crm-roles.png";
import alert from "./../../assets/Images/alert.png";
import reports from "./../../assets/Images/reports.png";
import fileUpload from "./../../assets/Images/file-upload.png";
import templates from "./../../assets/Images/templates.png";
import modules from "./../../assets/Images/modules.png";
import item from "./../../assets/Images/item.png";
import department from "./../../assets/Images/department.png";
import config from "./../../helpers/config";
import { Link } from "react-router-dom";
import * as translationHI from "../../translations/hindi";
import * as translationMA from "../../translations/marathi";
import { authHeader } from "../../helpers/authHeader";

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ticketShow: false,
      storeShow: false,
      showReport: true,
      translateLanguage: {},
    };
  }

  componentDidMount() {
    this.handleCRMRole();
    if (this.props.location.tabName) {
      let lowerTabsPane = document.querySelectorAll(".tab-pane");
      for (let i = 0; i < lowerTabsPane.length; i++) {
        lowerTabsPane[i].classList.remove("active");
        lowerTabsPane[i].classList.remove("show");
        if (this.props.location.tabName === lowerTabsPane[i].id) {
          lowerTabsPane[i].classList.add("active");
          lowerTabsPane[i].classList.add("show");
        }
      }
      let upperTabs = document.querySelectorAll(".nav-link");
      for (let i = 0; i < upperTabs.length; i++) {
        upperTabs[i].classList.remove("active");
        if (
          "#" + this.props.location.tabName ===
          upperTabs[i].getAttribute("href")
        ) {
          upperTabs[i].classList.add("active");
        }
      }
    }
    var store = window.localStorage.getItem("ERS");
    var ticket = window.localStorage.getItem("ERT");
    if (ticket === "true") {
      this.setState({
        ticketShow: ticket,
      });
    } else {
      this.setState({
        storeShow: store,
      });
    }

    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.setState({
        translateLanguage: translationHI,
      });
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.setState({
        translateLanguage: translationMA,
      });
    } else {
      this.setState({
        translateLanguage: {},
      });
    }
  }
  //handle crm role data
  handleCRMRole = () => {
    let self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/StoreCRMRole/GetStoreRolesByUserID",
      headers: authHeader(),
    })
      .then(function(res) {
        let msg = res.data.message;
        let data = res.data.responseData.modules;
        if (msg === "Success") {
          for (let i = 0; i < data.length; i++) {
            if (data[i].moduleName === "Reports") {
              if (
                data[i].moduleName === "Reports" &&
                data[i].modulestatus === true
              ) {
                self.setState({ showReport: true });
              } else {
                self.setState({ showReport: false });
              }
            }
          }
        }
      })
      .catch((data) => {
        console.log(data);
      });
  };
  render() {
    const TranslationContext = this.state.translateLanguage.default;

    return (
      <React.Fragment>
        <div className="container-fluid setting-title">
          <div className="setmainscreen">
            {TranslationContext !== undefined
              ? TranslationContext.div.setting
              : "Settings"}
          </div>
        </div>
        <div className="container-fluid">
          <div className="setting-tabs">
            <div className="row">
              <div className="col-md-3">
                <div className="setting-cntr">
                  <Link to="MasterHierarchy" className="setting-box">
                    <div className="setting-icons">
                      <img src={hierarchy} alt="hierarchy" />
                    </div>
                    <div className="setting-desc">
                      <strong>
                        {TranslationContext !== undefined
                          ? TranslationContext.strong.hierarchy
                          : "Hierarchy"}
                      </strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p
                              .asysteminwhichmembersofanorganizationorsocietyarerankedaccordingtorelativestatusorauthority
                          : "A system in which members of an organization or society are ranked according to relative status or authority."}
                      </p>
                    </div>
                  </Link>
                  <Link to="brands" className="setting-box">
                    <div className="setting-icons">
                      <img src={brand} alt="brand" />
                    </div>
                    <div className="setting-desc">
                      <strong>
                        {TranslationContext !== undefined
                          ? TranslationContext.strong.brand
                          : "Brand"}
                      </strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p
                              .asysteminwhichmembersofanorganizationorsocietyarerankedaccordingtorelativestatusorauthority
                          : "A system in which members of an organization or society are ranked according to relative status or authority."}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="setting-cntr">
                  <Link to="storeMaster" className="setting-box">
                    <div className="setting-icons">
                      <img src={storeSettings} alt="store-settings" />
                    </div>
                    <div className="setting-desc">
                      <strong>Store</strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p
                              .asysteminwhichmembersofanorganizationorsocietyarerankedaccordingtorelativestatusorauthority
                          : "A system in which members of an organization or society are ranked according to relative status or authority."}
                      </p>
                    </div>
                  </Link>
                  <Link to="categoryMaster" className="setting-box">
                    <div className="setting-icons">
                      <img src={category} alt="category" />
                    </div>
                    <div className="setting-desc">
                      <strong>Category</strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p
                              .asysteminwhichmembersofanorganizationorsocietyarerankedaccordingtorelativestatusorauthority
                          : "A system in which members of an organization or society are ranked according to relative status or authority."}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="setting-cntr">
                  <Link to="PriorityMaster" className="setting-box">
                    <div className="setting-icons">
                      <img src={priority} alt="priority" />
                    </div>
                    <div className="setting-desc">
                      <strong>
                        {TranslationContext !== undefined
                          ? TranslationContext.strong.priority
                          : "Priority"}
                      </strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p
                              .asysteminwhichmembersofanorganizationorsocietyarerankedaccordingtorelativestatusorauthority
                          : "A system in which members of an organization or society are ranked according to relative status or authority."}
                      </p>
                    </div>
                  </Link>
                  <Link to="MasterSLA" className="setting-box">
                    <div className="setting-icons">
                      <img src={sla} alt="sla" />
                    </div>
                    <div className="setting-desc">
                      <strong>
                        {TranslationContext !== undefined
                          ? TranslationContext.strong.sla
                          : "SLA"}
                      </strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p
                              .asysteminwhichmembersofanorganizationorsocietyarerankedaccordingtorelativestatusorauthority
                          : "A system in which members of an organization or society are ranked according to relative status or authority."}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="setting-cntr">
                  <Link to="UserMaster" className="setting-box">
                    <div className="setting-icons big-icon">
                      <img src={users} alt="users" />
                    </div>
                    <div className="setting-desc">
                      <strong>
                        {TranslationContext !== undefined
                          ? TranslationContext.strong.users
                          : "Users"}
                      </strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p.settingusersdetails
                          : "Master to manage all users and add new users as well."}
                      </p>
                    </div>
                  </Link>
                  <Link to="CRMRoleMaster" className="setting-box">
                    <div className="setting-icons">
                      <img src={crmRoles} alt="crm-roles" />
                    </div>
                    <div className="setting-desc">
                      <strong>
                        {TranslationContext !== undefined
                          ? TranslationContext.strong.crmroles
                          : "CRM Roles"}
                      </strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p
                              .asysteminwhichmembersofanorganizationorsocietyarerankedaccordingtorelativestatusorauthority
                          : "A system in which members of an organization or society are ranked according to relative status or authority."}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="setting-cntr">
                  <Link to="MasterAlerts" className="setting-box">
                    <div className="setting-icons icon-small">
                      <img src={alert} alt="alert" />
                    </div>
                    <div className="setting-desc">
                      <strong>
                        {TranslationContext !== undefined
                          ? TranslationContext.strong.alerts
                          : "Alerts"}
                      </strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p
                              .asysteminwhichmembersofanorganizationorsocietyarerankedaccordingtorelativestatusorauthority
                          : "A system in which members of an organization or society are ranked according to relative status or authority."}
                      </p>
                    </div>
                  </Link>
                  <Link to="templates" className="setting-box">
                    <div className="setting-icons icon-small">
                      <img src={templates} alt="templates" />
                    </div>
                    <div className="setting-desc">
                      <strong>
                        {TranslationContext !== undefined
                          ? TranslationContext.strong.templates
                          : "Templates"}
                      </strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p
                              .asysteminwhichmembersofanorganizationorsocietyarerankedaccordingtorelativestatusorauthority
                          : "A system in which members of an organization or society are ranked according to relative status or authority."}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="setting-cntr">
                  <Link to="ReportsMaster" className="setting-box">
                    <div className="setting-icons icon-small">
                      <img src={reports} alt="reports" />
                    </div>
                    <div className="setting-desc">
                      <strong>
                        {TranslationContext !== undefined
                          ? TranslationContext.strong.reports
                          : "Reports"}
                      </strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p
                              .asysteminwhichmembersofanorganizationorsocietyarerankedaccordingtorelativestatusorauthority
                          : "A system in which members of an organization or society are ranked according to relative status or authority."}
                      </p>
                    </div>
                  </Link>
                  <Link to="ModuleMaster" className="setting-box">
                    <div className="setting-icons">
                      <img src={modules} alt="modules" />
                    </div>
                    <div className="setting-desc">
                      <strong>
                        {TranslationContext !== undefined
                          ? TranslationContext.strong.modulesettings
                          : "Module Settings"}
                      </strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p.settingmodulesettingdetails
                          : "Own your configuration based on module & ease your business & ease the life of user."}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="setting-cntr">
                  <Link to="FileUploadLogMaster" className="setting-box">
                    <div className="setting-icons">
                      <img src={fileUpload} alt="file-upload" />
                    </div>
                    <div className="setting-desc">
                      <strong>
                        {TranslationContext !== undefined
                          ? TranslationContext.strong.fileuploadlogs
                          : "File Upload Logs"}
                      </strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p
                              .asysteminwhichmembersofanorganizationorsocietyarerankedaccordingtorelativestatusorauthority
                          : "A system in which members of an organization or society are ranked according to relative status or authority."}
                      </p>
                    </div>
                  </Link>
                  <Link to="blockedemail" className="setting-box">
                    <div className="setting-icons">
                      <img src={fileUpload} alt="file-upload" />
                    </div>
                    <div className="setting-desc">
                      <strong>
                        {TranslationContext !== undefined
                          ? TranslationContext.strong.blockedemailid
                          : "Blocked Email Id"}
                      </strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p
                              .asysteminwhichmembersofanorganizationorsocietyarerankedaccordingtorelativestatusorauthority
                          : "A system in which members of an organization or society are ranked according to relative status or authority."}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="setting-cntr">
                  <Link to="junkwords" className="setting-box">
                    <div className="setting-icons">
                      <img src={fileUpload} alt="file-upload" />
                    </div>
                    <div className="setting-desc">
                      <strong>
                        {TranslationContext !== undefined
                          ? TranslationContext.strong.junkwords
                          : "Junk Words"}
                      </strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p
                              .asysteminwhichmembersofanorganizationorsocietyarerankedaccordingtorelativestatusorauthority
                          : "A system in which members of an organization or society are ranked according to relative status or authority."}
                      </p>
                    </div>
                  </Link>
                  <Link to="DepartmentMaster" className="setting-box">
                    <div className="setting-icons icon-small">
                      <img src={department} alt="department" />
                    </div>
                    <div className="setting-desc">
                      <strong>
                        {TranslationContext !== undefined
                          ? TranslationContext.strong.department
                          : "Department"}
                      </strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p.settingdepartmentdetails
                          : "In summary, departments are important because they make it easier for the organization to undertake its numerous and complex activities simultaneously and thereby achieve its common objectives and goals."}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <div className="setting-cntr">
                  <Link to="itemMaster" className="setting-box">
                    <div className="setting-icons icon-small">
                      <img src={item} alt="item" />
                    </div>
                    <div className="setting-desc">
                      <strong>
                        {TranslationContext !== undefined
                          ? TranslationContext.strong.itemmaster
                          : "Item Master"}
                      </strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p.settingitemmasterdetails
                          : "An item master is a record that lists key information about an inventory item. This information may include the description, unit of measure, weight, dimensions, ordering quantity, and so forth for an item"}
                      </p>
                    </div>
                  </Link>
                  </div>
              </div>
              <div className="col-md-3">
                <div className="setting-cntr">
                  <Link to="claimCategoryMaster" className="setting-box">
                    <div className="setting-icons">
                      <img src={category} alt="category" />
                    </div>
                    <div className="setting-desc">
                      <strong>
                        {TranslationContext !== undefined
                          ? TranslationContext.strong.claimcategorymaster
                          : "Claim Category Master"}
                      </strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p.settingclaimcategorydetails
                          : "Category master where we can setup list of categories where support need to provide in terms of excahnge, refund or in modification of product."}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="setting-cntr">
                  <Link to="EscalationHierarchy" className="setting-box">
                    <div className="setting-icons">
                      <img src={hierarchy} alt="EscalationHierarchy" />
                    </div>
                    <div className="setting-desc">
                      <strong>
                        {TranslationContext !== undefined
                          ? TranslationContext.strong.escalationhierarchy
                          : "Escalation Hierarchy"}
                      </strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p.settingfileuploaddetails
                          : "Single place to analyze what all files we have uploaded from different section."}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="setting-cntr">
                  <Link to="ServiceTypeMaster" className="setting-box">
                    <div className="setting-icons">
                      <img src={category} alt="ServiceTypeMaster" />
                    </div>
                    <div className="setting-desc">
                      <strong>
                        {TranslationContext !== undefined
                          ? TranslationContext.strong.servicetypemaster
                          : "Service Type Master"}
                      </strong>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p.settingfileuploaddetails
                          : "Single place to analyze what all files we have uploaded from different section."}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Settings;
