import React, { Component } from "react";
import Hierarchy from "./../Settings/Store/HierarchyMaster";
class MasterHierarchy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
componentDidMount(){
  
}
  render() {
    return (
      <React.Fragment>
        <Hierarchy />
      </React.Fragment>
    );
  }
}

export default MasterHierarchy;
